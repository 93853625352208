import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/utils/permission.service';
import { InitDisplayedCol, Page, PageCtrl } from '../../models/common';

@Component({
  selector: 'app-table2-ui',
  templateUrl: './table2-ui.component.html',
  styleUrls: ['./table2-ui.component.css']
})
export class Table2UiComponent implements OnInit {

  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @Input() set data(payload: any) {
    if(payload) {
      const data = this.preCheckSelectedIds(payload);
      this.dataSource = new MatTableDataSource(data);
    }
  }
  @Input() initColumns: InitDisplayedCol[];
  @Input() selectedIds: Set<number> = new Set();

  @Input() pageProp: Page;
  @Input() showPagination: boolean;

  @Input() usePermission: boolean = false;

  @Input() matSortActive: string = "";
  @Input() matSortDirection: SortDirection = "";
  @Input() showCheckboxes: boolean = true

  @Output() checkboxCheckEmit: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeToggle: EventEmitter<number> = new EventEmitter();
  @Output() PageCtrlToggle: EventEmitter<PageCtrl> = new EventEmitter();
  @Output() sortEmit: EventEmitter<any> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;
  public masterCheckboxChecked: boolean;

  constructor(private permissionsService: PermissionService) {}
  ngOnInit(): void {
    this.displayedColumns = this.initColumns.map((col) => col.key);
  }

  ngOnChanges(changes: SimpleChanges){
    this.displayedColumns = this.initColumns.map((col) => col.key);
    if(!this.selectedIds.size){
      this.masterCheckboxChecked = false
    }
    // if(changes['pageProp']) this.pageProp = changes['pageProp'].currentValue
  }

  ngAfterViewInit() {
    if(this.dataSource)
    this.dataSource.sort = this.sort;
  }

  private preCheckSelectedIds(data: any[]) {
    if(!data || !data.length) return;
    return data.map((d) => {
      d.checked = this.selectedIds.has(d.id);
      return d;
    });
  }

  public hasPermission(perms:string[]){
    return this.permissionsService.hasPermissionList(perms);
  }
  public showToolTip(val, maxLen){
    const len = maxLen ?? 50;
    if(val.length <= len){
      return val;
    }
    return ''
  }

  public announceSortChange(sortState: any) {
    this.sortEmit.emit(sortState)
  }

  public onPageSizeToggle(val: number) {
    this.pageSizeToggle.emit(val);
  }
  public onPageCtrlToggle(val: PageCtrl) {
    this.PageCtrlToggle.emit(val);
  }

  public onCheck(event: MatCheckboxChange, item: any) {
    if (this.masterCheckboxChecked) this.masterCheckboxChecked = false;
    const elemCount = this.dataSource.data.length;
    this.dataSource.data.map((d: any) => {
      if (d.repaymentNo === item.repaymentNo) d.checked = event.checked;
      if (d.checked) this.selectedIds.add(d.repaymentNo);
      else this.selectedIds.delete(d.repaymentNo);
    });
    

    if (this.selectedIds.size === elemCount) this.masterCheckboxChecked = true;
    
    this.checkboxCheckEmit.emit(this.selectedIds);
  }

  public onCheckAll(event: MatCheckboxChange) {
    this.masterCheckboxChecked = event.checked;
    this.dataSource.data.forEach((element: any) => {
      element.checked = event.checked;
    });
    this.selectedIds = new Set(this.dataSource.data.map((val) => val.repaymentNo));
    event.checked
      ? this.checkboxCheckEmit.emit(this.selectedIds)
      : this.checkboxCheckEmit.emit(new Set());
  }

  public unCheckAll() {
    this.masterCheckboxChecked = false;
    this.data.forEach((element: any) => {
      element.checked = false;
    });
  }

  public triggerRowClick(item: any) {
    this.rowClick.emit(item)
  }

}
