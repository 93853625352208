<app-chart-wrapper [isLoading]="isLoading" [title]="'Clients Occupation'" (fullscreen)="setHideFilters($event)">
    <!-- <app-chart-filter filter [form]="clientOccupationForm" [formControls]="clientOccupationFormControls" *ngIf="!hideFilters" (filter)="filterClientOccupation($event)"></app-chart-filter> -->
    <!-- Filters -->
    <form filter [formGroup]="form" class="flex justify-end gap-2 mb-4">

        <mat-form-field class="w-[40%]" appearance="outline">
            <mat-label i18n="@@client_status">Client Status</mat-label>
            <mat-select
                (selectionChange)="filterClientOccupation({ type: reportsInput.CLIENT_STATUS, value:$event.value })"
                [formControlName]="reportsInput.CLIENT_STATUS">
                <mat-option *ngFor="let option of clientStatus" [value]="option.value">{{ option?.label }} </mat-option>
            </mat-select>
        </mat-form-field>

        <app-loan-product-search-select class="w-[40%]"
            (emitSelectedVal)="filterClientOccupation({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })"
            [control]="productCtrl" type="client">
        </app-loan-product-search-select>



    </form>
    <!-- End Filters -->
    <app-no-data-to-show chart *ngIf="!payload?.length && !isLoading"></app-no-data-to-show>

    <app-chart-summary-table *ngIf="payload?.length && !isLoading" [showFooter]="true"
        [tableWrapperClass]="payload && payload.length ? 'max-h-[360px] border rounded-xl border-primary/10 border-b-primary':'' "
        [tableClass]="''" [data]="payload" [initColumns]="columns" table></app-chart-summary-table>

</app-chart-wrapper>