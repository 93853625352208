import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from 'src/app/shared/models/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OperationalIndicatorService {
  private coreURL = `${environment.baseUrl}${environment.coreUrl}`
  constructor(private http: HttpClient) {}

  public getRepaymentVsDisbursement(queryParams) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/repaymentVsDisbursement?${queryParams}`
    );
  }
  public getRepaymentVsDisbursementTotal(queryParams) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/totalRepaymentVsDisbursement?${queryParams}`
    );
  }

  public getRunningLoans(queryParams) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/runningLoansChart?${queryParams}`);
  }

  public getDepositBalanceLoans(queryParams: any) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/depositBalanceChart?${queryParams}`);
  }

  public getDisbursedLoans(queryParams: any) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/disbursedLoansAmountChart?${queryParams}`);
  }

  public getDisbursedLoansCount(queryParams: any) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/disbursedLoansCountChart?${queryParams}`
    );
  }
  public getClientOccupation(queryParams: any) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}core/clients/clientsOccupation?${queryParams}`
    );
  }

  public getClients(queryParams: any) {
    return this.http.get<API<any>>(`${this.coreURL}clients/allClients?${queryParams}`);
  }

  public getClientsSummaryTotal(queryParams: any) {
    return this.http.get<API<any>>(`${this.coreURL}clients/totalClients?${queryParams}`);
  }

  public getWriteOff(queryParams) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/writeofChart?${queryParams}`);
  }

  public getParChart(queryParams) {
    return this.http.get<API<any>>(
      `${environment.baseUrl}accounts/loan-accounts/parChart?${queryParams}`);
  }
  
}

