import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unhandled-ui',
  templateUrl: './unhandled-ui.component.html',
  styleUrls: ['./unhandled-ui.component.css']
})
export class UnhandledUiComponent  {

  @Input() title: string;
  @Input() listTitle: string;
  @Input() unhandledList: any[] = [1,2,3];

  constructor() { }

 

  cancel() {}
  
  removeAll() {}

}
