import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { AccountingCategory, AccountingTransaction } from '../../models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ProceedModalComponent } from '../../modals/proceed-modal/proceed-modal.component';
import { SelectChargeIdComponent } from '../../modals/select-charge-id/select-charge-id.component';

@Component({
  selector: 'app-drag-drop-list',
  templateUrl: './drag-drop-list.component.html',
  styleUrls: ['./drag-drop-list.component.css'],
})
export class DragDropListComponent implements OnInit {
  @Input() product: 'loan' | 'deposit' = 'loan';
  @Input() isDisabled: boolean = false;
  @Input() createDefaultTxn: boolean = false;
  @Input() accountingCategories: { key: string; value: AccountingCategory }[] =
    [];
  @Input() oldAccountingCategories: {
    key: string;
    value: AccountingCategory;
  }[] = [];
  @Input() glAccounts: any[];
  @Input() charges: any;
  @Input() penaltyCharges: any;
  private previousSelectedId: number | null = null;
  filteredGlAccounts: any[];
  selectedChargeIds: number[] = [];
  filteredEvents: any[];
  filteredEventMapping: any[];
  @Input() events: { label: string; value: string }[] = [];
  @Input() eventMaps: any[] = [];
  mappingNames: any[] = [];
  selectedMappingName: any;
  mappings: any[] = [];

  @Input() accountType: 'ACCRUAL' | 'CASH' = 'ACCRUAL';
  @Output() switchAccountTypeEmit: EventEmitter<any> = new EventEmitter();
  @Output() emitAccountingList: EventEmitter<any> = new EventEmitter();

  newTransactionNames: { [key: string]: string } = {};
  isEmptyTransactionName: { [key: string]: boolean } = {};

  public defaultDebitEvents = [
    'REPAYMENT-PRINCIPAL',
    'DISBURSEMENT-INTEREST_PAID',
    'REPAYMENT-INTEREST',
    'DISBURSEMENT-CHARGE_PAID',
  ];

  public defaultCreditEvents = [
    'DISBURSEMENT-PRINCIPAL'
  ]
  private disbursementPrincipalDefault = {
    label: 'Disbursement Principal',
    creditGlId: 0,
    // creditGlName: 'FUNDSOURCE-SELECTED',
    eventName: 'DISBURSEMENT-PRINCIPAL',
    // creditGlAccountList: [{name: 'FUNDSOURCE-SELECTED', id: 0}]
  };
  private repaymentPrincipalDefault = {
    label: 'Repayment Principal',
    debitGlId: 0,
    // debitGlName: 'BRANCH-GL-SELECTED',
    eventName: 'REPAYMENT-PRINCIPAL',
    // debitGlAccountList: [{name: 'BRANCH-GL-SELECTED', id: 0}]
  };

  private interestDefault = [
    {
      label: 'Repayment Interest',
      debitGlId: 0,
      // debitGlName: 'FUNDSOURCE-SELECTED',
      eventName: 'REPAYMENT-INTEREST',
      // debitGlAccountList: [{name: 'FUNDSOURCE-SELECTED', id: 0}]
    },
    {
      label: 'Disbursement Interest Paid',
      debitGlId: 0,
      // debitGlName: 'FUNDSOURCE-SELECTED',
      eventName: 'DISBURSEMENT-INTEREST_PAID',
      // debitGlAccountList: [{name: 'FUNDSOURCE-SELECTED', id: 0}]
    },
  ];

  constructor(
    private utils: UtilityService,
    private toast: NotificationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.filteredGlAccounts = this.glAccounts;
    // this.mappingNames = this.eventMaps.map(item => item.mapping_name);
    this.mappingNames = this.eventMaps.map((item) => item.mappings);
    this.filteredEvents = this.mappingNames;

    this.addDefaultTransactions(); //add default transactions to the list
    this.setAllDefaultEvents();
  }

  private setAllDefaultEvents() {
    for (const category of this.accountingCategories) {
      for (const row of category.value.row) {
        if (this.defaultDebitEvents.includes(row.eventName)) {
          row.debitGlId = 0;
        }
        if (this.defaultCreditEvents.includes(row.eventName)) {
          row.creditGlId = 0;
        }
      }
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    const dropListId = event.container.id;
    const index = this.extractIndexFromDropListId(dropListId);

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      if (index !== null) {
        event.container.data.forEach((item) => {
          item.groupLabel = this.accountingCategories[index].value.name;
        });
      } else {
      }
    }
  }

  extractIndexFromDropListId(dropListId: string): number | null {
    const match = dropListId.match(/\d+/);

    if (match) {
      return parseInt(match[0], 10);
    } else {
      return null;
    }
  }

  /** Predicate function that does not allow accounting transactions to be dropped into a fees/charges list. */
  dropPredicate() {
    return true;
  }

  noDropPredicate() {
    return false;
  }

  addDefaultTransactions() {
    const disbursementKey = 'disbursement';
    const repaymentKey = 'repayment';
    const interestKey = 'interest';

    const disbursementCategory = this.accountingCategories.find(
      (cat) => cat.key === disbursementKey
    );
    const repaymentCategory = this.accountingCategories.find(
      (cat) => cat.key === repaymentKey
    );
    const interestCategory = this.accountingCategories.find(
      (cat) => cat.key === interestKey
    );

    if (disbursementCategory.value.row.length === 0)
      this.addTransaction('disbursement', true);
    if (repaymentCategory.value.row.length === 0)
      this.addTransaction('repayment', true);
    if (interestCategory.value.row.length === 0) {
      this.addTransaction('interest', true);
      this.addTransaction('disbursementInterestPaid', true);
    }
  }

  filterCreditGlOptions(searchText: string, row?: AccountingTransaction) {
    row.creditGlAccountList = this.glAccounts;
    if (!searchText) {
      return row.creditGlAccountList;
    }

    return (row.creditGlAccountList = row.creditGlAccountList.filter(
      (glAccount) =>
        glAccount.name.toLowerCase().includes(searchText.toLowerCase())
    ));
  }

  filterDebitGlOptions(searchText: string, row?: AccountingTransaction) {
    row.debitGlAccountList = this.glAccounts;
    if (!searchText) {
      return row.debitGlAccountList;
    }

    return (row.debitGlAccountList = row.debitGlAccountList.filter(
      (glAccount) =>
        glAccount.name.toLowerCase().includes(searchText.toLowerCase())
    ));
  }

  filterEventsOptions(searchText: string, row?: AccountingTransaction) {
    row.eventNameList = this.eventMaps[0].mappings.split(', ') ?? [];
    if (!searchText) {
      return row.eventNameList;
    }

    return (row.eventNameList = row.eventNameList.filter((event) =>
      event
        .toLowerCase()
        .includes(this.utils.joinWithUnderscore(searchText.toLowerCase()))
    ));
  }

  filterMappingOptions(searchText: string, row?: AccountingTransaction) {
    this.onMappingNameChange(row, row.eventName); // call the mapping change event to preload row.eventMappingList with complete list

    if (!searchText) {
      return row.eventMappingList; //if no search text, return all the eventMapping list
    }

    //else filter eventMapping by search text
    return (row.eventMappingList = row.eventMappingList.filter((eventMap) =>
      eventMap
        .toLowerCase()
        .includes(this.utils.joinWithUnderscore(searchText.toLowerCase()))
    ));
  }

  onMappingNameChange(
    row: AccountingTransaction,
    selectedMappingName: string
  ): void {
    const selectedMapping = row.eventNameList.find(
      (mapping) => mapping === selectedMappingName
    );
    row.eventMapping = selectedMapping.split('-')[1];
    row.eventName = selectedMapping;
    if (this.eventWithDefaultDebit(row)) row.debitGlId = 0;
    if (this.eventWithDefaultCredit(row)) row.creditGlId = 0;
    // row.eventName = selectedMapping.split('-')[0]
  }

  switchAccountType(accountType: 'ACCRUAL' | 'CASH') {
    this.switchAccountTypeEmit.emit(accountType);
  }

  getButtonClass(accountType: 'ACCRUAL' | 'CASH'): string {
    return this.accountType === accountType
      ? 'px-[15px] py-[10px] rounded-[10px] border-[#4d5d76] bg-primary text-white w-[132px] h-[45px]'
      : 'px-[15px] py-[10px] rounded-[10px] border-[#4d5d76] text-primary w-[132px] h-[45px]';
  }

  isTransactionValid(transaction: AccountingTransaction): boolean {
    const hasLabel = !!transaction.label;
    const hasDebitGlId = transaction.debitGlId != null;
    const hasCreditGlId = transaction.creditGlId != null;
    const hasEventName = !!transaction.eventName;

    const isValid = hasLabel && hasDebitGlId && hasCreditGlId && hasEventName;

    return isValid;

    // return (
    //   !!transaction.label &&
    //   transaction.creditGlId != null &&
    //   transaction.debitGlId != null &&
    //   !!transaction.eventName
    //   // && !!transaction.eventMapping
    // );
  }

  isCategoryValid(category: AccountingCategory): boolean {
    return category.row.every((row) => this.isTransactionValid(row));
  }

  addTransaction(catKey: string, isDefault: boolean = false): void {
    const feesAndChargesKey = 'fees_charges';
    const disbursementKey = 'disbursement';
    const repaymentKey = 'repayment';
    const interestKey = 'interest';
    const disbursementInterestPaidKey = 'disbursementInterestPaid';

    const category = this.accountingCategories.find(
      (cat) => cat.key === catKey
    );

    let newTxnInfo = {
      catKey,
      category,
      newChargeId: null,
      label: '',
      eventName: '',
      creditGlId: null,
      creditGlName: '',
      creditGlAccountList: this.glAccounts,
      debitGlId: null,
      debitGlName: '',
      debitGlAccountList: this.glAccounts,
      siblingCharge: false,
    };

    if (category) {
      if (!this.isCategoryValid(category.value)) {
        this.toast.error(
          $localize`:@@complete_all_required_fields:Complete all required fields`
        );
        return;
      }

      if (catKey === feesAndChargesKey) {
        const selectedCharges: { id: number; name: string }[] =
          category.value.row
            .filter(
              (sourceObj) =>
                sourceObj.latePaymentChargeId === null &&
                !sourceObj.siblingCharge
            )
            .map((sourceObj) => ({
              name: sourceObj.label,
              id: sourceObj.chargeId,
            }));

        const dialog = this.dialog.open(SelectChargeIdComponent, {
          disableClose: true,
          data: {
            charges: selectedCharges,
          },
        });
        dialog.afterClosed().subscribe((res) => {
          if (!res.chargeIds) return;
          newTxnInfo.newChargeId = res.chargeIds;
          newTxnInfo.siblingCharge = true;
          const charge = this.charges.find(
            (charge) => charge.id === newTxnInfo.newChargeId
          );
          newTxnInfo.label = charge.name;
          this.createTransactionRow(newTxnInfo);
        });
        if (newTxnInfo.newChargeId === null) return;
      }

      if (catKey === disbursementKey && isDefault) {
        newTxnInfo = { ...newTxnInfo, ...this.disbursementPrincipalDefault };
      }

      if (catKey === repaymentKey && isDefault) {
        newTxnInfo = { ...newTxnInfo, ...this.repaymentPrincipalDefault };
      }

      if (catKey === interestKey && isDefault) {
        this.interestDefault.map((def) => {
          newTxnInfo = { ...newTxnInfo, ...def };
          this.createTransactionRow(newTxnInfo);
        });
        return;
      }

      this.createTransactionRow(newTxnInfo);
    }
  }

  createTransactionRow(newTxnInfo) {
    newTxnInfo.category.value.row.push({
      label: newTxnInfo.label,
      eventName: newTxnInfo.eventName,
      eventNameUnformatted: '',
      debitGlId: newTxnInfo.debitGlId,
      debitGlName: newTxnInfo.debitGlName,
      creditGlName: newTxnInfo.creditGlName,
      creditGlId: newTxnInfo.creditGlId,
      eventMapping: '',
      chargeId: newTxnInfo.newChargeId,
      latePaymentChargeId: null,
      siblingCharge: newTxnInfo.siblingCharge,
      groupLabel: newTxnInfo.category.value.name,
      creditGlAccountList: newTxnInfo.creditGlAccountList,
      debitGlAccountList: newTxnInfo.debitGlAccountList,
      eventMappingList: [],
      eventNameList: this.eventMaps[0].mappings.split(', ') ?? [],
    });

    this.newTransactionNames[newTxnInfo.catKey] = '';
  }

  removeTransaction(catKey: string, index: number): void {
    const dialog = this.dialog.open(ProceedModalComponent, {
      disableClose: true,
      data: {
        message: $localize`:@@confirm_delete:Confirm delete? Action cannot be undone`,
      },
    });
    dialog.afterClosed().subscribe((res) => {
      if (!res) return;
      const category = this.accountingCategories.find(
        (cat) => cat.key === catKey
      );

      if (category) {
        category.value.row.splice(index, 1);
      }
    });
  }

  onInputTransactionName(row: AccountingTransaction, value: string) {
    row.label = this.utils.titleCase(value);
  }

  updateDebitAndCreditGl(
    type: 'debit' | 'credit',
    row: any,
    selectedId: number
  ) {
    const selectedAccount = this.glAccounts.find(
      (glAccount) => glAccount.id === selectedId
    );
    if (selectedAccount) {
      if (type === 'debit') {
        row.debitGlId = selectedAccount.id;
        row.debitGlName = selectedAccount.name;
      } else {
        row.creditGlId = selectedAccount.id;
        row.creditGlName = selectedAccount.name;
      }
    } else {
      if (type === 'debit') {
        row.debitGlId = null;
        row.debitGlName = null;
      } else {
        row.creditGlId = null;
        row.creditGlName = null;
      }
    }
  }

  submit() {
    const mergedRowArray: AccountingTransaction[] = [];
    let isValid = [];

    for (const category of this.accountingCategories) {
      for (const row of category.value.row) {
        if (!this.isTransactionValid(row)) {
          isValid.push(false);
          this.toast.error(
            `${category.value.name}` +
              ' ' +
              $localize`:@@category_has_incomplete_transactions:category has incomplete transactions.`
          );
          return;
        }

        // Clone the row object and remove specified properties
        const modifiedRow = {
          ...row,
          eventMappingList: undefined,
          creditGlAccountList: undefined,
          debitGlAccountList: undefined,
          eventNameList: undefined,
        };

        if (modifiedRow.eventName.includes('-')) {
          const [eventName, eventMapping] = modifiedRow.eventName.split('-');
          modifiedRow.eventName = eventName.trim();
          modifiedRow.eventMapping = eventMapping.trim();
        }

        // Join formated events and mapping with underscores
        modifiedRow.eventName = this.utils.joinWithUnderscore(
          modifiedRow.eventName
        );
        modifiedRow.eventMapping = this.utils.joinWithUnderscore(
          modifiedRow.eventMapping
        );

        mergedRowArray.push(modifiedRow);
      }
    }

    // Returns if it contains false value in array
    if (isValid.some((valid) => !valid)) return;

    mergedRowArray.forEach((record) => {
      record.eventName = record.eventName.toUpperCase();
      record.eventMapping = record.eventMapping.toUpperCase();
    });

    this.emitAccountingList.emit(mergedRowArray);
  }

  chargeIdChange(selectedIds: number[]) {
    const categoryKey = 'fees_charges';

    const category = this.accountingCategories.find(
      (cat) => cat.key === categoryKey
    );

    if (category) {
      // Identify the charge IDs that were unselected
      const removedChargeIds = this.selectedChargeIds.filter(
        (id) => !selectedIds.includes(id)
      );

      // Remove rows associated with the unselected charge IDs
      removedChargeIds.forEach((removedChargeId) => {
        // Find all rows with the same chargeId and remove them
        const indexesToRemove = category.value.row
          .map((row, index) => (row.chargeId === removedChargeId ? index : -1))
          .filter((index) => index !== -1);

        // Remove the rows with the specified indexes
        indexesToRemove.reverse().forEach((index) => {
          category.value.row.splice(index, 1);
        });
      });

      // Add rows for newly selected charge IDs
      selectedIds.forEach((chargeId) => {
        // Check if a row with the same chargeId already exists
        const existingRow = category.value.row.find((row) => {
          return row.chargeId === chargeId;
        });

        // If no row with the same chargeId exists, create a new row
        if (!existingRow) {
          const charge = this.charges.find((charge) => charge.id === chargeId);
          if (charge) {
            category.value.row.push(
              {
                label: charge.name,
                eventName: 'DISBURSEMENT-CHARGE_APPLIED',
                debitGlId: null,
                debitGlName: '',
                creditGlName: '',
                creditGlId: null,
                eventMapping: '',
                groupLabel: category.value.name,
                creditGlAccountList: this.glAccounts,
                debitGlAccountList: this.glAccounts,
                eventMappingList: [],
                eventNameList: this.eventMaps[0].mappings.split(', ') ?? [],
                chargeId: chargeId,
                latePaymentChargeId: null,
                siblingCharge: false,
              },
              {
                label: charge.name,
                eventName: 'DISBURSEMENT-CHARGE_PAID',
                debitGlId: 0,
                debitGlName: 'BRANCH-GL-SELECTED',
                creditGlName: '',
                creditGlId: null,
                eventMapping: '',
                groupLabel: category.value.name,
                creditGlAccountList: this.glAccounts,
                debitGlAccountList: this.glAccounts,
                eventMappingList: [],
                eventNameList: this.eventMaps[0].mappings.split(', ') ?? [],
                chargeId: chargeId,
                latePaymentChargeId: null,
                siblingCharge: false,
              }
            );
          }
        }
      });

      this.selectedChargeIds = selectedIds;
    }
  }

  latePaymentchargeIdChange(selectedId: number) {
    const categoryKey = 'fees_charges';

    const category = this.accountingCategories.find(
      (cat) => cat.key === categoryKey
    );

    if (category) {
      // Remove the row associated with the previous selected charge ID
      if (this.previousSelectedId !== null) {
        const previousRow = category.value.row.find(
          (row) => row.latePaymentChargeId === this.previousSelectedId
        );
        if (previousRow) {
          const indexToRemove = category.value.row.indexOf(previousRow);
          category.value.row.splice(indexToRemove, 1);
        }
      }

      //if selectedId is null exit
      if (selectedId === null) return;

      const charge = this.penaltyCharges.find(
        (charge) => charge.id === selectedId
      );
      if (charge) {
        const eventName = 'CRON';
        const eventMapping = 'PENALTY_RECEIVABLE';
        const selectedMapping = this.eventMaps.find(
          (item) => item.mapping_name.toLowerCase() === eventName.toLowerCase()
        );
        category.value.row.push({
          label: charge.name,
          eventName: this.utils.titleCase(
            eventName + ' ' + this.utils.underScoreToSpace(eventName)
          ),
          debitGlId: null,
          debitGlName: '',
          creditGlName: '',
          creditGlId: null,
          eventMapping: this.utils.titleCase(
            this.utils.underScoreToSpace(eventMapping)
          ),
          groupLabel: category.value.name,
          creditGlAccountList: this.glAccounts,
          debitGlAccountList: this.glAccounts,
          eventMappingList: selectedMapping.mappings.split(', ') ?? [],
          eventNameList: this.eventMaps[0].mappings.split(', ') ?? [],
          chargeId: null,
          latePaymentChargeId: selectedId,
        });
      }

      this.previousSelectedId = selectedId;
    }
  }

  isLatePaymentCharge(eventName: string, eventMapping: string): boolean {
    if (
      eventName.toLocaleLowerCase() === 'cron' &&
      eventMapping.toLocaleLowerCase() === 'penalty receivable'
    ) {
      return true;
    }

    return false;
  }

  isContainCharge(catKey: string): boolean {
    const categoryKey = 'fees_charges';
    if (catKey !== categoryKey) return true;

    const category = this.accountingCategories.find(
      (cat) => cat.key === catKey
    );

    if (category) {
      return category.value.row.some((row) => row.chargeId !== null);
    }

    // Return false if the category with the specified key is not found
    return false;
  }

  notSiblingCharge(row: AccountingTransaction): boolean {
    //Returns true when row has a charge and not a sibling charge
    return row.chargeId && !row.siblingCharge;
  }

  isDefaultDisbursement(row: AccountingTransaction): boolean {
    return row.eventName === 'DISBURSEMENT-PRINCIPAL';
  }
  isDefaultRepayment(row: AccountingTransaction): boolean {
    return row.eventName === 'REPAYMENT-PRINCIPAL';
  }
  isDefaultInterest(row: AccountingTransaction): boolean {
    return row.eventName === 'REPAYMENT-INTEREST';
  }
  isDefaultFee(row: AccountingTransaction): boolean {
    return row.eventName === 'DISBURSEMENT-CHARGE_PAID';
  }

  

  eventWithDefaultDebit(row: AccountingTransaction) {
    
    return this.defaultDebitEvents.includes(row.eventName);
  }
  eventWithDefaultCredit(row: AccountingTransaction) {
    return this.defaultCreditEvents.includes(row.eventName);
  }
}
