<table>
    <thead>
        <tr>
            <th>Business Type</th>
            <th>Count</th>
            <th>Percentage</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let n of [1,2,3,4,5,6]">
            <td>Makeup Artist</td>
            <td class="text-right">1,600</td>
            <td class="text-right">4.0%</td>
        </tr>
        <tr>
            <th>Total</th>
            <th class="text-right">46,000</th>
            <th class="text-right">100%</th>
        </tr>
    </tbody>
</table>