import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-avatar-ui',
  templateUrl: './image-avatar-ui.component.html',
  styleUrls: ['./image-avatar-ui.component.css']
})
export class ImageAvatarUiComponent implements OnChanges {
  @Input()classNames:any = 'rounded'
  @Input()defaultImage:any = 'assets/images/system-images/login-images/4.png'
  public baseUrl = environment.baseUrl + environment.coreUrl + 'data/';
  constructor(private http: HttpClient) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.defaultImage = this.baseUrl + this.defaultImage;
  }

  

}
