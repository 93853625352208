<div class="flex items-center">

    <!-- <i-feather class="icon" style="width: 35px; height:35px;" *ngIf="icon" [name]="icon"></i-feather> -->
    <button *ngIf="icon" [disabled]="isDisabled" class="border-none flex justify-center items-center text-center p-2 px-5" [ngClass]="iconClassNames">
        <mat-icon style="font-size: 20px;">add</mat-icon>
    </button>

    <button type="border-none p-2 px-5" [ngClass]="classNames" (click)="trigger(value)" class="button-icon flex items-center">
        <span *ngIf="name">{{ name }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
</div>