<app-sidebar (toggleSideNav)="onToggleSideNav($event)"></app-sidebar>

<app-header [u]="u" [showMobileToggler]="showMobileToggler" (sendNotificationEmit)="sendNotification($event)" (toggleSideNavEmit)="toggleSideNav()"></app-header>

<div class="body" [ngClass]="getBodyClass()">
    <router-outlet></router-outlet>
</div>
<app-main-loading-overlay [showLoader]="loading"></app-main-loading-overlay>

<a href="#nav" class="hide nav-off-screen-block" data-toggle="class:nav-off-screen, open" data-target="#nav"></a>
<app-footer></app-footer>

<!-- end main application -->

<!-- loading overlay -->
<!--/ loading overlay -->

<!-- modals -->
<app-modal-lock-screen [u]="u" (resetIdle)="reset()"></app-modal-lock-screen>
<!-- end modals -->