import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, throwError} from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';

import { AuthService } from 'src/app/auth/services/auth.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { LoadingService } from '../services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class WizerHttpInterceptor implements HttpInterceptor {
  numberOfTimesErrorOccured: any = 0;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    private toast: NotificationService,
    private authService: AuthService,
    private _loading: LoadingService,
    private router: Router
  ) {}

  private excludedEndpoints(req: HttpRequest<any>): boolean {
    const excludedEnpoints = [
      'login',
      'forgot-password',
      'verifyToken',
      'reset-password',
    ];
    const endpointParts = req.url.split('/');
    const endpoint = endpointParts[endpointParts.length - 1];
    // exception for enpoint (['https:', '', 'geolocation-db.com', 'json', ''])
    if(endpointParts[endpointParts.length - 2] === 'json') return true
    return excludedEnpoints.includes(endpoint);
  }

  private excludedEndpointsLoader(req: HttpRequest<any>): boolean {
    const excludedEnpoints = [
      'branch/list-branch',
      'areas/all-areas',
      'users/loan-officers',
      'savings-products/all',
      'loan-products/getAllLoanProducts',
      'depositAccounts/download',
      'reports/export-to-excel',
      'reports/export-to-pdf',
      'journal-entries/download',
      'journal-entries/compact-download',
      'reports/branchClientOverviewDownload',
      'reports/top-client-excel',
      'depositAccountReport/download',
      'api/refresh_token',
      'transactions/download',
      'clients/approvedClients',
      'loan-accounts/disbursedLoans',
      'loan-accounts/amountDisbursed',
      'loan-accounts/runningLoans',
      'loan-accounts/loansOutstanding',
      'loan-accounts/depositBalance',
      'loan-accounts/totalRepayment',
      'loan-accounts/loanProductPar',
    ];
    const removedQueryParams = req.url.split('?');
    const endpointParts = removedQueryParams[0].split('/');
    const endpoint = `${endpointParts[endpointParts.length - 2]}/${endpointParts[endpointParts.length - 1]}`;
    return excludedEnpoints.includes(endpoint);
  }

  private excludedRouteUrlsLoader (route: Router): boolean {
    const excludedRouteUrls = [
      '/',
    ];
    const currentRoute = route.url;
    return excludedRouteUrls.includes(currentRoute);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    /* exclude endpoint and route loader */
    if(!this.excludedEndpointsLoader(req) && !this.excludedRouteUrlsLoader(this.router)) this._loading.setLoading(true, req.url);
    
    /* exclude auth */
    if (this.excludedEndpoints(req)) return next.handle(req).pipe(
      finalize(()=>this._loading.setLoading(false, req.url))
    );;

    if (req.url.split('/')[req.url.split('/').length - 1] == 'keep-alive') {
    }
    if (req.url.split('/')[req.url.split('/').length - 1] == 'export-to-excel') {
      this.toast.info($localize`:@@download_starting:Download Starting...`);
    }
    const token = localStorage['token']?.replaceAll('"', '');
    if(!token) this.router.navigate(['login']);
    const locale = localStorage.getItem('locale');
    const newReq = req.clone({
      headers: req.headers
        .set('Authorization', `Bearer ${token}`)
        .set('Cache-Control', 'no-cache no-store, must-revalidate')
        .set('Pragma', 'no-cache')
        .set('Accept-Language', locale)
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
        .set('If-Modified-Since', '0'),
    });

    return next.handle(newReq).pipe(
      catchError((err)=>this.handleError(err, next, newReq)),
      finalize(()=>{
        if (req.url.split('/')[req.url.split('/').length - 1] == 'export-to-excel') {
          this.toast.success($localize`:@@download_completed:Download Completed`);
        }
        this._loading.setLoading(false, newReq.url)
      })
    );
  }

  handleResponse(req: HttpRequest<any>, data) {}

  handleError(
    err: HttpErrorResponse,
    next: HttpHandler,
    req: HttpRequest<any>
  ) {
    // const that = this;
    
    if (err.status == 504 || err.status == 0) {
      // if (that.numberOfTimesErrorOccured == 1) {
        // this.toast.warning('Warning!. Please if you are unable to load up data on the application, please log out and log back in or refresh the page. Sorry for the inconvenience');
      // }
    }else if (err.status == 503 || err.error.message === 'Unable to find instance for NEW-CORE-SERVICE') {
     this.toast.error($localize`:@@server_not_reachable:Server not reachable!`);
    }
    else if (err.status == 500) {
      this.toast.error($localize`:@@an_error_occured:An Error Occured!`);
    } 
    else if (err.error.code == 97) {
      //session timed out
      $('#modal-lock-screen').show();
    } else if (err.status == 401) {
      //expired token
      return this.refreshToken(req, next);
    } else {
      this.toast.error(err.error.message);
    }
    
    return throwError(()=>err);
  }

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  
  private refreshToken(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((res: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(res.data.access_token);
          return next.handle(
            this.addToken(request, res.data.access_token)
          );
        }),
        catchError((err)=>{

          if(err.status === 403){
            localStorage.clear();
            this.toast.error('Session Timeout');
            this.router.navigate(['login'])
          }
          return throwError(()=>err)
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

}
