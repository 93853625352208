<form class="flex flex-wrap items-center gap-[1.8%] gap-y-6 mt-12" [formGroup]="reportsForm" autocomplete="off">
    <ng-container *ngFor="let input of inputsToShow; trackBy: byInputType">

        <ng-container [ngSwitch]="input">

            <mat-form-field class="w-[150px]"  appearance="outline" *ngSwitchCase="reportInputTypes.DATE">
                <mat-label>Date</mat-label>
                <input readonly matInput [min]="minDate" [max]="maxDate" [matDatepicker]="date" placeholder="Choose a date" [formControlName]="reportInputTypes.DATE">
                <mat-datepicker-toggle matSuffix [for]="date">
                </mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w-[150px]" appearance="outline" *ngSwitchCase="reportInputTypes.START_DATE">
                <mat-label i18n="@@start_date">Start Date</mat-label>
                <input formControlName="startDate" name="startDate" [min]="startDateRange.min" [max]="startDateRange.max" readonly matInput [matDatepicker]="startDate" (dateChange)="dateListner()" placeholder="Start Date" i18n-placeholder="@@start_date" />
                <mat-datepicker-toggle matSuffix [for]="startDate"> </mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w-[150px]" appearance="outline" *ngSwitchCase="reportInputTypes.END_DATE">
                <mat-label i18n="@@end_date">End Date</mat-label>
                <input formControlName="endDate" name="endDate" [min]="endDateRange.min" [max]="endDateRange.max" readonly matInput [matDatepicker]="endDate" (dateChange)="dateListner()" placeholder="End Date" i18n-placeholder="@@end_date" />
                <mat-datepicker-toggle matSuffix [for]="endDate"> </mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="w-[150px]" appearance="outline" *ngSwitchCase="reportInputTypes.REPORT">
                <mat-label i18n="report_day">Report Day</mat-label>
                <mat-select formControlName="report">
                    <mat-option *ngFor="let day of reportDays" [value]="day.value">
                        {{ day.key }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <app-area-search-select *ngSwitchCase="reportInputTypes.AREA" [defaultSelected]="savedData?.savedArea" (emitSelectedVal)="emitSelectedVal(reportInputTypes.AREA,$event)" [control]="getAreaCtrl" [type]="type">
            </app-area-search-select>

            <app-branch-search-select *ngSwitchCase="reportInputTypes.BRANCH" [defaultSelected]="savedData?.savedBranch" (emitSelectedVal)="emitSelectedVal(reportInputTypes.BRANCH,$event)" [control]="getBranchCtrl" [areaId]="selectedAreaId" [type]="type"></app-branch-search-select>

            <app-client-deposit-search-select *ngSwitchCase="reportInputTypes.DEPOSIT_CLIENT" [showDefaultListValue]="showDepositClientDefaultListValue" [clientData]="depositClientData" [defaultSelected]="savedData?.savedDepositClient" (emitSelectedVal)="emitSelectedVal(reportInputTypes.DEPOSIT_CLIENT,$event)" [branchId]="selectedBranchId" [areaId]="selectedAreaId"
                [control]="getDepositClientCtrl"></app-client-deposit-search-select>


            <app-client-search-select *ngSwitchCase="reportInputTypes.CLIENT" [clientData]="clientData" [defaultSelected]="savedData?.savedClient" (emitSelectedVal)="emitSelectedVal(reportInputTypes.CLIENT,$event)" [branchId]="selectedBranchId" [areaId]="selectedAreaId"
                [control]="getClientCtrl"></app-client-search-select>

            <app-loan-officer-search-select *ngSwitchCase="reportInputTypes.LOAN_OFFICER" [defaultSelected]="savedData?.savedLoanOfficer" (emitSelectedVal)="emitSelectedVal(reportInputTypes.LOAN_OFFICER,$event)" [control]="getLoanOfficerCtrl" [branchId]="selectedBranchId"
                [areaId]="selectedAreaId" [type]="type"></app-loan-officer-search-select>

            <app-loan-product-search-select *ngSwitchCase="reportInputTypes.LOAN_PRODUCT" [defaultSelected]="savedData?.savedLoanProduct" (emitSelectedVal)="emitSelectedVal(reportInputTypes.LOAN_PRODUCT,$event)" [control]="getProductCtrl" [type]="type"></app-loan-product-search-select>

            <app-deposit-product-search-select *ngSwitchCase="reportInputTypes.DEPOSIT_PRODUCT" [showDefaultListValue]="showDepositDefaultListValue" [defaultSelected]="savedData?.savedDepositProduct" (emitSelectedVal)="emitSelectedVal(reportInputTypes.DEPOSIT_PRODUCT,$event)" [control]="getDepositProductCtrl" [type]="type"></app-deposit-product-search-select>

            <app-loan-status-search-select *ngSwitchCase="reportInputTypes.LOAN_STATUS" (emitSelectedVal)="emitSelectedVal(reportInputTypes.LOAN_STATUS,$event)" [control]="getStatusCtrl"></app-loan-status-search-select>
            <app-group-search-select *ngSwitchCase="reportInputTypes.GROUP" [defaultSelected]="savedData?.savedGroup" (emitSelectedVal)="emitSelectedVal(reportInputTypes.GROUP, $event)" [control]="getGroupCtrl" [loanOfficerId]="selectedLoanOfficerId"></app-group-search-select>

            <mat-form-field appearance="outline" *ngSwitchCase="reportInputTypes.LIMIT">
                <mat-label i18n="@@limit">Limit</mat-label>
                <input type="text" matInput appStrictNumbers [formControl]="getLimitCtrl" (keyup)="emitInputedVal('limit', getLimitCtrl.value)" autocomplete="off" />
            </mat-form-field>

        </ng-container>
    </ng-container>
    <ng-content></ng-content>
</form>

<div class="mt-6 mb-5" *ngIf="showTableActionFilter">
    <app-table-action-filter [showRunButton]="true" [title]="title" [activeState]="activeState" [initialValue]="initialValue" [states]="states" [actionButtons]="actionButtons" [showActionBar2]="showActionBar2" [disableActionButtons]="disableActionButtons"
        [showSearchInput]="showSearchInput" [showDownloadButton]="showDownloadButton" [runText]="runText" [runIconPath]="runIconPath" [runMenu]="runMenu" [showNumOfRowSelected]="showNumOfRowSelected" [numOfRowSelected]="numOfRowSelected" [totalAmtOfRowSelected]="totalAmtOfRowSelected"
        (searchEmit)="onSearch($event)" (runMainMenu)="onRunMenu()" (runMenuChange)="onRunMenuChange($event)" (stateEmit)="onStateChange($event)" (actionEmit)="onActionTrigger($event)"></app-table-action-filter>
</div>

<div *ngIf="showSummaryData && payload?.length > 0" class="inline-flex bg-summary rounded-[7px] py-[10px] px-[30px] mb-[10px] gap-[50px]">
    <ng-container *ngFor="let item of summaryData">
        <label *ngIf="item.type === 'currency'">{{item.label}}: <span class="font-semibold">{{ item.value| wz_currency }}</span></label>
        <label *ngIf="item.type === 'number'">{{item.label}}: <span class="font-semibold">{{ item.value | number }}</span></label>
        <label *ngIf="item.type !== 'number' && item.type !== 'currency'">{{item.label}}: <span class="font-semibold">{{ item.value }}</span></label>
    </ng-container>
</div>