import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import type { EChartsOption } from 'echarts';
import { pieData } from '../datasource';
import { UtilityService } from 'src/app/utils/utility.service';
import * as echarts from 'echarts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css'],
})
export class DoughnutChartComponent implements OnInit, OnChanges {
  @ViewChild('echarts') echartsElement: ElementRef;

  @Input() width: string = '300px';
  @Input() palette?: string[] = ['#4d5d76', '#929FB1', '#D4D9E2'];
  @Input() chartData?: Object[] = pieData;
  formattedTotalSum: string = '';
  @Input() hasCurrencySymbol: boolean = false;


  @Input() options: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    legend: {
      top: '5%',
      left: 'center',
      show: false,
    },
    grid: {
      width: '100%', // Set the width of the chart within the grid
      height: '50%', // Set the height of the chart within the grid
    },
    series: [
      {
        tooltip: {
          textStyle:{
            fontSize:11
          }
        },
        name: 'Approved Branch',
        type: 'pie',
        radius: ['25%', '68%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          position: 'inner',
          // formatter: '{b}\n{this.utilityService.formatTotalNumber(c)}\n{d}%',
          formatter: ({ name, value, percent }) => {
            return `${name}\n${this.utilityService.formatTotalNumber(
              value,'1.2-2', false
            )}\n${percent.toFixed(0)}%`;
          },
          fontSize: 11,
          color: '#FCFCFC',
          fontFamily: 'Poppins',
          fontWeight: 400,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        labelLine: {
          show: true,
        },
        data: [
          { value: 1048, name: 'Search Engine' },
          { value: 735, name: 'Direct' },
          { value: 580, name: 'Email' },
          { value: 484, name: 'Union Ads' },
          { value: 300, name: 'Video Ads' },
        ],
      },
    ],
    graphic: [
      {
        type: 'text',
        left: 'center',
        top: '48%',
        style: {
          text: '₦100.0m',
          fill: '#3A3A3A',
          fontSize: 11,
          fontWeight: 600,
          fontFamily: 'Poppins',
        },
      },
      {
        type: 'text',
        left: 'center',
        top: '53%',
        style: {
          text: 'Total',
          fill: '#3A3A3A',
          fontSize: 11,
          fontWeight: 400,
          fontFamily: 'Poppins',
        },
      },
    ],
  };

  constructor(private utilityService: UtilityService) {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']) {
      this.chartData = changes['chartData'].currentValue;
      this.setUpChart();
    }
  }

  setUpChart() {
    this.options.color = this.palette;
    this.options.series[0].data = this.chartData;

    if (this.chartData.length > 5) {
      this.options.series[0].label.position = 'outer';
      this.options.series[0].label.color = 'black';
    }

    let totalSum = 0;
    for (const data of this.options.series[0].data) {
      totalSum += data.value;
    }

    this.formattedTotalSum = this.hasCurrencySymbol
      ? this.utilityService.formatTotalNumber(totalSum)
      : this.utilityService.formatTotalNumber(totalSum, '1.2-2', false);

    this.options.graphic[0].style.text = this.formattedTotalSum;
    this.updateChartOptions()
  }

  updateChartOptions(): void {
    if (this.echartsElement && this.echartsElement.nativeElement) {
      const echartsInstance = echarts.init(this.echartsElement.nativeElement);
      echartsInstance.setOption(this.options);
    }
  }

  ngOnInit(): void {
    this.setUpChart()
  }
}
