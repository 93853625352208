import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { PostingRequest } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostingsService {
  baseUrl: string = environment.baseUrl + environment.accountUrl;
  reportUrl: string = environment.baseUrl + environment.reportUrl
  accountUrl: string = environment.baseUrl + environment.accountUrl

  constructor(
    private http: HttpClient,
    private utilityService: UtilityService,
  ) { }

  public getBulkLoanForDisbursement(req:any): Observable<any>{
    return this.http.get(`${this.accountUrl}loan-accounts/approved-loans`, {params: req});
  }

  public getBulkLoanForApproval(req:any): Observable<any>{
    return this.http.get(`${this.baseUrl}loan-accounts/pending-loans`, {params: req});
  }

  public approveBulkLoans(payload: any): Observable<any>{
    return this.http.put(`${this.baseUrl}loan-accounts/bulk-loan-approval`, payload);
  }

  public disburseBulkLoans(payload: any): Observable<any>{
    return this.http.put(`${this.baseUrl}loan-accounts/bulk-loan-disbursement`, payload);
  }

  public downloadPDF(req:any): Observable<any>{
    const headers = new HttpHeaders({'Accept': 'application/pdf'} )
    return this.http.get(`${this.reportUrl}export-to-pdf`, { headers, params: req, responseType: 'blob'});
  }

  public downloadExcel(req:any): Observable<any>{
  const headers = new HttpHeaders({'Accept': 'application/vnd.ms-excel'} )
    return this.http.get(`${this.reportUrl}export-to-excel`, { headers, params: req, responseType: 'blob' });
  }
  public downloadGroupPostingExcel(req:any): Observable<any>{
  const headers = new HttpHeaders({'Accept': 'application/vnd.ms-excel'} )
    return this.http.get(`${this.baseUrl}group-postings/download`, { headers, params: req, responseType: 'blob' });
  }

  public getGroupPostings(queryParams:PostingRequest): Observable<any>{
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http.get(`${this.baseUrl}group-postings${payload}`);
  }
  public getGroupPosting(id:string): Observable<any>{
    return this.http.get(`${this.baseUrl}group-postings/${id}`).pipe(map((res:any)=>{
      if(res.successful){
        res.data = this.extractPostingDetail(res.data);
      }
      return res
    }));
  }
  
  public getGroupPostingDetail(groupId:number): Observable<any>{
    return this.http.get(`${this.baseUrl}group-postings/fetch?group-id=${groupId}`).pipe(map((res:any)=>{
      if(res.successful){
        res.data = this.extractPostingDetail(res.data);
      }
      return res
    }));
  }

  private extractPostingDetail(data:any){
    const posting = JSON.parse(JSON.stringify(data));
    posting.clientPostingDTOS = posting.clientPostingDTOS.map(client=>({...client, ...client.postings[0]}))
    return posting
  }

  public postGroupPosting(payload:Object): Observable<any>{
    return this.http.post(`${this.baseUrl}group-postings`, payload);
  }

  public approveGroupPosting(payload: any): Observable<any>{
    return this.http.post(`${this.baseUrl}group-postings/approve`, payload);
  }

  public rejectGroupPosting(payload: any): Observable<any>{
    return this.http.post(`${this.baseUrl}group-postings/reject`, payload);
  }

  
}
