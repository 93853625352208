import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/utils/permission.service';
import { StateTypes } from '../../models/common';

@Component({
  selector: 'app-button-toggle-ui2',
  templateUrl: './button-toggle-ui2.component.html',
  styleUrls: ['./button-toggle-ui2.component.css']
})
export class ButtonToggleUi2Component {
  @Input() btnClass = 'w-[200px]';
  @Input() buttons: StateTypes[] = [];
  @Output() stateEmit: EventEmitter<StateTypes['value']> = new EventEmitter();

  constructor(private permissionsService: PermissionService) {}
  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }
  toggleActive(val: StateTypes['value']) {
    this.buttons.forEach((button) => {
      button.isActive = button.value === val;
    });
    this.stateEmit.emit(val);
  }
}
