import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-proceed-modal',
  templateUrl: './proceed-modal.component.html',
  styleUrls: ['./proceed-modal.component.css']
})
export class ProceedModalComponent implements OnInit {

  public message = "Would you like to proceed?"

  public proceedVal ={
    name: "Proceed",
    class: "p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[150px]"
  }
  public cancelVal = {name:"Cancel",
  class: "p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[150px]"
}
constructor(
  private fb: FormBuilder,
  private dialogRef: MatDialogRef<ProceedModalComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any
) { }

public cancel(){
  this.dialogRef.close();
}

public onSubmit(){
  this.dialogRef.close(true);
}

ngOnInit(): void {
  if(this.data?.message){
    this.message = this.data.message
  }
  
  if(this.data?.proceedVal) this.proceedVal =  this.data.proceedVal;
  if(this.data?.cancelVal) this.cancelVal =  this.data.cancelVal;
}

}
