<app-chart-wrapper [isLoading]="isLoading" [title]="'Daily Repayment vs. Disbursement'" (fullscreen)="setHideFilters($event)">
    <!-- Filters -->
    <form filter [formGroup]="form" class="flex justify-between mb-6">
        <div class="flex gap-1 w-[65%]">
            <mat-form-field class="w-[48%]" appearance="outline">
                <mat-label i18n="@@start_date">Start Date</mat-label>
                <input (dateChange)="filterDailyRepayment({ type: reportsInput.START_DATE, value: $event.value})" [formControlName]="reportsInput.START_DATE" name="startDate" [min]="startDateRange.min" [max]="startDateRange.max" readonly matInput [matDatepicker]="startDateFilter"
                    placeholder="Start Date" i18n-placeholder="@@start_date"  />
                <mat-datepicker-toggle matSuffix [for]="startDateFilter">
                </mat-datepicker-toggle>
                <mat-datepicker #startDateFilter></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="w-[48%]" appearance="outline">
                <mat-label i18n="@@end_date">End Date</mat-label>
                <input (dateChange)="filterDailyRepayment({ type: reportsInput.END_DATE, value: $event.value})" [formControlName]="reportsInput.END_DATE" name="endDate" [min]="endDateRange.min" [max]="endDateRange.max" readonly matInput [matDatepicker]="endDateFilter"
                    placeholder="End Date" i18n-placeholder="@@end_date"  />
                <mat-datepicker-toggle matSuffix [for]="endDateFilter"> </mat-datepicker-toggle>
                <mat-datepicker #endDateFilter></mat-datepicker>
            </mat-form-field>
        </div>

        <app-loan-product-search-select (emitSelectedVal)="filterDailyRepayment({ type: reportsInput.LOAN_PRODUCT, value: $event?.id })" class="w-[35%]" [control]="productCtrl" type="client" >
        </app-loan-product-search-select>

    </form>
    <!-- End Filters -->

    <app-line-chart *ngIf="dailyDisbursementChartData?.length && !isLoading" (emitCurrentChartData)="setCurrentData($event)" [setup]="chartSetup" chart [chartData]="dailyDisbursementChartData"></app-line-chart>

    <table *ngIf="dailyDisbursementChartData?.length && !isLoading" table id="grid2" class="w-full text-[9px]">
        <thead>
            <tr class="border-b">
                <th class="border-r"></th>
                <th class="border-r text-right">
                    <div class="flex items-center gap-2 justify-end">
                        <span class="flex items-center gap-1">
              <i
                *ngFor="let item of [].constructor(3); let i = index"
                [ngStyle]="{ 'background-color': chartSetup.yAxis[0].color }"
                class="rounded-full w-[3px] h-[3px] text-transparent"
                [ngClass]="
                  i === 1 ? '!w-[10px] !h-[10px] border border-white' : ''
                "
                >.</i
              >
            </span>
                        <span> Repayment </span>
                    </div>
                </th>
                <th class="text-right">
                    <div class="flex items-center gap-2 justify-end">
                        <span class="flex items-center gap-1">
              <i
                *ngFor="let item of [].constructor(3); let i = index"
                [ngStyle]="{ 'background-color': chartSetup.yAxis[1].color }"
                class="rounded-full w-[3px] h-[3px] text-transparent"
                [ngClass]="
                  i === 1 ? '!w-[10px] !h-[10px] border border-white' : ''
                "
                >.</i
              >
            </span>
                        <span> Disbursement </span>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="border-b">
                <td class="text-center border-r">Today</td>
                <td class="text-right border-r">
                    {{ utils.currencyFormat(summary?.today[0].totalRepaymentAsOfToday) }}
                </td>
                <td class="text-right">
                    {{ utils.currencyFormat(summary?.today[0].totalDisbursementAsOfToday) }}
                </td>
            </tr>
            <tr class="border-b">
                <td class="text-center border-r">
                    {{ currentData.startDate | date : "d MMM. yy" }} - {{ currentData.endDate | date : "d MMM. yy" }}
                </td>
                <td class="text-right border-r">
                    {{ utils.currencyFormat(currentData.totalRepayment) }}
                </td>
                <td class="text-right">
                    {{ utils.currencyFormat(currentData.totalDisbursement) }}
                </td>
            </tr>
            <tr class="border-b">
                <td class="text-center border-r">
                    {{ startDate | date : "d MMM. yy" }} - {{ endDate | date : "d MMM. 'yy" }}
                </td>
                <td class="text-right border-r">
                    {{ utils.currencyFormat(summary?.range[0].totalRepayment) }}
                </td>
                <td class="text-right">
                    {{ utils.currencyFormat(summary?.range[0].totalDisbursement) }}
                </td>
            </tr>
        </tbody>
    </table>
</app-chart-wrapper>