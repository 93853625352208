<div>
    <h1 class="mb-5 text-2xl">{{ title }}</h1>

    <div class="panel panel-default">
        <div class="panel-heading">
          <h4 class="panel-title">{{ listTitle }}</h4> 
        </div>
        <div class="panel-body border-b-2 rounded-none border-b-gray-100" *ngFor="let list of unhandledList">
          Panel content
        </div>                
    </div>

    <div class="flex flex-wrap gap-10 justify-center">
        <app-button-icon-ui [name]="'Cancel'" [value]="'cancel'" (clickEmit)="cancel()" [classNames]="'bg-error rounded-xl text-white h-[40px]'" [tooltip]="'Cancel'"></app-button-icon-ui>
        <app-button-icon-ui [name]="'Remove All'" [value]="'remove_all'" (clickEmit)="removeAll()" [classNames]="'bg-success rounded-xl hover:bg-success text-white h-[40px]'" [tooltip]="'Remove All'"></app-button-icon-ui>
    </div>
</div>