import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChartsModule } from 'src/app/shared/charts/charts.module';
import { OperationalIndicatorsComponent } from './components/operational-indicators/operational-indicators.component';
import { FinancialIndicatorsComponent } from './components/financial-indicators/financial-indicators.component';
import { UserActivityComponent } from './components/user-activity/user-activity.component';
import { SummaryCardsComponent } from './summary-cards/summary-cards.component';
import { TopFiltersComponent } from './top-filters/top-filters.component';
import { OperationalIndicatorsModule } from './components/operational-indicators/operational-indicators.module';
import { FinancialIndicatorsModule } from './components/financial-indicators/financial-indicators.module';
import { UserActivityModule } from './components/user-activity/user-activity.module';

const route: Routes = [
  {
    path: '',
    component: DashboardComponent,
  }
]

@NgModule({
  declarations: [
    DashboardComponent,
    OperationalIndicatorsComponent,
    FinancialIndicatorsComponent,
    UserActivityComponent,
    SummaryCardsComponent,
    TopFiltersComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartsModule,
    OperationalIndicatorsModule,
    FinancialIndicatorsModule,
    UserActivityModule,
    RouterModule.forChild(route),
  ]
})
export class DashboardModule { }
