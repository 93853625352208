import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-doc-viewer',
  templateUrl: './modal-doc-viewer.component.html',
  styleUrls: ['./modal-doc-viewer.component.css']
})
export class ModalDocViewerComponent implements OnInit {
  fetchedPhoto:any;
  public get title (){
    return $localize`:@@view_image:View Image`
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.fetchedPhoto = this.data.docUrl;
  }

}
