// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://api.myaquila.com/',
  baseUrl: 'https://aquila-v2-api.wizertestserver.com/',
  authUrl: 'auth/api/',
  coreUrl: 'core/',
  reportUrl: 'reports/',
  accountsUrl: 'accounts',
  accountUrl: 'accounts/',
  accountingUrl: 'accounting/',
  imgageUrl: 'data/image?imageName=',
  documentUrl: 'data/document?documentName=',
  clientId: 'WEB_ADMIN',
  indexDBName: 'aquila-database',
  indexDBVersion: 2.0,
  countryInfo: {
    code: 'NGN',
    locale: 'en',
  },
  datePickerOptions: {
      format: 'dd-mm-yyyy',
      autoclose: true,
      todayHighlight: true
  },
  monthYearDateOptions: {
    format: 'M, yyyy',
    autoclose: true,
    todayHighlight: true,
    startView: 'months',
    minViewMode: 'months'
  }
};

export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
