import { Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
  EMPTY,
  Observable,
  catchError,
  delay,
  filter,
  finalize,
  firstValueFrom,
  forkJoin,
  from,
  of,
  tap,
} from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { IndexedDBService } from 'src/app/indexDB/indexedDB.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { PermissionService } from 'src/app/utils/permission.service';
import { UtilityService } from 'src/app/utils/utility.service';
import { WebSocketService } from 'src/app/utils/webSocket.service';

interface SideNavToggle {
  screenWidth: number;
  collapsed: boolean;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  public u = {};
  public modalNotifications;
  public showMobileToggler: boolean = false;
  public showSideNav: boolean = true;
  public loading: boolean = false;
  /*
   * Keep Alive
   * */
  private idleState = 'Not started.';
  private timedOut = false;
  private lastPing?: Date = null;

  isSideNavCollapsed = false;
  screenWidth = 0;

  constructor(
    private idle: Idle,
    private keepalive: Keepalive,
    private utils: UtilityService,
    private _auth: AuthService,
    private _loading: LoadingService,
    private permissionService: PermissionService,
    private iDBService: IndexedDBService,
    private webSocketService: WebSocketService
  ) {
    this.initIdleTimeOut();
    this.permissionService.evaluateUsersPermissions()
    this.listenToLoading();
  }

  // open notification modal
  public sendNotification(data: any) {
    this.modalNotifications = data;
  }

  // show or hide side menu
  public toggleSideNav() {
    this.showSideNav = !this.showSideNav;
  }

  // on mobile, show hamburger menu to toggle SideNav
  private isMobile() {
    if (window.innerWidth < 1024) {
      this.showMobileToggler = true;
      this.showSideNav = false;
    }
    if (
      window.innerWidth <= 1366 &&
      window.innerHeight > 1000 &&
      window.innerHeight <= 1030
    ) {
      this.showMobileToggler = true;
      this.showSideNav = false;
    }
  }

  private listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });
  }

  private initIdleTimeOut() {
    // sets an idle timeout of 600 seconds, for testing purposes.
    this.idle.setIdle(600);
    // sets a timeout period of 30 seconds. after 180 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.lockScreen();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(60);

    this.keepalive.onPing.subscribe(() => {
      if (!this._auth.getToken) {
        this.keepalive.stop();
        return;
      };
      this._auth.refreshToken().subscribe(res=>this.permissionService.evaluateUsersPermissions());
      
      this.lastPing = new Date();
      const isIndexDBFetched = JSON.parse(
        localStorage.getItem('isIndexDBFetched') || null
      );
      if (!isIndexDBFetched && this._auth.getToken) {
        this.webSocketService.storeIndexedDbData();
      }else{
       
      }
    });

    this.reset();
  }
  // restart idle period
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
  // lock screen (only password needed)
  lockScreen(): void {
    // if (!localStorage.getItem('token')) {
    this.utils.hideLoading();
    this.utils.lockScreen();
    this._auth.logout().subscribe({
      next: () => {
        //clear the indexDB table records
        this.iDBService.clearAllTables();
      },
      error: (err) => {},
    });
  }

  private onInt() {
    this.isMobile();
    this.iDBService.initializeDatabase();
  }

  onToggleSideNav(data: SideNavToggle) {
    this.isSideNavCollapsed = data?.collapsed;
    this.screenWidth = data?.screenWidth;
  }

  getBodyClass() {
    let styleClass = '';

    if (this.isSideNavCollapsed && this.screenWidth > 768)
      styleClass = 'body-trimmed';
    else if (this.isSideNavCollapsed && this.screenWidth <= 768)
      styleClass = 'body-md-screen ';

    return styleClass;
  }

  ngOnInit(): void {
    this.onInt();
  }
}
