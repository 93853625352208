export default function evalPageFromAndTo(page: any) {
    const index: number = page.pageNumber * page.pageSize
    let firstIndex: number = (index - page.pageSize) + 1
    if(page.pageNumber == 0) firstIndex = 0;
    let lastIndex: number = index
    // if last index is more than the total records return total recorods
    if ((page.totalRecords - lastIndex )<= 0) {
        lastIndex =  page.totalRecords
    }
    return {
        from: firstIndex,
        to: lastIndex,
    }
}