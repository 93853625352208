<header class="header-container">
    <img src="assets/images/new-images/logo-2.png" class="text-center header-image ml-[4.5em]" routerLink="/dashboard" />
    <div class="header-right-section">
        <div id="google_translate_element"></div>
        <div class="flex items-center">
            <a class="header-notification-dropdown px-2 pt-2" nz-dropdown nzTrigger="click" [nzDropdownMenu]="notification">
                <div *ngIf="_notifications === undefined || _notifications.length <= 0">
                    <mat-icon class="text-primary">notifications</mat-icon>
                </div>
                <div *ngIf="_notifications !== undefined && _notifications.length > 0">
                    <nz-badge [nzCount]="_notifications.length">
                        <mat-icon class="text-primary">notifications</mat-icon>
                    </nz-badge>
                </div>
            </a>

            <nz-dropdown-menu #notification="nzDropdownMenu">
                <div class="dropdown-menu dropdown-menu-right dropdown-down" id="notDrop" aria-labelledby="notDropdownMenu">
                    <div *ngFor="let notification of _notifications" class="dropdown-item dropdown-flex">
                        <div class="box"></div>
                        <div *ngIf="
                notification.notificationType !== 'SUPPORT' &&
                notification.notificationType !== 'KYC'
              " (click)="sendNotification(notification)" data-toggle="modal" data-target="#notificationModal">
                            {{ notification.name }}
                        </div>
                        <div *ngIf="notification.notificationType === 'SUPPORT'" (click)="removeNotification(notification.notificationType)" routerLink="/support">
                            {{ notification.name }}
                        </div>
                        <div *ngIf="notification.notificationType === 'KYC'" (click)="removeNotification(notification.notificationType)" routerLink="/kyc">
                            {{ notification.name }}
                        </div>
                    </div>
                    <div *ngIf="_notifications && _notifications.length === 0" class="dropdown-item dropdown-flex">
                        <div class="box"></div>
                        <div>No new notifications</div>
                    </div>
                </div>
            </nz-dropdown-menu>
        </div>

        <a class="header-dropdown-section dropdown-toggle" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <img class="dropdown-image" src="{{
          u.photo
            ? baseUrl + 'api/v1/photo/' + u.photo
            : 'assets/images/system-images/avatar.png'
        }}" />
            <b class="caret"></b>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul class="rounded-xl" nz-menu nzSelectable>
                <li class="text-[11px] flex justify-between items-center font-semibold" nz-menu-item>
                    <p routerLink="/settings" class="dropdown-item">Settings</p>
                    <mat-icon class="text-primary text-[16px] mt-3">settings</mat-icon>
                </li>
                <li class="text-[11px] flex justify-between items-center font-semibold" (click)="logout()" nz-menu-item>
                    <p class="dropdown-item">Logout</p>
                    <mat-icon class="text-tertiary text-[16px] mt-2">logout</mat-icon>
                </li>
                <p style="padding: 15px; opacity: 0.5;" class="username text-[11px]" id="clickedByUser">
                    {{ u.username | titlecase }}
                </p>
            </ul>
        </nz-dropdown-menu>
    </div>
</header>