<div class="circle relative">
    <div *ngIf="type === 'currency' " class="dynamic-content text-primary text-[30px] mt-[30px]" [matTooltip]="utilityService.currencyFormat(totalAmount)">
        {{ utilityService.formatTotalNumber(totalAmount, '1.0-0') }}
    </div>
    <div *ngIf="type === 'number' " class="dynamic-content text-primary text-[30px] mt-[30px]">
        {{ utilityService.formatTotalNumber(totalAmount, '1.0-0', false) }}
    </div>
    <div *ngIf="type === 'percentile' " class="dynamic-content text-primary text-[30px] mt-[30px]">
        {{ totalAmount | wz_percentage_format_2 }}
    </div>
    <!-- <img [ngStyle]="{'width': width, 'height': height}" [src]="imageName" alt="Dynamic Image"> -->
    <mat-icon [ngClass]="classNames" [ngStyle]="{'width': width, 'height': height}" [svgIcon]="imageName"></mat-icon>
</div>