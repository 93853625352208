<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="bg-bg_outline text-black-70 p-6">
    <!-- Add panel class 'entry-dialog' and width property on each instances for style correction  -->
    <app-modal-header-ui [title]="title" (closeModal)="cancel()"></app-modal-header-ui>

    <div class="dialog-body">

        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field style="width: 450px;" class="w-full" appearance="outline">
                <mat-label> {{inputTitle}} </mat-label>
                <input ngClass="data && data.disabled?'disabled:text-black-50': '' " required matInput formControlName="name" />
            </mat-form-field>

            <div style="gap: 0 10px;" *ngIf="!data || !data.disabled" class="flex items-center justify-center mb-3">
                <app-button-icon-ui [buttonType]="'button'" classNames="btn btn-warn w-100px" (clickEmit)="cancel()" [name]="cancelVal.name" value="cancel"></app-button-icon-ui>
                <app-button-icon-ui [buttonType]="'submit'" [classNames]="proceedVal.class" [name]="proceedVal.name" value="proceed"></app-button-icon-ui>
            </div>
        </form>
    </div>
</div>