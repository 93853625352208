import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';

@Component({
  selector: 'app-search-ui',
  templateUrl: './search-ui.component.html',
  styleUrls: ['./search-ui.component.css'],
})
export class SearchUiComponent implements OnInit {
  @Input() set initialVal(val:string){
    if(this.search){
      this.search.setValue(val)
    }
  }
  @Input() placeholder = 'Search...';
  @Input() debounceTime:number = 800;
  @Output() searchEmit: EventEmitter<any> = new EventEmitter();
  public search: FormControl = new FormControl();
  constructor() {}

  ngOnInit(): void {
    
    this.search.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(this.debounceTime))
      .subscribe((val) => {
        this.searchEmit.emit(val)
      });
  }

}
