import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-comment-ui',
  templateUrl: './comment-ui.component.html',
  styleUrls: ['./comment-ui.component.css']
})
export class CommentUiComponent{

  reasonForm: FormGroup;

  public get title (){
    return $localize`:@@reason:Reason`
  }
  constructor(
    private dialogRef: MatDialogRef<CommentUiComponent>,
    private formBuilder: FormBuilder
  ) { 
    this.reasonForm = this.formBuilder.group({
      reason: ['', [Validators.required]],
    })
  }


  submit() {
    if (!this.reasonForm.valid) {
      this.reasonForm.markAllAsTouched()
      return;
    }

    this.dialogRef.close({
      data: this.reasonForm.value,
    })
  }

  cancel(){
    this.dialogRef.close()
  }

}
