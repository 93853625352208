import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDocViewerComponent } from '../../modals/modal-doc-viewer/modal-doc-viewer.component';

@Component({
  selector: 'app-button-img-viewer',
  templateUrl: './button-img-viewer.component.html',
  styleUrls: ['./button-img-viewer.component.css']
})
export class ButtonImgViewerComponent {
  @Input() value: any;
  @Output() clickEmit: EventEmitter<any> = new EventEmitter();
  constructor(
    private dialog: MatDialog,
  ) { }

  public trigger(val:any) {
    const dialogRef = this.dialog.open(ModalDocViewerComponent, {
      disableClose: true,
      panelClass: 'entry-dialog',
      width: '60%',
      data: { docUrl: val },
    });
    // this.clickEmit.emit(val);
  }

}
