import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Page, PageCtrl, PageCtrls } from '../../models/common';
import evalPageFromAndTo from '../../helperFunctions/evalPageFromAndTo';
import { Subject } from 'rxjs';
import { StaffService } from 'src/app/pages/staff-management/services/staff.service';




@Component({
  selector: 'app-pagination-ui',
  templateUrl: './pagination-ui.component.html',
  styleUrls: ['./pagination-ui.component.css'],
})
export class PaginationUiComponent {
  private defaultPage:Page = {
    firstPage: '0',
    lastPage: '0',
    nextPage: '0',
    pageNumber: 0,
    pageSize: 0,
    previousPage: '0',
    totalPages:  0,
    totalRecords: 0,
  }
  @Input() showPagination: boolean = true;
  @Input() set pageProp(page: any) {
    if(page) {
      let newPage;
      if(page?.totalPages > 0) {
       
        newPage = {
          firstPage: '1',
          lastPage: page?.totalPages ? page?.totalPages: '..',
          nextPage: page?.pageRequest ? (parseInt(page?.pageRequest?.page) + 2): '..',
          pageNumber: page?.pageRequest ? (parseInt(page?.pageRequest?.page) + 1): '..',
          pageSize: page?.pageRequest?.pageSize ? (parseInt(page?.pageRequest?.pageSize)): '..',
          previousPage: page?.pageRequest ? parseInt(page?.pageRequest?.page): '..',
          totalPages: page?.totalPages ? parseInt(page?.totalPages): '..',
          totalRecords: page?.totalElements ? parseInt(page?.totalElements): '..',
        }
       
        
      }else {
        newPage = this.defaultPage
      }
      this.page = newPage;
      this.updatePageControls(this.page);
    }   
    
  }
  @Input() pageSizeCounts: number[] = [10, 25, 50, 100];
  @Output() pageSizeToggleEmit: EventEmitter<number> = new EventEmitter();
  @Output() togglePageControlEmit: EventEmitter<PageCtrl> = new EventEmitter();

  public page: Page;
  public pageControls: PageCtrls = {
    next: false,
    prev: false,
    first: false,
    last: false,
  };
  private sub: any;

  private updatePageControls(page: Page) {
    this.page = { ...page, ...evalPageFromAndTo(page) };
    if (page.pageNumber === page.totalPages) {
      this.pageControls.last = true;
      this.pageControls.next = true;

      if (!page.previousPage || page.previousPage === '0') {
        this.pageControls.first = true;
        this.pageControls.prev = true;
        return;
      }
      this.pageControls.first = false;
      this.pageControls.prev = false;
    } else if (page.pageNumber === 1) {
      this.pageControls.last = false;
      this.pageControls.next = false;
      this.pageControls.first = true;
      this.pageControls.prev = true;
    } else {
      for (let ctrl in this.pageControls) {
        this.pageControls[ctrl] = false;
      }
    }
  }

  public togglePageControl(arrow: PageCtrl) {
    this.togglePageControlEmit.emit(arrow);
  }

  constructor() {
    this.updatePageControls(this.defaultPage);
  }

  public pageSizeToggle(target: EventTarget) {
    const select = target as HTMLSelectElement;
    this.pageSizeToggleEmit.emit(Number(select.value));
  }
}
