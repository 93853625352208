import { Component, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.css']
})
export class AccountTransactionsComponent {

  // For Transactions
  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: 'ID',
      key: 'ID',
      type: 'text',
      value: 'ID',
    },
    {
      name: 'Transaction Date',
      key: 'transaction_date',
      type: 'date',
      value: 'transaction_date',
    },
    {
      name: 'Submitted On',
      key: 'submitted_on',
      type: 'date',
      value: 'submitted_on',
    },
    {
      name: 'Type',
      key: 'type',
      type: 'text',
      value: 'type',
    },
    {
      name: 'Debit',
      key: 'debit',
      type: 'number',
      value: 'debit',
    },
    {
      name: 'Credit',
      key: 'credit',
      type: 'number',
      value: 'credit',
    },
    {
      name: 'Balance',
      key: 'balance',
      type: 'number',
      value: 'balance',
    },
    {
      name: 'Narration',
      key: 'narration',
      type: 'text',
      value: 'narration',
    },
   
  ]
  public payload = this.dummyData.depositTransactionsDummyTableData
  public transactions: any[] = []

  constructor(private dummyData: DummyDataService) { }

  public pageSizeToggle (size:number){
  }

  public pageCtrlToggle (ctrl:string){
  }

  onSort(value) {
  }

  onSearch(value) {
  }

}
