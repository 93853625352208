<div class="modal modal-lock-screen" id="modal-lock-screen" style="display: none" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-backdrop="static" data-keyboard="false">
    <div class="w-[100vw] h-[100vh]">
        <div class="flex justify-center items-center flex-col mt-[30%] sm:mt-[20%] md:mt-[10%]">
            <img class="rounded-full w-[150px] h-[150px] border" src="{{
          u.photo
            ? baseUrl + 'api/v1/photo/' + u.photo
            : 'assets/images/system-images/avatar.png'
        }}" />
            <br />
            <strong>{{ u.username | titlecase }}</strong>
            <div class="form-group" style="margin-top: 15px">
                <nz-input-group class="form-control" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" class="text-xs" nz-input (keyup.enter)="unlockScreen()" name="ppp" autocomplete="off" placeholder="Password" [(ngModel)]="unlockScreenParams.password" required />
                </nz-input-group>
                <ng-template #suffixTemplate>
                    <span class="pointer" nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'" (touchstart)="passwordVisible = true" (mousedown)="passwordVisible = true" (touchend)="passwordVisible = false" (mouseup)="passwordVisible = false"></span>
                </ng-template>
                <!-- <input type="password" (keyup.enter)="unlockScreen()" class="form-control" placeholder="Password" name="ppp" autocomplete="off" [(ngModel)]="unlockScreenParams.password" autocomplete="off" required /> -->
            </div>
            <div class="form-group">
                <!--<button type="button" (click)="logout()" class="btn btn-default">Cancel</button>-->
                <button #unlockBtn type="button" class="rounded-xl bg-success/90 hover:bg-success text-white p-3 px-6" (click)="unlockScreen()">
          Unlock
        </button>
            </div>
        </div>
    </div>
</div>