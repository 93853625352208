import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResetPasswordResolver } from './resolvers/wizer.resolver';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { SharedModule } from './shared/shared.module';
import { UtilsModule } from './utils/utils.module';
import { WizerHttpInterceptor } from './shared/common/wizer-http-interceptor.service';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_GB } from 'ng-zorro-antd/i18n';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { RouterExtService } from './shared/services/route.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { I18nModule } from './i18n/i18n.module';
import { IndexedDBService } from './indexDB/indexedDB.service';
import { LoginModule } from './auth/login/login.module';
// import { ModifyBackdatingComponent } from './roles/modify-backdating/modify-backdating.component';

registerLocaleData(en);


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    UtilsModule,
    FormsModule,
    HttpClientModule,
    LayoutModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
  ],
  providers: [
    I18nModule.setLocale(),I18nModule.setLocaleId(),
    ResetPasswordResolver,
    DatePipe,
    DecimalPipe,
    RouterExtService,
    // CanActivateChildRoutes,
    // CanLoadRoutes,
    { provide: MAT_DATE_LOCALE, useValue: 'en-UK' },
    // Web socket
    // {
    //   provide: InjectableRxStompConfig,
    //   useValue: myRxStompConfig,
    // },
    // {
    //   provide: RxStompService,
    //   useFactory: rxStompServiceFactory,
    //   deps: [InjectableRxStompConfig],
    // },
    // {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {disableClose: true}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WizerHttpInterceptor,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_GB },
    IndexedDBService,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
