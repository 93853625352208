import { DateLimit, SignatoryOpts } from '../models/common';

const currentYear = new Date().getFullYear();
export const DateOfBirthLimit: DateLimit = {
  start: new Date('01/01/1900'), //1970 set before
  end: new Date('12/31/2004'),
  startAge: new Date(
    currentYear - 60,
    new Date().getMonth(),
    new Date().getDate()
  ),
  endAge: new Date(
    currentYear - 18,
    new Date().getMonth(),
    new Date().getDate()
  ),
};

export enum AcctTypes {
  asset = 'ASSET',
  liability = 'LIABILITY',
  equity = 'EQUITY',
  income = 'INCOME',
  expense = 'EXPENSE',
}

export enum Modules {
  loan = 'loan',
  client = 'client',
}

export enum DefaultSignatureLabel {
  signature = 'signature',
  input = 'input',
  today = 'today'
}

export const SignatureOpts: SignatoryOpts[] = [
  {
    name: 'Signature',
    value: DefaultSignatureLabel.signature,
  },
  {
    name: 'Input',
    value: DefaultSignatureLabel.input,
  },
];
