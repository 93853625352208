import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  baseUrl: string = environment.baseUrl;

  userPermissions: string[] = [];

  constructor(private http: HttpClient, private dataService: DataService) { }

  public getRolePermissions(id: number): Observable<any>{
      return this.http.get(this.baseUrl + 'api/v2/internal/core/roles/rolePermissions/' + id);
  }

  public getRole(id: number): Observable<any>{
    return this.http.get(this.baseUrl + 'api/v2/internal/core/roles/' + id);
  }

  public getRoles(req:any): Observable<any>{
      return this.dataService.getList(this.baseUrl + 'api/v2/internal/core/roles', req);
  }

  public postRole(data: Object){
    return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/roles', data );
  }

  public updateDashboardLevel(data: Object) {
    return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/roles/update-dashboard-level', data );
  }

  public getAllPermissions(): Observable<any>{
      return this.http.get(this.baseUrl + 'api/v2/internal/core/roles/permissions');
  }

  /* Set/Unset role's permission */
  public setRolePermission(roleId: number, permissionId: number, state: boolean): Observable<any> {
      return this.http.post<any>(this.baseUrl + 'api/v2/internal/core/roles/setpermission', {
          roleId: roleId,
          permissionId: permissionId,
          state: state
      });
  }

  public setUserPermissions(permissions: string[]): void {
      this.userPermissions = permissions;
  }

  public getUserPermissions(): object {
      return this.userPermissions;
  }

  public hasPermission(permissionCode: string): boolean {
    return (this.userPermissions.includes(permissionCode)) ? true : false;
  }
}
