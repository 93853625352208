<div *ngIf="showLoader" id="loading-overlay">
    <div class="loading-img">
        <div class="loader-wrapper">
            <!-- Hello World
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div> -->
            <div class="z-[150]  font-semibold absolute w-full h-full m-auto flex items-center justify-center">
                <span class="loader"></span>
            </div>

        </div>
    </div>
</div>