<div class="py-3 px-3 rounded" *ngIf="items.length" [ngClass]="classNames">
    <ul class="flex justify-center gap-4 list-none">
        <ng-container *ngFor="let item of items; let i=index;">
            <mat-divider *ngIf="i !== 0" class="mx-4" [ngClass]="dividerClassNames" [vertical]="true"></mat-divider>
            <li class="flex flex-col gap-y-2 items-center ">
                <span class="">{{item.title}}</span>
                <span class="text-center font-semibold">{{item.subtitle}}</span>
            </li>

        </ng-container>
    </ul>
</div>