<div class="flex items-center justify-between bg-white">
    <app-button-icon-ui *ngIf="showBackBtn" [name]="'Back'" [value]="'back'" [icon]="'west'" (clickEmit)="goBack()" [classNames]="'text-secondary rounded-xl hover:bg-secondary hover:text-white border-black/20 h-[40px]'" [tooltip]="'Go Back'"></app-button-icon-ui>

    <div *ngIf="showNavigation && navButtons.length" class="flex">
        <app-nav-child-ui [navButtons]="navButtons" [parentPath]="parentPath"></app-nav-child-ui>
    </div>

    <h2 class="font-semibold text-lg" *ngIf="title">{{title}}</h2>

    <div class="text-right">
        <div class=" h-[30px] gap-x-1 flex items-center" role="group" aria-label="...">
            <ng-container *ngFor="let btn of titleActionButtons;">
                <ng-container *ngIf="btn.type === 'btn' && hasPermission(btn.perms)">
                    <app-button-icon-ui *hasPermissions="btn.perms" [name]="btn.name" [value]="btn.value" [icon]="btn.icon" (clickEmit)="trigger($event)" [classNames]="btn.classNames" [tooltip]="btn.tooltip"></app-button-icon-ui>
                </ng-container>
                <ng-container *ngIf="btn.type === 'menu' && childrenHasPerm(btn.children)">
                    <app-button-icon2-ui [iconClassNames]="btn.iconClassNames" [matMenuTriggerFor]="menu" [name]="btn.name" [value]="btn.value" [icon]="btn.icon" [classNames]="btn.classNames"></app-button-icon2-ui>
                    <mat-menu #menu="matMenu">
                        <div  class=" flex flex-col cursor-pointer gap-2">
                            <ng-container *ngFor="let child of btn.children">
                                <span  [ngClass]="child.classNames" (click)="trigger(child.value)" *hasPermissions="child.perms">
                                    <a class="text-semibold" style="color: black;">
                                        {{child.name}}
                                    </a>
                                </span>
                            </ng-container>
                        </div>
                    </mat-menu>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>