import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

declare let $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  public year = new Date().getFullYear()
  token: any;
  verifyTokenResponse: any = {};
  passwordResetRequest: any = {};
  bgImages = [
    '../../assets/images/system-images/login-background.jpg',
    '../../assets/images/system-images/market-woman.jpg',
    '../../assets/images/system-images/Market-metro.jpg',
    '../../assets/images/system-images/MarketWoman2.jpg',
    '../../assets/images/system-images/Tailor.jpg'
  ];
  random = Math.floor(Math.random() * Math.floor(this.bgImages.length));
  constructor(private activatedRoute: ActivatedRoute, private _auth: AuthService, private router: Router) { }

  ngOnInit() {
    //this.verifyTokenResponse = this.activatedRoute.snapshot.data['resetPassword'];
    this.verifyTokenResponse.code = 0;
  }

  setMyStyles() {
    let styles = {

      'position': 'absolute',
      'top': 0,
      'right': 0,
      'bottom': 0,
      'left': 0,
      'background-image': 'url(' + this.bgImages[this.random] + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover'
    };
    return styles;
  }

  resetPassword() {
    let that = this;
    this.passwordResetRequest.oldPassword = null;
      // if ($('#form-password-required').smkValidate()) {
      if (!this.passwordResetRequest.newPassword || this.passwordResetRequest.newPassword.trim().length < 6) {
    } else if (this.passwordResetRequest.newPassword !== this.passwordResetRequest.retypedPassword) {
    } else {
      //this.utils.showLoading();
    }
  }

  backToLogin() {
    window.location.href = 'login';
  }

}
