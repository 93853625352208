const fields = {
    "Name": ['businessDetailName','groupName', 'name'],
    "File": ['file'],
    "Firstname": ['firstName','nextOfKinFirstName'],
    "Lastname": ['lastName','nextOfKinLastName'],
    "Middlename":['middleName','nextOfKinMiddleName'],
    "Gender": ['gender','clientGender', 'nextOfKinGender'],
    "Date of Birth": ['dateOfBirth'],
    "Client": ['clientIds'],
    "Client Type": ['clientType'],
    "Group": ['groupId', 'clientGroupId', 'toGroupId', 'group'],
    "Group Role": ['clientGroupRole'],
    "Client Photo": ['clientPhoto'],
    "Phone Number": ['phoneNumber', 'nextOfKinPhoneNumber', 'mobileNumber'],
    "Email": ['email', 'nextOfKinEmail'],
    "State": ['stateId', 'state'],
    "Category": ['category'],
    "Parent Ledger Account": ['parentId'],
    "Account Type": ['type'],
    "Local Government Area": ['lgaId', 'localGovt'],
    "Town / City": ['townName', 'town'],
    "Occupation": ['occupation'],
    "Address": ['addressLine'],
    "Address 1": ['addressLine1'],
    "Address 2": ['addressLine2'],
    "ID Type": ['clientIdTypeId'],
    "ID Number": ['clientIdNumber'],
    "Photo": ['staffPhoto', 'passportPhoto', 'photo'],
    "Photo 1": ['clientBusinessPhoto1'],
    "Photo 2": ['clientBusinessPhoto2'],
    "Photo 3": ['clientBusinessPhoto3'],
    "Signature Photo": ['clientSignaturePhoto', 'signature'],
    "ID Card Photo": ['idCard'],
    "BVN": ['bvn'],
    "Relationship": ['relationshipType', 'nextOfKinRelationship', 'guarantorRelationship'],
    "Bank": ['clientBankId'],
    "Account Name": ['accountName'],
    "Account Number": ['accountNumber'],
    "Type": ['businessDetailTypeId'],
    "Start Date": ['businessDetailStartDate'],
    "CashFlow": ['businessDetailCashFlow'],
    "Business Photo": ['businessPhoto'],
    "Meeting Day": ['meetingDay'],
    "Branch": ['branchId', 'branch', 'toBranchId'],
    "Description": ['description'],
    "Meeting Time": ['meetingTime'],
    "Account Officer": ['loanOfficerId', 'loanOfficer', 'toLoanOfficerId'],
    "At the Branch": ['meetAtBranch'],
    "Employee ID": ['employeeId'],
    "Date Joined": ['joiningDate'],
    "Designation": ['designation'],
    "Staff": ['staff'],
    "Role": ['role'],
    "Old Role": ['oldRole'],
    "New Role": ['newRole'],
    "Is Account Officer": ['isLoanOfficer'],
    "Charges": ['chargeIds', 'charges'],
    "Interest Rate": ['interestRate', 'defaultInterestRate', 'interest'],
    "Interest Period Unit": ['interestPeriodUnit', 'interestRateUnit', 'interestPeriod'],
    "Interest Calculation Type": ['interestCalculationType', 'interestCalculation'],
    "Compunding Rate Period Unit": ['compoundingPeriodUnit', 'compoundingFrequency'],
    "Posting Rate Period Unit": ['interestPostingPeriodUnit', 'postingPeriod'],
    "Rounding Multiple": ['roundingMultiple'],
    "Minimum Opening Balance": ['minimumOpeningBalance', 'minimumBalance'],
    "Daily Transfer Limit": ['dailyTransferLimit'],
    "Accounting Rule": ['accountingType',],
    "Savings Reference": ['savingsReferenceIds', 'savingsReferenceGlAccountId', 'savingsReference'],
    "Income From Fee": ['feeIncomeAccountId'],
    "Income From Penalty": ['penaltyIncomeAccountId'],
    "Income From Interest": ['interestIncomeAccountId'],
    "Income From Recovery": ['recoveryIncomeAccountId'],
    "Savings Control": ['savingsControlAccountId'],
    "Written Off Account": ['writeOffAccountId'],
    "Interest on Savings": ['savingsInterestAccountId'],
    "Loan Tenor": ['defaultLoanPeriodLength', 'loanPeriodLength'],
    "Loan Period Unit": ['loanPeriodLengthUnit'],
    "Loan Product": ['loanProductId'],
    "Minimum Loan Tenor": ['minimumLoanPeriodLength'],
    "Maximum Loan Tenor": ['maximumLoanPeriodLength'],
    "Repayment Frequency": ['loanRepaymentFrequency'],
    "Repayment Frequency Period Unit": ['loanRepaymentFrequencyPeriodUnit'],
    "Minimum Interest Rate": ['minimumInterestRate'],
    "Maximum Interest Rate": ['maximumInterestRate'],
    "Amortization Type": ['amortizationType'],
    "Grace Period": ['gracePeriodLength'],
    "Compulsory Savings Type": ['compulsorySavingsType'],
    "Savings Product": ['savingsProductId'],
    "Compulsory Savings Amount": ['compulsorySavingsAmount'],
    "Prepayment Penalty Type": ['prepaymentPenaltyType'],
    "Prepayment Penalty Amount": ['prepaymentPenaltyAmount'],
    "Fund Source": ['fundSourceAccountsIds', 'fundSourceAccountId'],
    "Loan Portfolio": ['loanPortfolioAccountId'],
    "Interest Receivable": ['interestReceivableAccountId'],
    "Penalty Receivable": ['penaltyReceivableAccountId'],
    "Fees Receivable": ['feeReceivableAccountId'],
    "Losses Written Off": ['lossesWrittenOffAccountId'],
    "Interest Written Off": ['interestWrittenOffAccountId'],
    "Over Payment": ['overpaymentAccountId'],
    "Account": ['accountId'],
    "Amount": ['amount'],
    "Payment Method": ['paymentMethod'],
    "Transaction Type": ['transactionType'],
    "New Password": ['newPassword'],
    "Confirm New Password": [ 'confirmNewPassword'],
    "Principal": ['principal', 'defaultPrincipal'],
    "Maximum Principal": ['maximumPrincipal'],
    "Minimum Principal": ['minimumPrincipal'],
    "Minimum Balance": ['openingBalance'],
    "Transfer Limit": ['transferLimit'],
    "Income From Fees": ['feeIncomeAccountId'],
    "Income From Penalties": ['penaltyIncomeAccountId'],
    "Interest On Savings": ['savingsInterestAccountId'],
    "Written-Off Account": ['writeOffAccountId'],
    "Remark": ['remark'],
    "Code": ['code'],
    "Logo": ['logo'],
    "Favicon": ['website_tab_icon'],
}

function evalFields(){
    const evaluatedFields = {}
    Object.keys(fields).forEach((curr:any)=>{
        fields[curr].map(key=>{
            evaluatedFields[key] = curr
        })
    })
    return evaluatedFields;
}

export const Fields = evalFields()