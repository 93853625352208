import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DummyDataService {
  public dummyPage = {
    firstPage: '1',
    lastPage: '32',
    nextPage: '',
    pageNumber: 2,
    pageSize: 10,
    previousPage: '',
    totalPages: 0,
    totalRecords: 320,
  };

  public entryClosureDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    closure_date: `${new Date().getTime() + index}`,
    created_by: `Graham Potter`,
    created_date: `${new Date().getTime() + index}`,
    remark: `Remark - ${index}`,
    approval_state: index % 2 ? 'PENDING' : 'APPROVED',
  }))
  public reportsDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Report Name - ${index}`,
    description: `Report Description - ${index}`,
  }))

  public franchiseesDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    alias: `Alias - ${index}`,
    account_manager: `Manager - ${index}`,
    contact_name: `Contact Name - ${index}`,
    contact_phone: `Phone - ${index}`,
    contact_email: `Email - ${index}`,
    created_date: `2022-05-21T18:29:44.574`,
  }));

  public updatesDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index + 1,
    title: `Title - ${index}`,
    description: `Description - ${index}`,
    deployment_date: `2022-05-21T18:29:44.574`,
  }));

  public rolesDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index + 1,
    name: `SUPERADMIN - ${index}`,
    description: `Is A super admin - ${index}`,
  }));

  public areasDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    description: `Description - ${index}`,
  }));

  public groupPostingDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    description: `Description - ${index}`,
    created_by: `John Doe`,
    date_created: `2022-05-21T18:29:44.574`,
    posting_date: `2022-05-21T18:29:44.574`,
    total_amount: `12000`,
  }));

  public repaymentApprovalDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    loan_number: `${index + 1}`,
    name: `John Doe`,
    loan_product: `Easy Access`,
    amount_repayed: `1000`,
    transaction_type: `REPAYMENT`,
    loan_officer: `John Doe`,
    repayment_method: `CASH`,
    payment_date: `2022-05-21T18:29:44.574`,
    submitted_by: `John Doe`,
    last_updated_time: `2022-05-21T18:29:44.574`,
  }));



  public depositsDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    account_number: `0012343423`,
    branch: `Agric - ${index}`,
    product: `Group Savings`,
    balance: `12000`,
    account_state: `NORMAL`,
    last_updated: `200`,
  }))

  public depositChargeDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    type: `Type - ${index}`,
    rate: `Rate - ${index}`,
    value: `200`,
  }))

  public depositTransactionsDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index,
    ID: `ID - ${index}`,
    transaction_date: `2022-05-21T18:29:44.574`,
    submitted_on: `2022-05-21T18:29:44.574`,
    type: `Deposit`,
    credit: `200`,
    debit: `200`,
    balance: `200`,
    narration: `Balancing up`,
  }))

  public branchesDummyTableData =  new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Branch - ${index}`,
    area: `Area - ${index}`,
    email: `Email - ${index}`,
    phone_number: `8149562672`,
    date_created: `12-05-2022`,
  }));

  public loanProductsDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Branch - ${index}`,
    code: `CSML`,
    principal: `500500`,
    duration: `4 Months`,
    interest_rate: `2 Percent / Month`,
    amortization_type: `FLAT`,
  }));

  public depositProductsDummyTableData = new Array(10)
    .fill(0)
    .map((_, index) => ({
      id: index,
      name: `Branch - ${index}`,
      code: `CSML`,
      interest_rate: `2 Percent / Year`,
    }));

  public chargesDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    charge_type: `Charge Type - ${index}`,
    percentage: 100,
    amount: `50000`,
  }));

  public holidaysDummyTableData = new Array(10).fill(0).map((_, index) => ({
    id: index,
    name: `Name - ${index}`,
    branch: `Branch - ${index}`,
    group: `Group - ${index}`,
    start_date: '2022-05-21T18:29:44.574',
    end_date: '2022-05-21T18:29:44.574',
  }));

  public dummyTableData = new Array(100).fill(0).map((_, index) => ({
    id: index + 1,
    clientId: null,
    firstname: `Edward ${index}`,
    middleName: `Alice ${index}`,
    lastname: `King ${index}`,
    registrationDate: '21-05-2022',
    phoneNumber: '8149562672',
    email: '',
    username: '',
    designation: '',
    branch: {
      id: `${Math.floor(Math.random() * index)}`,
      name: `${index} Ag-Ogijo`,
      extra: null,
    },
    group: {
      id: `${Math.floor(Math.random() * index)}`,
      name: `Imoleayo ${index}`,
      extra: null,
    },
    gender: index % 2 ? 'FEMALE' : 'MALE',
    photo: 'c2ddf0c5-cacd-4f74-90fb-c7dad417052d20220521182944.jpg',
    businessDetail: {
      name: `Cooked Food ${index}`,
      businessType: `Trading ${index}`,
    },
    address: `London, Park Lane no. ${index}`,
    createdDate: null,
    state: index % 2 ? 'PENDING' : 'APPROVED',
    statusReason: null,
    role: 'App administrator',
    loanOfficer: 'John Doe',
    clientName: 'Phillip',
    accountNumber: 'L0000000142124',
    loanProduct: 'Easy Access',
    principal: 1000,
    interest: 500,
    charge: 100,
    total: 2000,
    outstanding: 200,
    amountPaid: 1500,
    loanState: 'Running',
    occupation: 'Software Developer',
    mobileNumber: '08111000111',
    
    startDate: '2022-05-21T18:29:44.574',
    maturityDate: '2022-05-21T18:29:44.574',
    lastLogin: '2022-05-21T18:29:44.574',
    lastUpdateTime: '2022-05-21T18:29:44.574',
  }));
  public dummyClientTransfer = new Array(100).fill(0).map((_, index) => ({
    id: index + 1,
    firstname: `Edward ${index}`,
    middleName: `Alice ${index}`,
    lastname: `King ${index}`,
    fromBranch: 'Ketu',
    toBranch: 'Mushin',
    fromGroup: 'Stella Corporatives',
    toGroup: 'Funsho Corporatives',
    fromOfficer: 'Stephen',
    toOfficer: 'Derek',
    treatedBy: 'Folarin',
    treatedOn: new Date(),
    remark: 'No remark',
    dateCreated: new Date(),
  }));
  public dummyGroupTransfer = new Array(100).fill(0).map((_, index) => ({
    id: index + 1,
    group: `Edward ${index}`,
    fromBranch: 'Ketu',
    toBranch: 'Mushin',
    fromGroup: 'Stella Corporatives',
    toGroup: 'Funsho Corporatives',
    fromOfficer: 'Stephen',
    toOfficer: 'Derek',
    treatedBy: 'Folarin',
    treatedOn: new Date(),
    remark: 'No remark',
    dateCreated: new Date(),
  }));
  public dummyLoanOfficersTransfer = new Array(100).fill(0).map((_, index) => ({
    id: index + 1,
    name: `Edward ${index}`,
    fromBranch: 'Ketu',
    toBranch: 'Mushin',
    treatedBy: 'Folarin',
    treatedOn: new Date(),
    remark: 'No remark',
    dateCreated: new Date(),
  }));

  public dummyClientLoanData = new Array(50).fill(0).map((_, index) => ({
    id: index,
    accountNumber: 'L0000000230938',
    branch: 'Ile- Epo',
    clientName: 'Rita  Onuoha',
    principal: 20000,
    interest: 1900,
    charge: 600,
    principalPaid: 0,
    interestPaid: 1900,
    chargePaid: 600,
    balance: 20000,
    overdue: 0,
    overdueSince: null,
    oldAccountNumber: null,
    loanProductName: 'QUICK ACCESS LOAN',
    loanAccountId: 230938,
    state: 'APPROVED',
    accountState: 'NORMAL',
    loanState: 'RUNNING',
    outstanding: 20000,
    lastUpdateTime: '2021-09-24T17:17:22.116',
    startDate: '2021-09-24',
    maturityDate: '2021-10-25',
  }));

  public dummyUserClientsData = new Array(10).fill(0).map((_, index) => ({
    approvalState: 'APPROVED',
    clientId: '0009-0300-55316',
    firstName: 'Abimbola',
    lastName: 'Amajie',
    phoneNumber: '08143860749',
    branch: 'ketu',
    id: 55316 + index,
    role: 'MEMBER',
    registrationDate: '2021-09-24T17:17:22.116'
  }));
  public dummyUserLoansData = new Array(10).fill(0).map((_, index) => ({
    clientId: '0009-0300-55316',
    clientName: 'Abimbola Cynthia Amajie',
    accountNumber: '022346532',
    oldAccountNumber: '022346532',
    loanProductName: 'QUICK ACCESS LOAN',
    outstanding: 20000,
    principal: 30000,
    interest: 2000,
    charge: 200,
    total: 40000,
    amountPaid: 10000,
    state: 'APPROVED',
    overdue: '2021-09-24T17:17:22.116',
    overdueSince: '2021-09-24T17:17:22.116'
  }));
  public dummyUserSavingsData = new Array(10).fill(0).map((_, index) => ({
    clientId: '0009-0300-55316',
    accountName: 'Group 22',
    accountNumber: '022346532',
    oldAccountNumber: '022346532',
    savingProductName: 'QUICK ACCESS LOAN',
    outstanding: 20000,
    state: 'APPROVED',
    approvalState: 'APPROVED',
    clientName: 'Abimbola Cynthia Amajie',
    balance: 20000
  }));

  public dummyClientDepositData = new Array(50).fill(0).map((_, index) => ({
    id: index,
    accountNumber: '30000230938',
    product: 'CASH DROP',
    balance: 3000,
    status: index % 2 ? 'APPROVED' : 'REJECTED',
  }));
  public dummyClientIdentificationsData = new Array(50)
    .fill(0)
    .map((_, index) => ({
      id: index,
      idType: 'Voters Card',
      idNumber: 20045543,
      description: 'Business Id Card',
    }));
  public dummyClientDocumentsData = new Array(50).fill(0).map((_, index) => ({
    id: index,
    name: `Client Business Photo ${index + 1}`,
    description: 'Guarantor Shop rent receipt',
  }));
  public dummyClientCommentsData = new Array(50).fill(0).map((_, index) => ({
    id: index,
    text: `Random Text ${index + 1}`,
    addedBy: 'Super Admin',
    dateAdded: '2022-06-12',
  }));
  public dummyGroupClientsData = new Array(10).fill(0).map((_, index) => ({
    approvalState: 'APPROVED',
    clientId: '0009-0300-55316',
    fullName: 'Abimbola Cynthia Amajie',
    id: 55316 + index,
    role: 'MEMBER',
  }));
  public dummyGroupLoansData = new Array(10).fill(0).map((_, index) => ({
    clientId: '0009-0300-55316',
    accountName: 'Group 22',
    accountNumber: '022346532',
    oldAccountNumber: '022346532',
    loanProductName: 'QUICK ACCESS LOAN',
    outstanding: 20000,
    state: 'APPROVED',
  }));
  public dummyKYCData = new Array(10).fill(0).map((_, index) => ({
    id: 55497 + index,
    approvalState: 'APPROVED',
    date: '2022-02-25T13:06:48.066',
    bvnImage:
      'https://aquila-api.wizertestserver.com/api/v2/internal/general/web-client/open/files/photo/a3651d1e-2c06-47aa-90fe-c66de8a6d8ab20211126173726.jpg',
    selfie:
      'https://aquila-api.wizertestserver.com/api/v2/internal/general/web-client/open/files/photo/5883b769-d9e5-425c-b238-e19191ac691520220225130624selfie01.png',
    clientIdentification: {
      number: '988888888',
      description: "Clients's Passport document",
      url: 'https://aquila-api.wizertestserver.com/api/v2/internal/general/web-client/open/files/photo/13aaa552-9c9b-4ee3-8718-a3adf8d3cc7a20211126172326idPicture01.png.png',
      idType: 'Passport',
    },
    utilityBill:
      'https://aquila-api.wizertestserver.com/api/v2/internal/general/web-client/open/files/photo/a2e16266-6406-4d5f-850b-7a424acd626520211126172326p.png.png',
    bvnPhoneNumber: '08143860749',
    bvnEmail: 'mosinmiloluwa.o@gmail.com',
    bvnFirstName: 'Jubril',
    bvnMiddleName: 'Ann',
    bvnLastName: 'Jubril',
  }));
  public dummyGroupDocumentsData = new Array(10).fill(0).map((_, index) => ({
    name: '0009-0300-55316',
    addedBy: 'Super Admin',
    addedOn: new Date(),
  }));
  public dummyGroupSavingsData = new Array(10).fill(0).map((_, index) => ({
    clientId: '0009-0300-55316',
    accountName: 'Group 22',
    accountNumber: '022346532',
    oldAccountNumber: '022346532',
    savingProductName: 'QUICK ACCESS LOAN',
    outstanding: 20000,
    state: 'APPROVED',
  }));
  public dummyClientGroupsData = new Array(50).fill(0).map((_, index) => ({
    id: index + 1,
    name: 'Ken',
    overdue: 0,
    performance: 0,
    branch: {
      id: 1,
      name: 'Head Office',
      extra: null,
    },
    numberOfmembers: 8,
    meetingDay: 'SUNDAY',
    meetingLocation: 'Lien 2, Iunne',
    loanOfficer: {
      id: 135,
      name: 'Nurudeen  Aderoju',
      extra: null,
    },
    groupLeader: {
      id: 53,
      name: 'Musumina Adesua',
    },
    approvalState: null,
    numberOfMembers: 3,
    lastUpdateTime: '2022-06-15T11:26:10.56',
  }));
}
