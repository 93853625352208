import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TitleActionBtnType } from '../../models/common';

@Component({
  selector: 'app-nav-child-ui',
  templateUrl: './nav-child-ui.component.html',
  styleUrls: ['./nav-child-ui.component.css']
})
export class NavChildUiComponent implements OnInit {

  @Input() parentPath: string = 'clients';
  @Input() currentUrl: string = '';
  @Input() navButtons: TitleActionBtnType[] = [];

  constructor(
    private router: Router,
    ) { }

  ngOnInit(): void {
    const index = this.navButtons.findIndex(object => {
      if(object.value === '') return `/${this.parentPath}/${object.value}` === `${this.router.url.split('?')[0]}/`;
      return `/${this.parentPath}/${object.value}/` === `${this.router.url.split('?')[0]}/`;
    });
    this.activateBtn(index);
  }

  public activateBtn(index) {
    this.navButtons.forEach((element, i) => {
      if(i === index) {
        element.active = true;
      }else {
        element.active = false;
      }
    });
    // this.navButtons[index].active = true;
  }

  public isActive(btn: any): boolean {
    const urlArr = this.router.url.split('/')
    let url = urlArr[urlArr.length - 1]
    const navUrl = btn.value
    if(url.includes('?')) url = url.split('?')[0]
   
    return (url === navUrl)
  }

  public trigger(btn:any){
    this.router.navigate([`${this.parentPath}/${btn.value}`]);
  }

}
