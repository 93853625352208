import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { IndexedDBService } from '../indexDB/indexedDB.service';



@NgModule({
  declarations: [
    HeaderComponent,
    MainComponent,
    SidebarComponent,
    FooterComponent,
  ],
  imports: [CommonModule, AppRoutingModule, SharedModule, NzDropDownModule, NzBadgeModule],
  exports: [MainComponent],
})
export class LayoutModule {}
