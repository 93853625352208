export default function findValFromObj(object: any, key?: string) {
  if (!object || typeof object !== 'object') return '';
  if (!key) return '';
  let value;
  Object.keys(object).some((k) => {
    if (k === key) {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object') {
      value = findValFromObj(object[k], key);
      return value !== undefined;
    }
    return false;
  });
  return value;
}
