import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/utils/permission.service';
import { ActionButtonType } from '../../models/common';

@Component({
  selector: 'app-action-bar-ui',
  templateUrl: './action-bar-ui.component.html',
  styleUrls: ['./action-bar-ui.component.css'],
})
export class ActionBarUiComponent {
  @Input() actionButtons: ActionButtonType[] = [];
  @Input() showActionButtons: boolean = false;
  @Input() disableActionButtons: boolean = true;
  @Output() actionEmit: EventEmitter<ActionButtonType['value']> =
    new EventEmitter();
    
  constructor(private permissionsService: PermissionService) {}


  public evalClass(val: ActionButtonType['value']) {
    if (val === 'reject' || val === 'disable' || val === 'decline' || val === 'cancel') {
      return 'bg-error';
    }
    if(val === 'modify2'){
      return 'bg-primary'
    }
    return 'bg-success';
  }

  public actionToggled(val: ActionButtonType['value']) {
    this.actionEmit.emit(val);
  }
}
