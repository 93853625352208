import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/utils/permission.service';
import {
  InitDisplayedCol,
  Page,
  PageCtrl,
  StateTypes,
} from '../../models/common';

@Component({
  selector: 'app-table-ui',
  templateUrl: './table-ui.component.html',
  styleUrls: ['./table-ui.component.css'],
})
export class TableUiComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @Input() set data(payload: any) {
    let data = payload;
    if (payload) {
      data = this.preCheckSelectedIds(payload);
    }
    this.dataSource = new MatTableDataSource(Array.isArray(payload)?payload:null);
  }
  @Input() tableWrapperClass: string;
  @Input() initColumns: InitDisplayedCol[];
  @Input() selectedIds: Set<number> = new Set();
  @Input() selectedDatas: any[] = [];

  @Input() pageProp: Page;
  @Input() showPagination: boolean;
  @Input() isPostingModule: boolean = false;

  @Input() usePermission: boolean = false;
  @Input() showFooter: boolean = false;

  @Input() matSortActive: string = '';
  @Input() matSortDirection: SortDirection = '';
  @Input() showCheckboxes: boolean = true;
  @Input() activeState: StateTypes['value'];

  @Output() checkboxCheckEmit: EventEmitter<any> = new EventEmitter();
  @Output() checkboxCheckEmitData: EventEmitter<any> = new EventEmitter();
  @Output() pageSizeToggle: EventEmitter<number> = new EventEmitter();
  @Output() PageCtrlToggle: EventEmitter<PageCtrl> = new EventEmitter();
  @Output() sortEmit: EventEmitter<any> = new EventEmitter();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() actionBtnTriggered: EventEmitter<any> = new EventEmitter();

  @ViewChildren('checkBoxes') checkboxes: QueryList<ElementRef>;

  public displayedColumns: string[];
  public dataSource: MatTableDataSource<any>;
  public masterCheckboxChecked: boolean;

  minDate: Date;
  maxDate: Date;

  constructor(private permissionsService: PermissionService) {
    const currentYear = new Date().getFullYear();
    const currentTime = new Date();
    this.minDate = new Date(currentYear - 6, 0, 1);
    this.maxDate = new Date(currentTime);
  }

  ngOnInit(): void {

    this.displayedColumns = this.initColumns.map((col) => col.key);
  }

  public customTrackBy(index) {
    return index;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.displayedColumns = this.initColumns.map((col) => col.key);

    if (!this.selectedIds.size) {
      setTimeout(() => {
        this.masterCheckboxChecked = false;
      });
    }

    if (changes['activeState']) {
      this.activeState = changes['activeState'].currentValue;
    }
    // if(changes['pageProp']) this.pageProp = changes['pageProp'].currentValue
  }

  ngAfterViewInit() {
    if (this.dataSource) this.dataSource.sort = this.sort;
  }

  private preCheckSelectedIds(data: any[]) {
    if (!data || !data.length) return;
    return data.map((d) => {
      d.checked = this.selectedIds.has(d.id);
      return d;
    });
  }

  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }
  public showToolTip(val, maxLen) {
    const len = maxLen ?? 50;
    if (val.length <= len) {
      return val;
    }
    return '';
  }

  public announceSortChange(sortState: any) {
    this.sortEmit.emit(sortState);
  }

  public onPageSizeToggle(val: number) {
    this.pageSizeToggle.emit(val);
  }
  public onPageCtrlToggle(val: PageCtrl) {
    this.PageCtrlToggle.emit(val);
  }

  public onCheck(event: MatCheckboxChange, item: any) {
    if (this.masterCheckboxChecked) this.masterCheckboxChecked = false;
    const elemCount = this.dataSource.data.length;
    this.dataSource.data.map((d: any) => {
      if (d.id) {
        if (d.id === item.id) d.checked = event.checked;
        const index = this.selectedDatas.findIndex((data) => data.id === d.id);
        if (d.checked) {
          if (index < 0) this.selectedDatas.push(d);
          this.selectedIds.add(d.id);
        } else {
          this.selectedIds.delete(d.id);
          if (index >= 0) this.selectedDatas.splice(index, 1);
        }

        return;
      }

      if (d.accountId) {
        if (d.accountId === item.accountId) d.checked = event.checked;
        if (d.checked) {
          this.selectedIds.add(d.accountId);
        } else {
          this.selectedIds.delete(d.accountId);
        }
        return;
      }
    });
    if (this.selectedIds.size === elemCount) this.masterCheckboxChecked = true;
    this.checkboxCheckEmit.emit(this.selectedIds);
    this.checkboxCheckEmitData.emit(this.selectedDatas);
  }

  public onCheckAll(event: MatCheckboxChange) {
    this.masterCheckboxChecked = event.checked;

    // if (this.isPostingModule && this.activeState !== 'pending') {
      this.dataSource.data.forEach((data: any) => {
        if (!data.disableCheckbox) data.checked = event.checked;
      });

      if (event.checked) {
        this.selectedDatas = this.dataSource.data.filter(
          (data) => !data.disableCheckbox
        );

        this.selectedIds = new Set(
          this.dataSource.data
            .filter((data) => !data.disableCheckbox)
            .map((val) => val.id || val.accountId)
        );

        this.checkboxCheckEmit.emit(this.selectedIds);
        this.checkboxCheckEmitData.emit(this.dataSource.data);
      } else {
        
        this.selectedIds = new Set();
        this.selectedDatas = [];
        this.checkboxCheckEmit.emit(new Set());
        this.checkboxCheckEmitData.emit([]);
      }
    // }
    // // NOT POSTING MODULE
    // else {
    //   this.dataSource.data.forEach((data: any) => {
    //     data.checked = event.checked;
    //   });

    //   if (event.checked) {
    //     this.selectedDatas = this.dataSource.data;
    //     this.selectedIds = new Set(
    //       this.dataSource.data.map((val) => val.id || val.accountId)
    //     );

    //     this.checkboxCheckEmit.emit(this.selectedIds);
    //     this.checkboxCheckEmitData.emit(this.dataSource.data);
    //   } else {
    //     this.selectedIds = new Set();
    //     this.selectedDatas = [];
    //     this.checkboxCheckEmit.emit(new Set());
    //     this.checkboxCheckEmitData.emit([]);
    //   }
    // }

  }

  public unCheckAll() {
    this.masterCheckboxChecked = false;
    this.data.forEach((element: any) => {
      element.checked = false;
    });
    this.selectedDatas = [];
  }

  public triggerRowClick(rowId: number) {
    this.rowClick.emit(rowId);
  }
}
