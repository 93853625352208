<section style="overflow: auto;" *ngIf="url !== '/reset-password' && url !== '/login'">

    <app-main></app-main>
</section>

<!-- modal change password -->
<app-modal-change-password u="u" (logoutEmit)="logout()"></app-modal-change-password>
<!--/ modal change password -->

<!-- loading overlay -->
<div *ngIf="url === '/reset-password' || url === '/login'">

    <app-loading-overlay [showLoader]="loading" [isConfiguring]="isConfiguring"></app-loading-overlay>
</div>
<!--/ loading overlay -->

<!-- Configuration Modal -->
<!-- check is configuring if its meant to trigger loading overlay -->
<!-- <app-configuration-modal [isConfiguring]="isConfiguring"></app-configuration-modal> -->

<!--/ Configuration Modal -->

<!-- Terms and Condition Modal -->
<app-terms-and-condition-modal (redirectToDashboardEmit)="redirectToDashboard()"></app-terms-and-condition-modal>
<!--/  Terms and Condition Modal -->

<!-- components/pages which do not require token -->
<!-- login, resetpassword, changepassword, ... -->
<div *ngIf="url === '/reset-password' || url === '/login'">
    <router-outlet></router-outlet>
</div>
<!--/ components/pages which do not require token -->


<!-- new update modal-->
<app-new-update-modal></app-new-update-modal>
<!--/ new update modal-->


<!-- notification modal -->
<app-notification-modal *ngIf="modalNotifications" [modalNotifications]="modalNotifications"></app-notification-modal>
