import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CanvasDrawComponent } from '../canvas-draw/canvas-draw.component';
import { InputModalComponent } from '../input-modal/input-modal.component';
import jspdf from "jspdf";
import  html2canvas from "html2canvas";
import { SignatureOpts } from '../../dto/constants';
import { MoneyFormatInNaira } from 'src/app/utils/wizer.pipes';
import { LoanService } from 'src/app/pages/loan-management/services/loan.service';


@Component({
  selector: 'app-preview-signature',
  templateUrl: './preview-signature.component.html',
  styleUrls: ['./preview-signature.component.css'],
  providers: [MoneyFormatInNaira]
})
export class PreviewSignatureComponent implements AfterViewInit {
  @ViewChild('parser') parser: ElementRef<HTMLDivElement>;

  private htmlContent = '';
  private signatoryOpts = SignatureOpts.map((val) => val.value);
  public mappedFields = {};
  public showSave = true

  constructor(
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private loanService: LoanService,
    private currencyPipe: MoneyFormatInNaira,
    private dialogRef: MatDialogRef<PreviewSignatureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if(this.data.preview){
      this.showSave = false
    }
    if(!this.data?.mappedFields) return;
    this.mappedFields = this.data.mappedFields;
    this.signatoryOpts = this.signatoryOpts.concat(Object.keys(this.mappedFields))
  }

  private hideActionElements(){
    this.parser.nativeElement.setAttribute('style','border-bottom: none; height:100%')
    document.querySelectorAll('#signature').forEach((val) => {
      val.setAttribute('style', 'display:none');
    });
    document.querySelectorAll('#input').forEach((val) => {
      val.setAttribute('style', 'display:none');
    });
  }
  private showActionElements(){
    this.parser.nativeElement.setAttribute('style','border-bottom: 1px solid rgba(0, 0, 0, 0.108); height:70vh')

    document.querySelectorAll('#signature').forEach((val) => {
      val.setAttribute('style', 'display:content');
    });
    document.querySelectorAll('#input').forEach((val) => {
      val.setAttribute('style', 'display:content');
    });
  }

  // initialize and parse html string
  // set click event on special signatories
  public init() {
    if(!this.data || !this.data.htmlContent) return
    this.htmlContent = this.data.htmlContent;
    if(!this.data.process){
      this.parser.nativeElement.innerHTML = this.htmlContent;
      this.hideActionElements()
      return
    }
    const val = this.transform(this.htmlContent);
    this.parser.nativeElement.innerHTML = val;
    document.querySelectorAll('#signature').forEach((val) => {
      val.addEventListener('click', (e: any) => {
        this.sign(e.target.dataset);
      });
    });
    document.querySelectorAll('#input').forEach((val) => {
      val.addEventListener('click', (e: any) => {
        this.input(e.target.dataset);
      });
    });
  }

  // create signature and append to image reference
  public sign(type) {
    const dialogRef = this.dialog.open(CanvasDrawComponent);
    dialogRef.afterClosed().subscribe((dataUrl) => {
      if (!dataUrl) return;
      const imgRef = document.querySelector(`img[data-ref="${type.ref}"]`);
      imgRef.setAttribute('src', dataUrl);
      imgRef.setAttribute('style', 'height: 20px; width: 40px;');
    });
  }
  // input values to bold tag references
  public input(type) {
    const dialogRef = this.dialog.open(InputModalComponent, {panelClass: 'entry-dialog', width: '450px'});
    dialogRef.afterClosed().subscribe((val) => {
      if (!val) return;
      const inputRef = document.querySelector(`b[data-ref="${type.ref}"]`);
      inputRef.innerHTML = val.name;
    });
  }

  public transform(val: string) {
    let returnVal = '';
    let onCopyPlaceholder = false;
    let placeholder = '';
    for (let i = 0, len = val.length; i < len; i++) {
      if (val[i] === '{') {
        onCopyPlaceholder = true;
        placeholder = '';
        continue;
      }
      if (val[i] === '}') {
        onCopyPlaceholder = false;
        returnVal += this.processPlaceholder(placeholder);
        continue;
      }
      if (onCopyPlaceholder) {
        placeholder += val[i];
        continue;
      }

      returnVal += val[i];
    }
    return returnVal;
  }

  private processPlaceholder(value: string) {
    const formattedSig = this.formatSignatureAndInput(value);
    if (formattedSig) return formattedSig;
    const formattedPlaceholder = this.formatPlaceholder(value);
    if(formattedPlaceholder) return formattedPlaceholder
    return value;
  }

  public formatPlaceholder(value: string){
    if(value === 'today'){
      return this.datePipe.transform(Date.now(), 'dd-MM-yyyy')
    }
    if(value === 'charges'){
      return this.currencyPipe.transform(this.mappedFields[value])

    }
    if(value === 'principal'){
      return this.currencyPipe.transform(this.mappedFields[value])

    }
    if(value === 'interest'){
      return `<span style='text-transform:lowercase'>${this.mappedFields[value]}% per ${this.mappedFields['interestUnit'] || 'month'}</span>`

    }
    if(value === 'clientAddress'){
      if(typeof this.mappedFields['clientAddress'] === 'string') return this.mappedFields['clientAddress'];
      const address = this.mappedFields['clientAddress']?.addressLine1;
      const townName = this.mappedFields['clientAddress']?.townName
      const lga = this.mappedFields['clientAddress']?.lga?.name
      const state = this.mappedFields['clientAddress'].lga?.state?.name
      return `<span style='text-transform:lowercase'>${address} ${townName}, ${lga}, ${state}</span>`

    }
    return `<span style='text-transform:capitalize'>${this.mappedFields[value] || ''}</span>`
  }

  public formatSignatureAndInput(value: string) {
    const placeholderSplit = value.split('|');
    // check if its a signatory
    if(!placeholderSplit[0].match(/(\w+):(.*)/)) return undefined;
    const evaluated = placeholderSplit.reduce(
      (acc: string[], placeholder: string) => {
        const isMatch = placeholder.match(/(\w+):(.*)/);

        if (!isMatch || !this.signatoryOpts.includes(isMatch[1])) {
          acc.push(placeholder);
          return acc;
        }
        acc.push(this.formatSignatory(isMatch[1], isMatch[2]));
        return acc;
      },
      []
    );
    return `
    <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 10px;
        "
      >
    ${evaluated.join('')}
    </div>`;
  }

  private formatSignatory(type: string, value: string) {
    if (type === 'signature') {
      const [id, label] = value.split(',');
      return `<div style="width: 180px; text-align: center">
      <p>${label}</p>
          <img
            data-ref='${id}'
            style=""
          />
          <hr
            style="
              border: none;
              border-top: 1px dotted rgba(0, 0, 0, 0.348);
              color: #fff;
              background-color: #fff;
              height: 1px;
              width: 100%;
              margin: 5px;
            "
          />
          <a
          id='signature'
          class="red italic"
          data-ref='${id}'
            style="margin: 0; margin-top: 10px; opacity: 0.6"
            >Click to sign</a
          >
        </div>`;
    }
    if (type === 'input') {
      const [id, label] = value.split(',');
      return `<div style="width:180px; text-align: center">
      <p>${label}</p>
      <b data-ref='${id}' ></b>
          <hr
            style="
              border: none;
              border-top: 1px dotted rgba(0, 0, 0, 0.348);
              color: #fff;
              background-color: #fff;
              height: 1px;
              width: 100%;
              margin: 5px;
            "
          />
          <a
          id='input'
          class="red italic"
          data-ref='${id}'
            style="margin: 0; margin-top: 10px; opacity: 0.6"
            >Append Name</a
          >
        </div>`;
    }
    if (this.mappedFields[type]) {
      const [id, label] = value.split(',');
      return `<div style="width: 180px; text-align: center">
      <p>${label}</p>
          <b style="padding:10px 0; text-transform:capitalize" >${this.mappedFields[type]}</b>
          <hr
            style="
              border: none;
              border-top: 1px dotted rgba(0, 0, 0, 0.348);
              color: #fff;
              background-color: #fff;
              height: 1px;
              width: 100%;
              margin: 5px;
            "
          />
          
        </div>`;
    }
    return value;
  }


  public downloadAsPDF() {
    var data = this.parser.nativeElement; //Id of the table
    data.style.height = '100%';
    this.hideActionElements()
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      let imgWidth = 208;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL("image/png");

      let pdf = new jspdf("p", "mm", "a4", true); // A4 size page of PDF
      let position = 0;
      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(`DOG$.pdf`.split(" ").join(""));
    });
    data.style.height = '70vh';
    this.showActionElements()
  }


  saveDocument(){
    if(this.data.module.name === 'loan'){

      this.loanService.saveContract(this.data.module.id, this.parser.nativeElement.innerHTML, this.data.module.ref).subscribe(res=>{

      })
    }
  }


  ngAfterViewInit(): void {
    this.init();
  }
}
