import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  baseCoreUrl: string = environment.baseUrl + environment.coreUrl;
  baseUrl: string = environment.baseUrl

  public searchBranchEndpoint = `${this.baseCoreUrl}branch?page=0&pageSize=500&state=APPROVED&sort-order=DESC&filter=`
  public searchLoanOfficerByBranchEndpoint = (id)=>`${this.baseCoreUrl}users?is-loan-officer=true&branch-id=${id}&page=0&pageSize=100&state=ALL&sort-field=lastUpdateTime&sort-order=DESC&filter=`
  public searchApprovedLoanOfficerByBranchEndpoint = (id)=>`${this.baseCoreUrl}users?is-loan-officer=true&branch-id=${id}&page=0&pageSize=100&state=APPROVED&sort-field=lastUpdateTime&sort-order=DESC&filter=`
  public searchGroupByBranchEndpoint = (id)=>`${this.baseCoreUrl}branch/groups/${id}?page=0&pageSize=500&state=APPROVED&sort-field=last_update_time&sort-order=DESC&filter=`

  constructor(private http: HttpClient) { }

  createBranch(data) {
    return this.http.post<any>(this.baseCoreUrl + 'branch', data);
  }

  updateBranch(data) {
    return this.http.put<any>(this.baseCoreUrl + 'branch', data);
  }

  getBranches(params: string) {
    return this.http.get(`${this.baseCoreUrl}branch?${params}`);
  }

  getBranch(branchId: number) {
    return this.http.get(`${this.baseCoreUrl}branch/${branchId}`);
  }

  public getLoanOfficersByBranch(id:number):Observable<any> {
    return this.http.get(`${this.baseCoreUrl}branch/loanOfficers/${id}?page=0&pageSize=100&state=APPROVED&sort-field=last_update_time&sort-order=DESC&filter=`);
  }

  public searchGroupByBranch(id:number):Observable<any> {
    return this.http.get(this.searchGroupByBranchEndpoint(id));
  }
  public getGroupByBranch(id:number, pageRequest):Observable<any> {
    const query = new URLSearchParams(pageRequest).toString()
    return this.http.get(`${this.baseCoreUrl}branch/groups/${id}?${query}`);
  }

  public toggleBranches(data:any): Observable<any>{
    return this.http.post<any>(this.baseCoreUrl + 'branch/toggle', data);
  }

  public rejectBranch(data:any): Observable<any>{
    return this.http.post<any>(this.baseCoreUrl + 'branch/reject', data);
  }

  // Methods for storing branch list

  // private localStorageKey = 'branchesData';

  // setBranchesData(data: { id: number, name: string }[]): void {
  //   localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  // }

  // getBranchesData(): { id: number, name: string }[] {
  //   const storedData = localStorage.getItem(this.localStorageKey);
  //   return storedData ? JSON.parse(storedData) : [];
  // }
}
