import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-repayment-schedule',
  templateUrl: './account-repayment-schedule.component.html',
  styleUrls: ['./account-repayment-schedule.component.css']
})
export class AccountRepaymentScheduleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
