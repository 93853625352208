import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chart-paginator',
  templateUrl: './chart-paginator.component.html',
  styleUrls: ['./chart-paginator.component.css']
})
export class ChartPaginatorComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number;
  @Input() dynamicBottom: string = '-2%';
  @Output() updateActionEmit: EventEmitter<any> = new EventEmitter()
  
  constructor() { }


  goToPreviousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updateActionEmit.emit(this.currentPage);
    }
  }

  goToNextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updateActionEmit.emit(this.currentPage);
    }
  }

}
