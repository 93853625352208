import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'src/app/shared/charts/charts.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserActivityClientOccupationComponent } from './components/user-activity-client-occupation/user-activity-client-occupation.component';
import { UserActivityClientsComponent } from './components/user-activity-clients/user-activity-clients.component';
import { UserActivityDailyRepaymentDisbursementComponent } from './components/user-activity-daily-repayment-disbursement/user-activity-daily-repayment-disbursement.component';
import { UserActivityDepositBalanceComponent } from './components/user-activity-deposit-balance/user-activity-deposit-balance.component';
import { UserActivityDisbursedLoansComponent } from './components/user-activity-disbursed-loans/user-activity-disbursed-loans.component';
import { UserActivityDisbursedLoansCountComponent } from './components/user-activity-disbursed-loans-count/user-activity-disbursed-loans-count.component';
import { UserActivityPARComponent } from './components/user-activity-par/user-activity-par.component';
import { UserActivityRunningLoansComponent } from './components/user-activity-running-loans/user-activity-running-loans.component';
import { UserActivityWriteOffsComponent } from './components/user-activity-write-offs/user-activity-write-offs.component';
import { RecentActivityComponent } from './components/recent-activity/recent-activity.component';
import { LoggedInUsersComponent } from './components/logged-in-users/logged-in-users.component';

@NgModule({
  declarations: [
    UserActivityClientOccupationComponent,
    UserActivityClientsComponent,
    UserActivityDailyRepaymentDisbursementComponent,
    UserActivityDepositBalanceComponent,
    UserActivityDisbursedLoansComponent,
    UserActivityDisbursedLoansCountComponent,
    UserActivityPARComponent,
    UserActivityRunningLoansComponent,
    UserActivityWriteOffsComponent,
    RecentActivityComponent,
    LoggedInUsersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ChartsModule
  ],
  exports: [
    UserActivityClientOccupationComponent,
    UserActivityClientsComponent,
    UserActivityDailyRepaymentDisbursementComponent,
    UserActivityDepositBalanceComponent,
    UserActivityDisbursedLoansComponent,
    UserActivityDisbursedLoansCountComponent,
    UserActivityPARComponent,
    UserActivityRunningLoansComponent,
    UserActivityWriteOffsComponent,
    RecentActivityComponent,
    LoggedInUsersComponent
  ]
})
export class UserActivityModule { }
