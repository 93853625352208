<app-chart-wrapper [isLoading]="isLoading" [title]="'Deposit Balance'" (fullscreen)="hideFilters = $event">


    <!-- Filters -->
    <form filter [formGroup]="form" class="flex gap-1 mb-4" [ngClass]="showSortControl?'justify-between':'justify-end'">

        <ng-container *ngIf="showSortControl">
            <button mat-button [matMenuTriggerFor]="sorting" class="sort-btn">
                {{ sortLabel }}
                <img class="ml-2.5 h-5" src="assets/icons/sort-order.svg" alt="" />
              </button>
            <mat-menu #sorting="matMenu">
                <button mat-menu-item *ngFor="let option of sortOptions" (click)="onSort(option)">
                  {{ option.label }}
                </button>
            </mat-menu>
        </ng-container>

        <app-deposit-product-search-select class="w-[40%]" [disabled]="hideFilters" (emitSelectedVal)="filterDepositBalance({ type: reportsInput.DEPOSIT_PRODUCT, value: $event?.id })" [control]="productCtrl" type="client">
        </app-deposit-product-search-select>


    </form>
    <!-- End Filters -->


    <app-circle-chart chart *ngIf="depositChartData?.length === 1 && !isLoading" [totalAmount]="depositChartData[0]?.value ?? 0" [imageName]="'money_wallet'" [classNames]="'text-primary'">
    </app-circle-chart>

    <app-no-data-to-show chart *ngIf="!depositChartData?.length && !isLoading"></app-no-data-to-show>


    <!-- <app-doughnut-chart chart *ngIf="depositChartData?.length > 1 && depositChartData?.length < 5"
        [chartData]="depositChartData"
        [palette]="depositBalancePalette"
    ></app-doughnut-chart> -->

    <app-column-chart chart *ngIf="depositChartData?.length > 1 && !isLoading" [chartData]="depositChartData" [sortingOptions]="depositSortingOptions" [itemsPerPage]="6"></app-column-chart>

</app-chart-wrapper>