import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NotificationService } from 'src/app/utils/notification.service';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.css'],
})
export class ModalChangePasswordComponent {
  @Input() u: any = {};
  @Output() logoutEmit: EventEmitter<any> = new EventEmitter();

  public password: any = {};

  constructor(private utils: UtilityService, private _auth: AuthService, private toast: NotificationService) {}

  public logout(): void {
    this.logoutEmit.emit();
  }

  /* reset password. first time login */
  public changePassword(): void {
    // if ($('#form-password-required').smkValidate()) {
    if (
      !this.password.oldPassword ||
      this.password.oldPassword.trim().length === 0
    ) {
      this.toast.error('Kindly enter your old password');
    } else if (
      !this.password.newPassword ||
      this.password.newPassword.trim().length < 6
    ) {
      this.toast.error('Password should contain a minimum of six character');
    } else if (this.password.newPassword !== this.password.retypedPassword) {
      this.toast.error('Passwords do not match');
    } else {
      this.utils.showLoading();
      // // this._auth
      // //   .resetPassword(
      // //     this.u.id,
      // //     this.password.oldPassword,
      // //     this.password.newPassword,
      // //     'MONTHLY_CHANGE'
      // //   )
      // //   .subscribe((data) => {
      // //     if (data.code == 0) {
      // //       // $('#modal-change-password').modal('hide');
      // //       this.toast.success(data.message);
      // //       setTimeout(function () {
      // //         localStorage.clear();
      // //         location.reload();
      // //       }, 2000);
      //     } else {
      //       this.toast.error(data.message);
      //     }
      //     this.utils.hideLoading();
      //   });
    }
    // }
  }

}
