<ng-container *ngIf="actionButtons?.length > 0 && wrapInMenu">
    <button mat-button [matMenuTriggerFor]="actionBtns" class="flex items-center bg-primary text-white px-3 gap-1">
    {{ selectedBtn.key }}
    <mat-icon class="!h-[16px] text-[16px]">filter_list</mat-icon>
    <!-- <img class="ml-2.5 h-5" src="assets/icons/filter_icon.svg" alt="" /> -->
  </button>
    <mat-menu #actionBtns="matMenu">
        <ng-container *ngFor="let button of actionButtons">
            <button mat-menu-item *hasPermissions="button.perms" [ngClass]="{ 'active-state': button.value === selectedAction }" class="state-item-btn" (click)="actionToggled(button.value)">
        <span> {{ button.key }}</span>
      </button>
        </ng-container>
        <!-- <button mat-menu-item *ngFor="let state of states" [ngClass]="{ 'active-state': state.value === activeState }" class="state-item-btn" (click)="onStateChange(state.value)">
            <span> {{ state.name  }}</span>
        </button> -->
    </mat-menu>
</ng-container>
<!-- <ng-container *ngFor="let button of actionButtons">
        <button [disabled]="disableActionButtons" [class]="defaultClass" [ngClass]="button.selected ? activeClass : inactiveClass" *hasPermissions="button.perms" (click)="actionToggled(button.value)">
        {{ button.key }}
      </button>
    </ng-container> -->
<div *ngIf="actionButtons?.length > 0 && !wrapInMenu" class="flex gap-1 flex-wrap items-center">
    <ng-container *ngFor="let button of actionButtons">
        <button [disabled]="disableActionButtons" [class]="defaultClass" [ngClass]="button.selected ? activeClass : inactiveClass" *hasPermissions="button.perms" (click)="actionToggled(button.value)">
      {{ button.key }}
    </button>
    </ng-container>
</div>