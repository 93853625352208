import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, forkJoin, merge } from 'rxjs';
import { finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { CancelHttpRequestService } from 'src/app/shared/services/cancel-request.service';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
})
export class ClientsComponent implements OnInit {
  public topFiltersFormInstance: FormGroup;
  public hideFilters: boolean = false;
  public isLoading: boolean = false;
  public showSortControl: boolean = false;

  public productId: any;
  public tenantId = JSON.parse(localStorage.getItem('pf') || null).tenantId;

  public legends: { label: string; total: number }[] = [];
  public numberOfTenantBranches: number;

  public clientPalette: string[] = ['#929FB1', '#D4D9E2', '#4d5d76'];

  public clientsForm: FormGroup;
  public clientsFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
  ];
  public clientsChartData?:
    | { name: string; active: number; pending: number; approved: number }[]
    | { name: string; value: number }[] = [];
  public clientsSortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };

  public form: FormGroup;

  public subject = new Subject<number>();
  public reportsInput = ReportInputTypes;

  public sortLabel = 'Sort';

  public sortOptions = [
    { label: 'Default', value: 'default' },
    // { label: 'Value: Asc.', value: 'value-asc'},
    // { label: 'Value: Desc.', value: 'value-desc'},
    { label: 'Name: Asc.', value: 'name-asc' },
    { label: 'Name: Desc.', value: 'name-desc' },
  ];

  constructor(
    private operationalIndicatorService: OperationalIndicatorService,
    private topFilterService: TopFilterService,
    private utils: UtilityService,
    private fb: FormBuilder
  ) {
    this.topFiltersFormInstance = this.topFilterService.topFilterForm;
  }

  ngOnInit(): void {
    this.initForm();
    this.getClientsChartData();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.SORT]: new FormControl(`default`, [
        Validators.required,
      ]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
    });
  }
  public get productCtrl() {
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label;
    this.filterClients({
      value: sortOption.value,
      name: ReportInputTypes.SORT,
      type: ReportInputTypes.SORT,
    });
  }

  getClientsChartData() {
    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          const { areaId, branchId, loanOfficerId, startDate, endDate } =
            this.topFiltersFormInstance.value;
          const { productId, tenantId } = this;

          const queryParams = new URLSearchParams({
            startDate: this.utils.formatDate_custom_yearFirst(startDate),
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(tenantId && { tenantId }),
            ...(productId && { productId }),
          });

          this.isLoading = true;
          return forkJoin([
            this.operationalIndicatorService.getClients(queryParams),
            this.operationalIndicatorService.getClientsSummaryTotal(
              queryParams
            ),
          ]);
        })
      )
      .subscribe({
        next: ([clients, summary]) => {
          this.isLoading = false;
          // Clients

          const { numberOfBranchesForTenant } = clients.data.total[0];
          this.numberOfTenantBranches = numberOfBranchesForTenant;

          if (clients?.data?.data.length < 2) {
            this.hideFilters = true;
            this.clientsChartData = [
              {
                name: 'Active',
                value: clients.data.data[0]?.activeClients || 0,
              },
              {
                name: 'Pending',
                value: clients.data.data[0]?.pendingClients || 0,
              },
              {
                name: 'Approved',
                value: clients.data.data[0]?.approvedClients || 0,
              },
            ];
            this.showSortControl = false;
          } else {
            this.showSortControl = true;
            this.clientsChartData = clients.data.data.map((item: any) => ({
              name: item.branchName,
              bar3: item.pendingClients,
              bar2: item.activeClients,
              bar1: item.approvedClients,
            }));
          }

          // Summary
          const {
            totalActiveClients,
            totalPendingClients,
            totalApprovedClients,
          } = summary.data;

          this.legends = [
            {
              label: 'Approved',
              total: totalApprovedClients,
            },
            {
              label: 'Active',
              total: totalActiveClients,
            },
            {
              label: 'Pending',
              total: totalPendingClients,
            },
          ];
        },
      });
  }

  filterClients(ev: any) {
    switch (ev.type) {
      case ReportInputTypes.SORT:
        const selectedString =
          ev.value === 'default' ? 'name-default' : ev.value;
        const [key, sortingDirection] = selectedString.split('-');
        this.clientsSortingOptions = { key, sortingDirection };
        break;
      case ReportInputTypes.LOAN_PRODUCT:
        this.productId = ev.value;
        this.subject.next(Math.random());
        break;
    }
  }
}
