import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseCoreUrl: string = environment.baseUrl + environment.coreUrl;
  baseUrl: string = environment.baseUrl;
  public searchLoanOfficerEndpoint = `${this.baseCoreUrl}users?is-loan-officer=true&page=0&pageSize=100&state=ALL&sort-field=lastUpdateTime&sort-order=DESC&filter=`
  public searchLoanOfficerEndpointByParam = (param?:string)=> `${this.baseCoreUrl}users?${
    param ? '&' + param + '&' : ''
  }is-loan-officer=true&page=0&pageSize=100&state=ALL&sort-field=lastUpdateTime&sort-order=DESC&filter=`
  public searchApprovedLoanOfficerEndpoint = `${this.baseCoreUrl}users?is-loan-officer=true&page=0&pageSize=100&state=APPROVED&sort-field=lastUpdateTime&sort-order=DESC&filter=`

  constructor(private http: HttpClient) {}

  public createUser(data: any) {
    return this.http.post<any>(this.baseCoreUrl + 'users', data);
  }

  public getAllUsers(req: any): Observable<any> {
    return this.http.get(this.baseCoreUrl + `users?${req}`);
  }

  public getUser(id: number): Observable<any> {
    return this.http.get(this.baseCoreUrl + 'users/' + id);
  }

  public updateUser(data: Object) {
    return this.http.put<any>(this.baseCoreUrl + 'users', data);
  }

  /* enable / disable branches.Takes data{'ids': [1,2,baseUrl3], 'enable': bool} */
  public toggleUsers(data: any): Observable<any> {
    return this.http.post<any>(this.baseCoreUrl + 'users/toggle', data);
  }

  public changeRole(data: any, userId: number) {
    return this.http.put<any>(`${this.baseCoreUrl}users/${userId}/roles`, data);
  }

  public getUserGroups(params: string) {
    return this.http.get(`${this.baseCoreUrl}groups?${params}`);
  }

  public getUserClients(userId: number, params: string): Observable<any> {
    return this.http.get(
      `${this.baseCoreUrl}users/${userId}/clients?${params}`
    );
  }
  public getUserClientsFirst500(userId: number) {
    return this.http.get(
      `${this.baseCoreUrl}users/${userId}/clients?page=0&pageNumber=500`
    );
  }

  public getUserLoans(userId: number, params: string) {
    return this.http.get(
      `${this.baseUrl}accounts/loan-accounts/loan-officer/${userId}?${params}`
    );
  }
  public getOfficerLoans(params: string) {
    return this.http.get(
      `${this.baseUrl}accounts/loan-accounts/get-loanAccount-by-loan-officerId?${params}`
    );
  }
  public downloadOfficerLoans(params: string) {
    const headers = new HttpHeaders({ Accept: 'application/vnd.ms-excel' });
    return this.http.get(
      `${this.baseUrl}accounts/loan-accounts/loan-account-by-loan-officer-id/download?${params}`,
      { headers, responseType: 'blob' }
    );
    
  }

  public getUserSavings(userId: number, params: string) {
    return this.http.get(
      `${this.baseUrl}accounts/saving-accounts/loan-officer/${userId}?${params}`
    );
  }

  public resetUserPassword(data: any) {
    return this.http.put<any>(this.baseCoreUrl + 'users/reset-password', data);
  }
}
