import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-loan-status-search-select',
  templateUrl: './loan-status-search-select.component.html',
  styleUrls: ['./loan-status-search-select.component.css']
})
export class LoanStatusSearchSelectComponent {
  @Input() control: FormControl | string;
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();
  
  constructor() { }

  statusChange(event) {
    if(event === 0) event = '';
    this.emitSelectedVal.emit(event);
  }

  public get ctrl() {
    return this.control as FormControl;
  }

}
