import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SearchableSelectTypes } from '../../models/common';

@Component({
  selector: 'app-searchable-select',
  templateUrl: './searchable-select.component.html',
  styleUrls: ['./searchable-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SearchableSelectComponent,
      multi: true
    }
  ]
})
export class SearchableSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() disabled: boolean = false;
  @Input() label: string
  @Input() required: boolean = true
  @Input() multiple: boolean = false
  @Input() options: SearchableSelectTypes[]
  @Input() serverSearch: boolean = false
  @Input() showDefaultOption: boolean = false

  @Output() selectionChangeEmit:EventEmitter<any> = new EventEmitter();
  searchOptions: SearchableSelectTypes[] = []
  multipleValues = []

  onChange: (value: any) => void
  onTouch: () => void
  isDisabled: boolean = false

  public form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      value: new FormControl(),
    })
  }
  
  get value() {
    return this.form.get('value')
  }

  byOptionId(index, option) {
    return option.id
  }

  ngOnInit(): void {
    if(this.required) {
      this.value.addValidators(Validators.required)
      this.value.updateValueAndValidity()
    }
    else {
      this.value.clearValidators()
      this.value.updateValueAndValidity()
    } 
  }

  writeValue(obj: any): void {
    this.value.setValue(obj)
    this.value.updateValueAndValidity()
    this.form.get('value').setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.value.disable() : this.value.enable()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) this.searchOptions = changes['options'].currentValue
  }

  setValue(ev: any, changed?: boolean) {
    // TO DO: When its a multi select input
    // if (this.multiple && ev.value) {
    //   if (this.multipleValues.length > 0) {
    //     this.multipleValues = [...new Set([...this.multipleValues, ...ev.value])]
    //   } else if (this.multipleValues.length < 1) {
    //     this.multipleValues = ev.value
    //   }
    // }

    if(ev.value === 'all') {
      this.selectionChangeEmit.emit({label: 'all', value: 0});
    }else {
      this.selectionChangeEmit.emit(this.options.find(opt=>opt.value === ev.value))
    }
    

    if(changed) {
      this.value.setValue(ev.value)
      this.onChange(ev.value)
      this.onTouch()
      return
    }

    // if(this.serverSearch) {
    //   this.value.setValue(ev.target.value)
    //   this.onChange(ev.target.value)
    //   this.onTouch()
    // } else this.searchSelectField(ev.target.value)
  }

  searchSelectField(searchEvent: any) {
    let searchString = searchEvent.target.value;
    this.searchOptions = this.options
      .filter(option =>
        option.label.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
      )
  }

}
