import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

export const routes = [
  {path: '', component: ResetPasswordComponent, pathMatch: 'full'},
]

@NgModule({
  declarations: [
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  exports:[ResetPasswordComponent]
})
export class ResetPasswordModule { }
