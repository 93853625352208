<div class="flex gap-3 p-1 rounded-lg overflow-auto">
    <ng-container *ngFor="let button of buttons">
        <button class="p-3 md:px-5 whitespace-nowrap border border-opacity-20 border-primary text-primary" *ngIf="hasPermission(button.perms)" (click)="toggleActive(button.value)" [ngClass]="
        button.isActive
          ? ' rounded-lg text-white bg-primary/90 hover:bg-primary'
          : ''
      ">
      {{ button.name }}
    </button>
    </ng-container>
</div>