<div class="relative text-[10px]">
    <div *ngIf="!options.series['length']" class="absolute flex justify-center items-center w-full h-full">
        <p>No Data To Show</p>
    </div>
    <div *ngIf="setup && setup.yAxis.length && showLegend" class="flex items-center  justify-center py-1 gap-6 w-full">
        <p class="flex items-center gap-3" *ngFor="let yAxis of setup.yAxis">
            <span>{{yAxis.label}}</span>
            <span class="flex gap-1 items-center">
                <i *ngFor="let item of [].constructor(5); let i = index" [ngStyle]="{'background-color':yAxis.color}" class="rounded-full w-[3px] h-[3px] text-transparent" [ngClass]="i === 2 ?'!w-[10px] !h-[10px] border border-white':''" >.</i>
            </span>
        </p>

    </div>
    <div #echarts echarts [options]="options" theme="macarons"></div>
    <div #echarts2 echarts [options]="options2" theme="macarons" class="hidden"></div>

    <app-chart-paginator *ngIf="chartData && chartData.length" [currentPage]="currentPage" [totalPages]="totalPages" [dynamicBottom]="dynamicBottom" (updateActionEmit)="updateChartData($event)"></app-chart-paginator>
    <label *ngIf="hasCurrencySymbol" class="absolute italic -top-3 left-2 unit-label">{{utilityService.getCurrencySymbol('NGN')}}{{scale.unit}}</label>
    <label *ngIf="!hasCurrencySymbol" class="absolute top-2 left-2 unit-label">{{scale.unit}}</label>
</div>