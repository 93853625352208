import { Component, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-account-guarantors',
  templateUrl: './account-guarantors.component.html',
  styleUrls: ['./account-guarantors.component.css']
})
export class AccountGuarantorsComponent implements OnInit {

  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: 'First Name',
      key: 'first_name',
      type: 'text',
      value: 'first_name',
    },
    {
      name: 'Last Name',
      key: 'last_name',
      type: 'text',
      value: 'last_name',
    },
    {
      name: 'Email',
      key: 'email',
      type: 'text',
      value: 'email',
    },
    {
      name: 'Phone Number',
      key: 'phone_number',
      type: 'text',
      value: 'phone_number',
    },
    {
      name: 'Photo',
      key: 'photo',
      type: 'text',
      value: 'photo',
    },
    {
      name: 'ID Card',
      key: 'id_card',
      type: 'text',
      value: 'id_card',
    },
    {
      name: 'Signature',
      key: 'signature',
      type: 'text',
      value: 'signature',
    }
   
  ]

  public payload = this.dummyData.depositTransactionsDummyTableData
  public documents: any[] = []

  constructor(private dummyData: DummyDataService) { }

  ngOnInit(): void {

  }

  
  public pageSizeToggle (size:number){

  }

  public pageCtrlToggle (ctrl:string){

  }

  onSort(value) {
  }

  onSearch(value) {
  }

}
