import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, firstValueFrom, merge, switchMap } from 'rxjs';
import { OperationalIndicatorService } from 'src/app/pages/dashboard/services/operational-indicator.service';
import { TopFilterService } from 'src/app/pages/dashboard/services/topFIlter.service';
import {
  ChartFilterControlsInterface,
  InitDisplayedCol,
  ReportInputTypes,
} from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-client-occupation',
  templateUrl: './client-occupation.component.html',
  styleUrls: ['./client-occupation.component.css'],
})
export class ClientOccupationComponent implements OnInit {
  public hideFilters: boolean = false;
  public isLoading: boolean = false;

  public clientOccupationForm: FormGroup;
  public clientOccupationFormControls: ChartFilterControlsInterface[] = [
    {
      name: ReportInputTypes.CLIENT_STATUS,
      type: 'CLIENT_STATUS',
      position: 'right',
    },
    {
      name: ReportInputTypes.LOAN_PRODUCT,
      type: 'LOAN_PRODUCT',
      position: 'right',
    },
  ];

  public productId = 0;
  public state = 'APPROVED';

  public reportsInput = ReportInputTypes;
  public form: FormGroup;

  public clientStatus = [
    { label: 'Approved Clients', value: 'approved' },
    { label: 'Active Client', value: 'running' },
  ];

  public defaultColumn: InitDisplayedCol[] = [
    {
      name: 'Business Type',
      value: 'businessName',
      type: 'text',
      key: 'businessName',
      border: 'r',
      maxLen: 40,
      footer: 'Total',
    },
    {
      name: 'Count',
      value: 'approvedClients',
      type: 'number',
      key: 'approvedClients',
      border: 'r',
      footer: '233003',
    },
    {
      name: 'Percentage',
      value: 'approvedClientsPercentage',
      type: 'percentile',
      key: 'approvedClientsPercentage',
      footer: '100%',
    },
  ];

  public columns: InitDisplayedCol[] = [];
  public subject = new Subject<number>();

  payload;

  setColumn(payload: any[]) {
    if (this.state === 'running') {
      const total = payload.reduce((acc, item) => acc + item.activeClients, 0);
      const totalPer = payload.reduce(
        (acc, item) => acc + item.activeClientsPercentage,
        0
      );
      const columns = JSON.parse(JSON.stringify(this.defaultColumn)).map(
        (col) => {
          if (col.value === 'approvedClients') {
            col.value = 'activeClients';
            col.footer = total;
          }
          if (col.value === 'approvedClientsPercentage') {
            col.value = 'activeClientsPercentage';
            col.footer = totalPer;
          }
          return col;
        }
      );
      this.columns = columns;
      return;
    }
    const total = payload.reduce((acc, item) => acc + item.approvedClients, 0);
    const totalPer = payload.reduce(
      (acc, item) => acc + item.approvedClientsPercentage,
      0
    );
    const columns = JSON.parse(JSON.stringify(this.defaultColumn)).map(
      (col) => {
        if (col.value === 'activeClients' || col.value === 'approvedClients')
          col.footer = total;
        if (col.value === 'approvedClientsPercentage') {
          col.footer = totalPer;
        }
        return col;
      }
    );
    this.columns = columns;
  }

  constructor(
    private topFilterService: TopFilterService,
    private service: OperationalIndicatorService,
    private utils: UtilityService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.fetchDetails();
    this.subject.next(Math.random());
  }

  private initForm() {
    this.form = this.fb.group({
      [ReportInputTypes.CLIENT_STATUS]: new FormControl('running', [
        Validators.required,
      ]),
      [ReportInputTypes.LOAN_PRODUCT]: new FormControl(0, [
        Validators.required,
      ]),
    });
  }

  public setHideFilters(val: boolean) {
    this.hideFilters = val;
    if (val) {
      this.form.get(ReportInputTypes.CLIENT_STATUS).disable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).disable();
    } else {
      this.form.get(ReportInputTypes.CLIENT_STATUS).enable();
      this.form.get(ReportInputTypes.LOAN_PRODUCT).enable();
    }
  }

  public get productCtrl() {
    return this.form.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  filterClientOccupation(ev: any) {
    switch (ev.type) {
      case ReportInputTypes.LOAN_PRODUCT:
        this.productId = ev.value;

        break;
      case ReportInputTypes.CLIENT_STATUS:
        this.state = ev.value;
        break;
    }
    this.subject.next(Math.random());
  }

  async fetchDetails() {
    merge(
      this.topFilterService.topFilterForm.valueChanges,
      this.subject.asObservable()
    )
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          const { areaId, branchId, loanOfficerId, startDate, endDate } =
            this.topFilterService.topFilterForm.value;


          const queryParams = new URLSearchParams({
            startDate: this.utils.formatDate_custom_yearFirst(startDate),
            endDate: this.utils.formatDate_custom_yearFirst(endDate),
            ...(areaId && { areaId }),
            ...(branchId && { branchId }),
            ...(loanOfficerId && { loanOfficerId }),
            ...(this.productId && { productId: this.productId.toString() }),
            ...(this.state && { state: this.state.toUpperCase() }),
          });

          return this.service.getClientOccupation(queryParams);
        })
      )
      .subscribe((res) => {
        this.setColumn(res.data.data);
        this.payload = res.data.data;
        this.isLoading = false;
      });
  }
}
