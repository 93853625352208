<!-- Standalone Formcontrol -->
<mat-form-field *ngIf="!controlGroup" class="w-[100%]" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="ctrl" [placeholder]="placeholder" #singleSelect>
        <mat-option>
            <ngx-mat-select-search [placeholderLabel]="placeholder" noEntriesFoundLabel="'No match found'" [formControl]="filterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="showDefaultListValue" [value]="0">All</mat-option>
        <mat-option class="capitalize" *ngFor="let record of filteredList | async" [value]="record[keyValue['value']]">
            {{ keyValue["key"] | mergeMultiValues : record | titlecase }}
        </mat-option>
    </mat-select>
</mat-form-field>

<!-- Form Groups -->
<ng-container *ngIf="controlGroup">
    <mat-form-field [formGroup]="controlGroup" class="w-[100%]" appearance="outline">
        <mat-label>{{ label }}</mat-label>
        <mat-select [formControlName]="control + ''" [placeholder]="placeholder" #singleSelect>
            <mat-option>
                <ngx-mat-select-search [disabled]="disabled" [placeholderLabel]="placeholder" noEntriesFoundLabel="'No match found'" [formControl]="filterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="showDefaultListValue" [value]="0">All</mat-option>
            <mat-option class="capitalize" *ngFor="let record of filteredList | async" [value]="record[keyValue['value']]">
                {{ keyValue["key"] | mergeMultiValues : record | titlecase }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>