import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { depositChartData } from 'src/app/shared/charts/datasource';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-fees-commissions',
  templateUrl: './fees-commissions.component.html',
  styleUrls: ['./fees-commissions.component.css']
})
export class FeesCommissionsComponent implements OnInit {

  public hideFilters: boolean = false

  public feesComissionFrom: FormGroup
  public feesComissionFromControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.SORT, type: 'SORT', position: 'left' },
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  public feesComissionSortingOptions: { key: 'name' | 'value', sortingDirection: 'asc' | 'desc' | 'default' } = {
    key: 'name',
    sortingDirection: 'asc'
  }
  feesComissionChartData?: any[] = depositChartData;

  constructor() { }

  ngOnInit(): void {
    this.feesComissionChartData = depositChartData.map((item: any) => ({
      name: item.country,
      value: item.gold
    }))
  }

  filterFeesComission(ev: any) {
    if (ev.type === 'SORT') {
      const selectedString = ev.value === 'default' ? 'name-default' : ev.value;
      const [key, sortingDirection] = selectedString.split('-');
      this.feesComissionSortingOptions = { key, sortingDirection };
    }
  }

}
