import { KeyValueType } from "../models/common";

export const Genders:KeyValueType[] = [
    {
        key: "Male",
        value: "MALE"
    },
    {
        key: "Female",
        value: "FEMALE"
    },
    {
        key: "Others",
        value: "OTHER"
    }
]  
export const LoanOfficerStatus: KeyValueType[] = [
    {
        key: "Yes",
        value: "YES"
    },
    {
        key: "No",
        value: "NO"
    },
]  
export const ClientTypes:KeyValueType[] = [
    {
        key: "Individual",
        value: "INDIVIDUAL"
    },
    {
        key: "Group",
        value: "GROUP"
    },
]  
export const ClientGroupRole:KeyValueType[] = [
    {
        key: "Member",
        value: "MEMBER"
    },
    {
        key: "Leader",
        value: "LEADER"
    },
    {
        key: "Secretary",
        value: "SECRETARY"
    },
    {
        key: "Treasurer",
        value: "TREASURER"
    },
]
export function getUserLanguages() {
    const Languages:KeyValueType[] = [
        {
            key: $localize`:Language english|label for english language@@lang_english:English`,
            value: "en"
        },
        {
            key: $localize`:Language french|label for french language@@lang_french:French`,
            value: "fr"
        },
        {
            key: $localize`:Language german|label for german language@@lang_german:German`,
            value: "de"
        },
        {
            key: $localize`:Language spanish|label for spanish language@@lang_spanish:Spanish`,
            value: "es"
        },
        {
            key: $localize`:Language portuguese|label for portuguese language@@lang_portuguese:Portuguese`,
            value: "pt"
        },
    ]
    return Languages;
}

export const Designations:KeyValueType[] = [
    {
        key: $localize`:staff|label for staff@@staff_enum:Staff`,
        value: "STAFF"
    },
    {
        key: $localize`:director|label for director@@director_enum:Director`,
        value: "DIRECTOR"
    },
]

export const RelationshipTypes: KeyValueType[] = [
    {
        key: 'Brother',
        value: 'BROTHER'
    },
    {
        key: 'Sister',
        value: 'SISTER'
    },
    {
        key: 'Parent',
        value: 'PARENT'
    },
    {
        key: 'Father',
        value: 'FATHER'
    },
    {
        key: 'Mother',
        value: 'MOTHER'
    },
    {
        key: 'Uncle',
        value: 'UNCLE'
    },
    {
        key: 'Aunt',
        value: 'AUNT'
    },
    {
        key: 'Uncle',
        value: 'UNCLE'
    },
    {
        key: 'Nephew',
        value: 'NEPHEW'
    },
    {
        key: 'Nephew',
        value: 'NIECE'
    },
    {
        key: 'Cousin',
        value: 'COUSIN'
    },
    {
        key: 'Son',
        value: 'SON'
    },
    {
        key: 'Daughter',
        value: 'DAUGTHER'
    },
    {
        key: 'Spouse',
        value: 'SPOUSE'
    },
    {
        key: 'Friend',
        value: 'FRIEND'
    },
    {
        key: 'Sibling',
        value: 'SIBLING'
    },
    {
        key: 'Business Associate',
        value: 'BUSINESS_ASSOCIATE'
    },
    {
        key: 'Others',
        value: 'OTHERS'
    },
]

export const CalendarUnit = [
    {
        label: 'Day',
        value: 'DAY'
    },
    {
        label: 'Week',
        value: 'WEEK'
    },
    {
        label: 'Month',
        value: 'MONTH'
    },
    {
        label: 'Year',
        value: 'YEAR'
    },
]