import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.css']
})
export class CustomInputComponent {

  customForm: FormGroup;

  public get title (){
    return this.data.title || 'Custom Title'
  }
  public get label (){
    return this.data.label || 'Custom Label'
  }
  constructor(
    private dialogRef: MatDialogRef<CustomInputComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.customForm = this.formBuilder.group({
      customInput: ['', [Validators.required]],
    })
  }


  submit() {
    if (!this.customForm.valid) {
      this.customForm.markAllAsTouched()
      return;
    }

    this.dialogRef.close( this.customForm.value)
  }

  cancel(){
    this.dialogRef.close()
  }

}
