import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationResponse } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class UsernotificationService {

  private notificationsEndpoint: string = `${environment.baseUrl}api/v2/internal/core/data/notifications`;
  constructor(private httpClient: HttpClient) { }

  public getNotifications(): Observable<NotificationResponse> {
    // let notifications: Notification[] = [new Notification()];
    // return of(notifications);
      return this.httpClient.get<NotificationResponse>(this.notificationsEndpoint);
  }
}
