import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { PageRequest, TogglePayload } from 'src/app/shared/models/common';
import { UtilityService } from 'src/app/utils/utility.service';
import { environment } from 'src/environments/environment';
import { ClientService } from '../../client-management/services/client.service';

@Injectable({
  providedIn: 'root',
})
export class DepositService {
  baseUrl: string = environment.baseUrl;
  // Assumptions a client wont have more than a hundred account
  public pageRequest: PageRequest = {
    page: 0,
    pageSize: 100,
    state: 'APPROVED',
    sortField: 'last_update_time',
    sortOrder: 'DESC',
    filter: '',
  };
  public getAllSavings = (id)=>this.getClientSavingAccounts(id, this.pageRequest);

  constructor(
    private http: HttpClient,
    private clientService: ClientService,
    private utilityService: UtilityService
  ) {}

  public addSavingsAccount(payload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}accounts/saving-accounts`, payload);
  }
  public getSavingAccount(id: number): Observable<any> {
    return this.http.get(`${this.baseUrl}accounts/saving-accounts/${id}`);
  }

  public getSavingAccounts(queryParams: PageRequest): Observable<any> {
    // this.currentTableRequest = req;
    const payload = this.utilityService.objToQueryParams(queryParams);
    
    return this.http.get(`${this.baseUrl}accounts/saving-accounts${payload}`);
  }
  public approve(data: TogglePayload): Observable<any> {
    // this.currentTableRequest = req;
    const state =
      data.state === 'enabled' ? 'APPROVED' : data.state.toUpperCase();
    const payload = { ...data, state };
    return this.http.put<any>(
      this.baseUrl + 'accounts/saving-accounts/approve',
      payload
    );
  }
  public toggle(data: TogglePayload): Observable<any> {
    // this.currentTableRequest = req;
    const state =
      data.state === 'enabled' ? 'APPROVED' : data.state.toUpperCase();
    const payload = { ...data, state };
    return this.http.put<any>(
      this.baseUrl + 'accounts/saving-accounts/toggle',
      payload
    );
  }

  public getClientSavingAccounts(
    id: number,
    queryParams: any
  ): Observable<any> {
    const payload = this.utilityService.objToQueryParams(queryParams);
    return this.http
      .get(this.baseUrl + `accounts/saving-accounts/clients/${id}${payload}`)
      .pipe(
        tap((res) => {
          if (!res.successful) return;
          this.clientService.setClientAccountCount('savings', res.data.length);
        })
      );
  }

  public getGroupSavingAccounts(id: number, req: any): Observable<any> {
    return this.http.get(
      this.baseUrl + `accounts/saving-accounts/group/${id}?${req}`
    );
  }

  public getSavingTransactions(id: number, req: any): Observable<any> {
    return this.http.get(
      this.baseUrl + `accounts/savings-transactions/${id}?${req}`
    );
  }

  public makeDepositOrWithdrawal(id: number, payload: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}accounts/saving-accounts/${id}/make-posting`,
      payload
    );
  }

  public transactionReversal(id: number, payload: any): Observable<any> {
    return this.http.post(
      `${this.baseUrl}accounts/savings-transactions/${id}/reversal`,
      payload
    );
  }

  public getDepositAudit(params: any): Observable<any> {
    return this.http.get(`${this.baseUrl}accounts/audits`, { params } );
  }
}
