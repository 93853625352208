<div cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="w-[300px] sm:w-[400px]">
    <h5 class="mb-5 text-lg">{{ title }}</h5>

    <div class="panel panel-default">
        <div class="panel-heading">
            <h5 class="panel-title">{{ listTitle }}</h5>
        </div>
        <div class="max-h-[300px] overflow-auto">
            <div class="panel-body border-b-2 rounded-none border-b-gray-100" *ngFor="let list of unhandledList">
                {{list.name}}
            </div>
        </div>
    </div>
    <h4 class="mb-3">{{message}}</h4>

    <div *ngIf="!showOk" class="flex flex-wrap gap-10 justify-center">
        <app-button-icon-ui [buttonType]="'button'" (clickEmit)="removeAll()" classNames="p-3 rounded-xl text-white bg-success/90 hover:bg-success w-[180px]" name="Proceed" value="submit"></app-button-icon-ui>
        <app-button-icon-ui [buttonType]="'button'" classNames="p-3 rounded-xl border border-error opacity-80 hover:opacity-100 text-error w-[180px]" (clickEmit)="cancel()" name="Cancel" value="cancel"></app-button-icon-ui>
    </div>

    <app-button-icon-ui *ngIf="showOk" [buttonType]="'button'" classNames="p-3 rounded-xl text-white bg-primary/90 hover:bg-primary w-[100%]" (clickEmit)="cancel()" name="Ok" value="cancel"></app-button-icon-ui>

</div>