import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/utils/notification.service';
import { environment } from 'src/environments/environment';
import { FileDetail } from '../../models/common';

@Component({
  selector: 'app-image-upload-ui',
  templateUrl: './image-upload-ui.component.html',
  styleUrls: ['./image-upload-ui.component.css'],
})
export class ImageUploadUiComponent implements OnInit, OnChanges, OnDestroy {
  @Input() label: string = 'Photo';
  @Input() fetchedPhoto: string;
  @Input() resetForm;
  @Input() componentName: string = '';
  @Input() isViewMode: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() isMultiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() acceptedFileTypes: string = '.png, .jpeg, .jpg';
  @Input() buttonValue: string = 'Photo';
  public buttonPrefix:string = 'Upload'
  @Input() set defaultFiles(file: File | null) {
    if (!file) return
    this.filePreview = { file, url: this.createUrl(file) }
  }
  @Output() fileChangeEmit: EventEmitter<any> = new EventEmitter();
  @Output() fileEmit: EventEmitter<any> = new EventEmitter();
  // @Output() fileChangeEmit: EventEmitter<string|ArrayBuffer> = new EventEmitter();

  public files: FileDetail | null;
  public filePreview: FileDetail | null = null;
  public filesArray = [];
  private subscription: Subscription = new Subscription();
  public imgUrl: string = environment.baseUrl + environment.coreUrl + 'data/';
  public srcImage;
  constructor(
    private sanitizer: DomSanitizer,
    private toast: NotificationService,
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.resetForm) {
      this.filePreview = null;
    }
  }

  ngOnInit(): void {
    if (this.fetchedPhoto) {
      this.srcImage = this.imgUrl + this.fetchedPhoto;
      this.buttonPrefix = 'Change'
    }
  }

  public upload(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files.length > 0) {
      const file: File = files[0];
      const fileName: string[] = file.name.split('.');
      const type = fileName[fileName.length - 1];
      const name: string = fileName.slice(0, -1).join('');
      const size = Math.trunc(file.size / 1024)
      if (size > 1000) {
        return this.toast.error("Maximum Photo Upload is 1Mb");
      }
      this.files = {
        name,
        size: Math.trunc(file.size / 1024),
        file: file,
        url: this.createUrl(file),
        type,
      };
      this.fileEmit.emit(file)
      this.subscription.add(
        this.convertFile(file).subscribe(base64 => {
          this.fileChangeEmit.emit(base64);
        })
      );
      this.buttonPrefix = 'Change'

      this.filePreview = this.files;
    }

  }

  private createUrl(file: File) {
    const fileName: string[] = file.name.split('.');
    const type = fileName[fileName.length - 1];
    return type !== 'pdf'
      ? this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      : URL.createObjectURL(file)
  }



  // convert input file to base64
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
