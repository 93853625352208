import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PermissionService } from 'src/app/utils/permission.service';
import { StateTypes } from '../../models/common';

@Component({
  selector: 'app-state-ui',
  templateUrl: './state-ui.component.html',
  styleUrls: ['./state-ui.component.css'],
})
export class StateUiComponent {
  @Input() alignState: 'left' | 'right' = 'right';
  @Input() title: string = '';
  @Input() states: StateTypes[] = [];
  @Input() initialState: string;

  @Output() stateEmit: EventEmitter<StateTypes['value']> = new EventEmitter();
  constructor(private permissionsService: PermissionService) {}

  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }
  public actionToggled(val: StateTypes['value']) {
    this.stateEmit.emit(val);
  }
}
