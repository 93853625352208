import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { BooleanReversedValues, BooleanValues, CapitalizePipe, CounterFormat, DetermineDayPipe, 
  FilterGlAccountsPipe, FindValInObject, GeneralFormat, MergeMultiValues, MinusValuesPipe, MoneyFormat, 
  MoneyFormatInNaira, MoneySymbolFormat, MycurrencyPipe, NoDecimalMoneyFormatInNaira, NumberWithCommas, 
  NumberWithCommasAndTwoDecimal, PercentageFormat, PercentageFormat1, PercentageFormat2, PercentageFormat3, 
  PhoneNumberPipe, Truncate, TwoDigitNumberPipe, TiteCaseFormat, UnderScoreToSpacePipe, FilterGlAccountNamePipe, PercentageFormat4, FormatTotalNumber, OrdinalDatePipe } from './wizer.pipes';


const PIPES = [
  MoneyFormat,
  MoneySymbolFormat,
  GeneralFormat,
  MoneyFormatInNaira,
  MycurrencyPipe,
  CounterFormat,
  PercentageFormat,
  PercentageFormat1,
  CapitalizePipe,
  FormatTotalNumber,
  DetermineDayPipe,
  PhoneNumberPipe,
  PercentageFormat2,
  PercentageFormat4,
  PercentageFormat3,
  MinusValuesPipe,
  FindValInObject,
  NumberWithCommas,
  NumberWithCommasAndTwoDecimal,
  Truncate,
  MergeMultiValues,
  NoDecimalMoneyFormatInNaira,
  BooleanValues,
  BooleanReversedValues,
  TwoDigitNumberPipe,
  FilterGlAccountsPipe,
  FilterGlAccountNamePipe,
  TiteCaseFormat,
  OrdinalDatePipe,
  UnderScoreToSpacePipe
]

@NgModule({
  declarations: PIPES,
  imports: [
    CommonModule
  ],
  exports: PIPES,
  providers: [TitleCasePipe]
})
export class UtilsModule { }
