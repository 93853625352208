import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { API, ReportInputTypes } from 'src/app/shared/models/common';

@Injectable({
  providedIn: 'root',
})
export class TopFilterService {
  public topFilterForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.topFilterForm = this.formBuilder.group({
      [ReportInputTypes.AREA]: [0, [Validators.required]],
      [ReportInputTypes.BRANCH]: [0, [Validators.required]],
      [ReportInputTypes.LOAN_OFFICER]: [0, [Validators.required]],
      [ReportInputTypes.START_DATE]: [new Date(), [Validators.required]],
      [ReportInputTypes.END_DATE]: [new Date(), [Validators.required]],
    });
  }
}
