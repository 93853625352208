<app-chart-wrapper [showTotalCount]="!!total" [total]="total" [wrapperClass]="'!h-[285px] !gap-y-0'" [filterClass]="'pt-0'" [tableClass]="'p-0'" [isLoading]="isLoading" [title]="'Logged-In Users'" [showExpandIcons]="false">
    <!-- <table table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Time</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let n of [1,2,3,4,5,6]">
                <td>Admin</td>
                <td>3pm</td>
            </tr>
        </tbody>
    </table> -->
    <app-chart-summary-table headerClass="!bg-white border-r border-black/10" *ngIf="payload?.length && !isLoading" [tableWrapperClass]="payload && payload.length ? 'max-h-[240px] rounded-md ':'' " [tableClass]="''" [data]="payload" [initColumns]="columns"
        table></app-chart-summary-table>
</app-chart-wrapper>