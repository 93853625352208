import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-disbursed-loans-count',
  templateUrl: './user-activity-disbursed-loans-count.component.html',
  styleUrls: ['./user-activity-disbursed-loans-count.component.css']
})
export class UserActivityDisbursedLoansCountComponent implements OnInit {

  public hideFilters: boolean = false
  public disbursedLoanCountForm: FormGroup
  public disbursedLoanCountFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]
  
  constructor() { }

  ngOnInit(): void {
  }

  filterDisbursedLoansCount(ev: any) {
  }

}
