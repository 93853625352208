import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity-disbursed-loans',
  templateUrl: './user-activity-disbursed-loans.component.html',
  styleUrls: ['./user-activity-disbursed-loans.component.css']
})
export class UserActivityDisbursedLoansComponent implements OnInit {

  public hideFilters: boolean = false

  public disbursedLoanForm: FormGroup
  public disbursedLoanFormControls: ChartFilterControlsInterface[] = [
    { name: ReportInputTypes.LOAN_PRODUCT, type: 'LOAN_PRODUCT', position: 'right' },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  filterDisbursedLoans(ev: any) {
  }

}
