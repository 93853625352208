import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import type { EChartsOption, LineSeriesOption } from 'echarts';
import { lineData } from '../datasource';

import * as echarts from 'echarts';
import { UtilityService } from 'src/app/utils/utility.service';
import { LineChartSetup } from '../../models/common';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css'],
})
export class LineChartComponent implements OnInit, OnChanges {
  @ViewChild('echarts') echartsElement: ElementRef;
  @ViewChild('echarts2') echarts2Element: ElementRef;

  @Output() emitScale: EventEmitter<string> = new EventEmitter();
  @Output() emitCurrentChartData: EventEmitter<any[]> = new EventEmitter();
  @Input() hasCurrencySymbol: boolean = true;
  @Input() width: string = '300px';
  @Input() palette?: string[] = ['#4d5d76', '#CC2C2C'];
  @Input() setup: LineChartSetup;
  @Input() showLegend: boolean = false;
  @Input() defaultChartData?: { name: string; value: number }[] = [];

  // @Input() yAxisLabels?: string[] = ['y', 'y1'];
  @Input() chartData?: any[];
  @Input() itemsPerPage: number = 4; // Number of items per page
  months = [];
  averageKey = '';
  currentPage: number = 1;
  totalPages: number;
  dynamicBottom: string = '2%';
  formatZeros: string = '';
  public scale = {
    unit: '',
    value: 1,
  };

  options2: EChartsOption = {};

  @Input() options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      textStyle: {
        fontSize: 10,
      },
      formatter: (params) => {
        return `${params[0].name}<br />
          ${params[0].marker} ${
          params[0].seriesName
        } : ${this.utilityService.currencyFormat(
          params[0].data * this.scale?.value
        )} <br />
          ${params[1].marker} ${
          params[1].seriesName
        } : ${this.utilityService.currencyFormat(
          params[1].data * this.scale?.value
        )} <br />
        `;
      },
    },
    xAxis: [
      {
        type: 'category',
        data: [new Date().toString()],
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 1,
          fontSize: 10,
          formatter: (value: string) => {
            const isDate = new Date(value);
            if (isDate.toString() === 'Invalid Date') return value;
            return `${isDate.getDate()}\n ${
              this.utilityService.months[isDate.getMonth()]
            } '${isDate.getFullYear().toString().slice(-2)}`;
          },
        },
      },
    ],
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: 10,
        formatter: (value: number) => {
          if (!value) return '-';
          return this.utilityService.pipeNumber(value);
        },
      },
    },
    grid: {
      containLabel: true,
      left: 5,
      top: '10%',
      width: '93%', // Set the width of the chart within the grid
      height: '90%', // Set the height of the chart within the grid
    },
    series: [
      {
        data: [],
        type: 'line',
        symbolSize: 8,
        symbol: 'circle',
        lineStyle: {
          type: 'dotted',
          dashOffset: 2,
        },
      },
    ],
  };

  constructor(
    public utilityService: UtilityService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.options2 = JSON.parse(JSON.stringify(this.options));
    this.setChartPlotCount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']) {
      this.chartData = changes['chartData'].currentValue;

      this.formatChartData();
      this.setUpChart2();
      this.updateChartData(1);
    }
  }

  ngOnInit(): void {
    this.updateChartData(this.currentPage);
  }

  setUpChart2() {
    const yAxisSeriesData = this.setup.yAxis.reduce(
      (acc: LineSeriesOption[], yAxis) => {
        const seriesOption: LineSeriesOption = {
          type: 'line',
          symbolSize: 8,
          symbol: 'circle',
          lineStyle: {
            type: 'dotted',
            dashOffset: 20,
          },
          data: [],
          name: yAxis.label,
        };

        this.defaultChartData.forEach((data) => {
          seriesOption.data.push(data[yAxis.value]);
        });

        acc.push(seriesOption);
        return acc;
      },
      []
    );

    const xAxisSeriesData = this.defaultChartData.map((data) => {
      return data[this.setup.xAxis.value];
    });

    this.options2.series = yAxisSeriesData;

    // Update x-axis data
    this.options2.xAxis[0].data = xAxisSeriesData;
  }

  private setChartPlotCount() {
    this.breakpointObserver.observe('(min-width: 900px)').subscribe((res) => {
      if (res.matches) {
        this.itemsPerPage = 7;
      } else {
        this.itemsPerPage = 4;
      }
      this.updateChartData(this.currentPage);
    });
  }

  formatChartData() {
    if (!this.chartData || !this.chartData.length || !this.setup) return;

    this.averageKey =
      this.setup.yAxis.find((item) => item.useAverage)?.value ||
      this.setup.yAxis[0].value;
    this.scale = this.utilityService.getScale(this.chartData, this.averageKey);
    this.emitScale.emit(this.scale.unit);
    this.chartData = this.chartData.map((item: any) => {
      const val = {};
      this.setup.yAxis.map((yAxis) => {
        val[yAxis.value] = item[yAxis.value] / this.scale.value;
      });
      return { ...item, ...val };
    });
    this.defaultChartData = this.chartData;
  }

  updateChartData(currentPage: number): void {
    if (!this.setup) return;
    this.currentPage = currentPage;
    const startIndex = (currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Update series data and x-axis labels
    if (!Array.isArray(this.options.series)) {
      this.options.series = [];
    }

    const yAxisSeriesData = this.setup.yAxis.reduce(
      (acc: LineSeriesOption[], yAxis) => {
        const seriesOption: LineSeriesOption = {
          type: 'line',
          symbolSize: 8,
          symbol: 'circle',
          lineStyle: {
            type: 'dotted',
            dashOffset: 20,
          },
          data: [],
          name: yAxis.label,
        };

        this.chartData.slice(startIndex, endIndex).forEach((data) => {
          seriesOption.data.push(data[yAxis.value]);
        });

        acc.push(seriesOption);
        return acc;
      },
      []
    );

    const xAxisSeriesData = this.chartData
      .slice(startIndex, endIndex)
      .map((data) => {
        return data[this.setup.xAxis.value];
      });

    this.emitCurrentChartData.emit(
      JSON.parse(
        JSON.stringify(this.chartData.slice(startIndex, endIndex))
      ).map((data) => {
        this.setup.yAxis.forEach((val) => {
          data[val.value] = data[val.value] * this.scale.value;
        });
        return data;
      })
    );

    
    this.options.series = yAxisSeriesData;

    // Update x-axis data
    this.options.xAxis[0].data = xAxisSeriesData;

    // Update chart colors
    this.options.color = this.setup.yAxis.map((val) => val.color);

    // Calculate the total number of pages
    this.totalPages = Math.ceil(this.chartData.length / this.itemsPerPage);

    this.updateChartOptions();
  }

  updateChartOptions(): void {
    setTimeout(() => {
    if (this.echartsElement && this.echartsElement.nativeElement) {
        const echartsInstance = echarts.getInstanceByDom(this.echartsElement.nativeElement);
        const echarts2Instance: any = echarts.getInstanceByDom(
          this.echarts2Element.nativeElement
        );

        echarts2Instance.setOption(this.options2);

        const yAxisOpt =
          echarts2Instance._model._componentsMap.get('yAxis')[0].axis;


        this.options.yAxis = {
          ...this.options.yAxis,
          min: 0,
          max: yAxisOpt.scale._extent[1] < 8 ? 8 : yAxisOpt.scale._extent[1],
        };

        echartsInstance.setOption(this.options);
      }
    }, 50);
  }
}
