import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/utils/utility.service';

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.css']
})
export class CircleChartComponent {
  @Input() totalAmount: number;
  @Input() imageName: string;
  @Input() classNames: string;
  @Input() type: 'currency' | 'number' | 'percentile' = 'currency';
  @Input() width: string = '50px';
  @Input() height: string = '50px';

  constructor(public utilityService: UtilityService) { }

}
