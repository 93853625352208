import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appStrictNumbers]'
})
export class StrictNumbersDirective {

  private regEx: RegExp = new RegExp('^[0-9]*$')
  private specialKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab']

  constructor(private elementRef: ElementRef) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if(this.specialKeys.includes(event.key)) return

    const inputValue = this.elementRef.nativeElement.value.concat(event.key)
    if(inputValue && !String(inputValue).match(this.regEx)) {
      event.preventDefault()
    }
    return
  } 

  // Copy and Paste Action
  @HostListener('paste', ['$event']) onPaste(event) {
    const clipboardData = (event.originalEvent || event).clipboardData.getData('text/plain')
    if(clipboardData && !this.regEx.test(clipboardData)) {
      event.preventDefault()
    }
    return
  } 

}
