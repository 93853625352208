import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getUserLanguages } from 'src/app/shared/dto/common';
import { KeyValueType } from 'src/app/shared/models/common';
import { NotificationService } from 'src/app/utils/notification.service';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public passwordVisible = false;
  
  username = '';
  password = '';
  language = 'en';
  res: any = {};
  email = '';
  baseUrl: string = environment.baseUrl;
  forgotPasswordObject: any = {};
  currentPassword = '';
  newPassword = '';
  confirmNewPassword = '';
  public resetPasswordType:'onExpiry' | 'onFirstTimeLogin' = 'onFirstTimeLogin'
  public changePasswordForm: FormGroup;
  public forgotPasswordForm: FormGroup;
  public languages: KeyValueType[] = getUserLanguages();
  bgImages = [
    '../../assets/images/system-images/login-images/1.png',
    '../../assets/images/system-images/login-images/2.png',
    '../../assets/images/system-images/login-images/3.png',
    '../../assets/images/system-images/login-images/4.png',
    '../../assets/images/system-images/login-images/5.png',
    '../../assets/images/system-images/login-images/6.png',
    '../../assets/images/system-images/login-images/7.png',
    '../../assets/images/system-images/login-images/8.png',
    '../../assets/images/system-images/login-images/9.png',
    '../../assets/images/system-images/login-images/10.png',
    '../../assets/images/system-images/login-images/11.png',
    '../../assets/images/system-images/login-images/12.png',
    '../../assets/images/system-images/login-images/13.png',
    '../../assets/images/system-images/login-images/14.png',
    '../../assets/images/system-images/login-images/15.svg',
    '../../assets/images/system-images/login-images/16.svg',
    '../../assets/images/system-images/login-images/17.svg',
  ];
  random = Math.floor(Math.random() * this.bgImages.length);
  public year = new Date().getFullYear()


  

  constructor(
    private authService: AuthService,
    private toast: NotificationService,
    private formBuilder: FormBuilder,
    private validatorService: WizerValidatorService,
    private zone: NgZone,
  ) {
    this.initChangePasswordForm();
  }
  ngOnInit(): void {
    this.language = localStorage.getItem('locale');
  }

  changeLocale(locale: string) {
    // Store the user's preferred locale in localStorage
    localStorage.setItem('locale', locale);

    // Reload the page to apply the new locale
    location.reload();
  }

  login(): void {
    // localStorage.setItem('userPerm', JSON.stringify(module));
    // localStorage.setItem('token', JSON.stringify(module));
    // location.href = '/';
    // return;
    // $('.btn-login').attr('disabled', 'disabled').html('Please wait...');

    this.authService.login(this.username, this.password).subscribe({
      next: (res) => {
        if (!res.successful) return;

        // this.wsService.fetchWebSocketData();
        const { firstTimeLogin } = res?.data?.user_info;
        if (firstTimeLogin) {
          this.toast.info(`Password change required`);
          this.changePasswordForm.get('email').setValue(this.username);
          this.changePasswordForm.get('oldPassword').setValue(this.password);
          this.changePasswordForm.get('oldPassword').disable();
          this.showForm('form-change-password-container');
          this.changePasswordForm.markAllAsTouched();
          return;
        }
        
        localStorage.setItem('locale', this.language);
        this.toast.success(res.message);
        // this.saveDataList();
        setTimeout(() => {
          this.zone.run(()=> {
            // this.router.navigate(['/dashboard']);
            location.href = '/';
          })
        }, 1000);
      },
      error: (res: HttpErrorResponse) => {
        if(res.status === 403 && res.error.code === 20) {
          this.resetPasswordType = 'onExpiry'
          this.toast.info(`Password change required`);
          this.changePasswordForm.get('email').setValue(this.username);
          this.showForm('form-change-password-container');
          this.changePasswordForm.markAllAsTouched();
          return;
        }
        if(res.status === 401) {
          this.toast.error(res.error.message);
        }else {
          this.toast.error(res.error.message);
        }
        $('.btn-login').removeAttr('disabled').html('Sign in');
        // this.toast.error(err.error.message);
      },
    });
  }
  
  navigateToForgotPassword(){
    this.showForm('form-forgot-password-container');
    this.forgotPasswordForm.get('email').setValue(this.username)
  }
  forgotPassword(): void {
    if (!this.forgotPasswordForm.valid) {
      this.validatorService.CheckMessagesAndNotify(
        this.forgotPasswordForm.controls
      );
      return;
    }
    const { email } = this.forgotPasswordForm.value;
    this.authService.forgotPassword(email).subscribe({
      next: (res) => {
        if (!res.successful) return;
        this.toast.success(res.message);

        // this.toast.success(
        //   'Password reset successful, Kindly check your email'
        // );
        this.username = email;
        this.showForm('form-login-container');
      },
      error: (err) => {
        this.toast.error(err.error.message);
      },
    });
    // this.forgotPasswordObject.url = window.location.origin;
  }
  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      this.validatorService.CheckMessagesAndNotify(
        this.changePasswordForm.controls
      );
      return;
    }
    if(this.resetPasswordType === 'onExpiry'){
      this.authService
      .resetPasswordOnExpiry(this.changePasswordForm.getRawValue())
      .subscribe((res) => {
        if (!res.successful) return;
        this.toast.success(res.message);

        this.showForm('form-login-container');
        this.resetPasswordType = 'onFirstTimeLogin'
      });
      return;
    }
    this.authService
      .resetPassword(this.changePasswordForm.getRawValue())
      .subscribe((res) => {
        if (!res.successful) return;
        this.toast.success(res.message);
        // this.toast.success('Password updated successfully, Kindly login');
        this.showForm('form-login-container');
      });
    // slide in login
  }

  private initChangePasswordForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)]],
    });
    this.changePasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      oldPassword: ['', [Validators.required]],
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}/),
          this.validatorService.MatchValidator(
            'confirmNewPassword',
            true,
            'Password'
          ),
        ],
      ],
      confirmNewPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}/),
          this.validatorService.MatchValidator(
            'newPassword',
            false,
            'Password'
          ),
        ],
      ],
    });
  }

  showForm(formID: string): void {
    // login form
    if (formID === 'form-login-container') {
      $('.forms-container section.form-forgot-password-container').slideUp(500);
      $('.forms-container section.form-change-password-container').slideUp(500);
      $('.forms-container section.form-login-container').slideDown(500);
      // forgot password form
    } else if (formID === 'form-forgot-password-container') {
      $('.forms-container section').slideUp(500);
      $('.forms-container section.form-forgot-password-container').slideDown(
        500
      );
      // change password form
    } else if (formID === 'form-change-password-container') {
      $('.forms-container section').slideUp(500);
      $('.forms-container section.form-change-password-container').slideDown(
        500
      );
    }
  }

  setMyStyles() {
    const styles = {
      'background-image': 'url(' + this.bgImages[this.random] + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
    };
    return styles;
  }
}
