import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-loader-indicator',
  templateUrl: './chart-loader-indicator.component.html',
  styleUrls: ['./chart-loader-indicator.component.css']
})
export class ChartLoaderIndicatorComponent {

  constructor() { }

}
