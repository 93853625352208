<app-search-select
    [label]="depositLabel"
    [showDefaultListValue]="showDefaultListValue"
    [defaultSelected]="defaultSelected"
    (emitSelectedVal)="emitSelected($event)"
    [control]="control"
    [disabled]="disabled"
    [type]="type"
    [fetchedList]="depositProductData"
    [endpoint]="endpoint"
></app-search-select>