import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  baseUrl: string = environment.baseUrl + environment.coreUrl;

  public searchGroupEndpoint = `${this.baseUrl}groups?page=0&pageSize=500&state=approved&sortField=last_update_time&sortOrder=DESC&filter=`
  public searchGroupByLoanOfficersEndpoint = (id)=>`${this.baseUrl}groups?loan-officer-id=${id}&page=0&pageSize=500&state=APPROVED&sort-field=lastUpdateTime&sort-order=DESC&filter=`


  constructor(private http: HttpClient) { }

  public postGroup(data: Object){
    return this.http.post<any>(this.baseUrl + 'groups', data);
  }

  public getAllGroup(req:any): Observable<any>{
    return this.http.get(this.baseUrl + `groups?${decodeURIComponent(req)}`);
  }

  public getGroup(id: number): Observable<any> {
    return this.http.get(this.baseUrl + 'groups/' + id);
  }

  public searchGroup(param: string): Observable<any> {
    return this.http.get(this.searchGroupEndpoint + param);
  }

  public updateGroup(data: Object) {
    return this.http.put<any>(this.baseUrl + 'groups', data);
  }

  public toggleState(data:any): Observable<any>{
    return this.http.post<any>(this.baseUrl + 'groups/toggle', data);
  }

  public getGroupClients(id:number, req:any): Observable<any>{
    return this.http.get(this.baseUrl + `groups/${id}/clients?${req}`);
  }
  public getGroupClientsFirst500(id:number): Observable<any>{
    return this.http.get(this.baseUrl + `groups/${id}/clients?page=0&pageSize=500`);
  }

  public postGroupDocument(data: Object){
    return this.http.post<any>(this.baseUrl + 'groups/documents', data);
  }

  public getGroupDocument(req:any): Observable<any> {
    return this.http.get(this.baseUrl + `groups/documents?${req}`);
  }

  public getGroupByBranch(id:number): Observable<any>{
    return this.http.get(this.baseUrl + `groups/groupsByBranch/${id}`);
  }

  public getGroupByLoanOfficer(loanOfficerId: number) {
    return this.http.get(this.baseUrl + `groups?loan-officer-id=${loanOfficerId}&page=0&pageSize=500&state=APPROVED&sort-field=lastUpdateTime&sort-order=DESC&filter=`);
  }

}
