import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.css']
})
export class InputModalComponent implements OnInit {

  public title = "Append Name"
  public inputTitle = "Name"

  public proceedVal ={
    name: "Submit",
    class: "btn bg-info w-100px"
  }
  public cancelVal = {name:"Cancel",
  class: "btn btn-warn w-100px"
}

  public form: FormGroup;


  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<InputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public initForm(){
    this.form = this.fb.group({
      name: new FormControl("", Validators.required)
    })
  }

  public cancel(){
    this.dialogRef.close();
  }

  public onSubmit(){
    if(!this.form.valid) return
    this.dialogRef.close(this.form.value)
  }

  ngOnInit(): void {
    this.initForm()
    // refer to instantiation(default value) to determine its data type
    if(this.data && this.data.title) this.title =  this.data.title;
    if(this.data && this.data.inputTitle) this.inputTitle =  this.data.inputTitle;
    if(this.data && this.data.proceedVal) this.proceedVal =  this.data.proceedVal;
    if(this.data && this.data.cancelVal) this.cancelVal =  this.data.cancelVal;
    if(this.data && this.data.defaultValue) {
      this.form.get('name').setValue(this.data.defaultValue)
    }

    

    
  }
}
