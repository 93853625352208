import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, catchError, mergeMap, of, retryWhen, throwError } from 'rxjs';
import { AreaIndexedDBService } from 'src/app/indexDB/area.service';
import { AreasService } from 'src/app/pages/settings/services/areas.service';

@Component({
  selector: 'app-area-search-select',
  templateUrl: './area-search-select.component.html',
  styleUrls: ['./area-search-select.component.css']
})
export class AreaSearchSelectComponent implements OnInit, OnDestroy  {

  @Input() showDefaultListValue:boolean = true;
  @Input() defaultSelected:any;
  @Input() control: FormControl | string;
  @Input() disabled: boolean = false
  @Input() type: 'client' | 'server' = 'client';
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public areaLabel: string = $localize`:@@area:Area`;
  public areasData: { id: number, name: string }[] = [];
  public endpoint: string = '';
  public storedArrayString = localStorage.getItem('indexDBFetched');
  public storedArray = this.storedArrayString ? JSON.parse(this.storedArrayString) : [];
  private subscription: Subscription;

  constructor(
    private areaIDBService: AreaIndexedDBService,
    private areaService: AreasService
  ) { }

  ngOnInit(): void {
    this.getAllAreas();
    this.endpoint = this.areaService.searchAreaEndpoint;
    if (!this.storedArray.includes('areaList')) {
      this.type = 'server';
    }
  }

  emitSelected(value:{id:any, name:string}) {
    this.emitSelectedVal.emit(value);
  }

  getAllAreas() {
    if (this.subscription) {
      // Unsubscribe from the previous subscription
      this.subscription.unsubscribe();
    }
    this.subscription = this.areaIDBService.getStoredData().subscribe({
      next: (areas) => {
        this.areasData = areas;

        // Check if the areasData is empty after fetching
        if (areas.length === 0) {
          // It's still empty, trigger a refetch
          this.getAllAreas();
        }
      },
      error: (error) => { 

      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
