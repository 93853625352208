import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NotificationService } from 'src/app/utils/notification.service';


@Component({
  selector: 'app-new-update-modal',
  templateUrl: './new-update-modal.component.html',
  styleUrls: ['./new-update-modal.component.css'],
})
export class NewUpdateModalComponent {
  constructor(private readonly updates: SwUpdate, private toast: NotificationService) {
    // reload when there is an update from service worker
    // updates.activateUpdate().then(() => document.location.reload())
    this.updates.versionUpdates.subscribe((event) => {
      let that = this;
      if (event) {
        this.toast.info('New update is available. Updating in 5 seconds...');
        setTimeout(function () {
          that.downloadUpdate();
        }, 5000);
        // $('#newUpdateModal').modal('show');
      }
    });
    // this._user.setUserLoggedIn(false);
  }

  downloadUpdate() {
    this.updates.activateUpdate().then(() => {
      if (localStorage.getItem('refreshed') === null) {
        localStorage['refreshed'] = true;
        window.location.reload();
      } else {
        localStorage.removeItem('refreshed');
      }
    });
  }

}
