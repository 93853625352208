<app-search-select
    [label]="productLabel"
    [showDefaultListValue]="showDefaultListValue"
    [defaultSelected]="defaultSelected"
    (emitSelectedVal)="emitSelected($event)"
    [control]="control"
    [disabled]="disabled"
    [type]="type"
    [fetchedList]="loanProductData"
    [endpoint]="endpoint"
></app-search-select>