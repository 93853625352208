<div>

    <div class="row">
        <div class="col-lg-3 col-md-4">
            <div class="flex flex-wrap justify-content-between">
                <p>Start Date: </p>
                <p><strong> </strong></p>
            </div>
        </div>

        <div class="col-lg-3 col-md-4">
            <div class="flex flex-wrap justify-content-between">
                <p>End Date: </p>
                <p><strong> </strong></p>
            </div>
        </div>

        <div class="col-lg-3 col-md-4">
            <div class="flex flex-wrap justify-content-between">
                <p>Area: </p>
                <p><strong> </strong></p>
            </div>
        </div>

        <div class="col-lg-3 col-md-4">
            <div class="flex flex-wrap justify-content-between">
                <p>Branch: </p>
                <p><strong> </strong></p>
            </div>
        </div>
    </div>

    <app-table-ui (sortEmit)="onSort($event)" [data]="tableRecords" [pageProp]="pageProp" [showPagination]="showPagination" (pageSizeToggle)="pageSizeToggle($event)" (PageCtrlToggle)="pageCtrlToggle($event)" [initColumns]="columns">
    </app-table-ui>
</div>