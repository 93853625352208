import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDTypeRes, PaginatedIDTypeRes } from '../models/dependency';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private stateLG = new BehaviorSubject<{ [id: number]: PaginatedIDTypeRes }>(
    {}
  );
  private states = new BehaviorSubject<IDTypeRes | null>(null);

  baseUrl: string = environment.baseUrl + environment.coreUrl;

  constructor(private http: HttpClient, private dataService: DataService) {}

  private addStateLG(id, lgRes) {
    const stateLG = { ...this.stateLG.value };
    stateLG[id] = lgRes;
    this.stateLG.next(stateLG);
  }

  /* states */
  public getState(): Observable<IDTypeRes> {
    if(this.states.value) return of(this.states.value);
    return this.http.get<IDTypeRes>(this.baseUrl + 'states').pipe(tap(res=>{
      this.states.next(res);
    }));
  }
  public getStateById(id: number): Observable<any> {
    return this.http.get(this.baseUrl + `states/${id}`);
  }

  /* lgas*/
  public getLga(id: number): Observable<PaginatedIDTypeRes> {
    if (this.stateLG.value[id]) return of(this.stateLG.value[id]);
    return this.http
      .get<PaginatedIDTypeRes>(
        this.baseUrl + `lga?stateId=${id}&sort-order=ASC`
      )
      .pipe(
        tap((res) => {
          this.addStateLG(id, res);
        })
      );
  }
  public getLgaById(id: number): Observable<IDTypeRes> {
    return this.http.get<IDTypeRes>(this.baseUrl + `lga/${id}`);
  }
}
