<div class="modal fade" id="notificationModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered instruction-modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{modalNotifications.name}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="notification-mother">
            <div *ngFor="let message of modalNotifications.messages" class="notification-content">
              <div class="circle"></div>
              <p class="date">{{message.date | date:'d MMM. y, h:mm a'}}</p>
              <p [innerHTML]="message.message" class="message"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>