import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router) {}
  
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const token = localStorage.getItem('token');
    if (token && segments[0].path === 'login') {
      this.router.navigate(['/']);
      return true
    }  
    if (segments[0].path === '/login' || segments[0].path === 'reset-password') return true;
    if (!token && segments[0].path === '/login') {

      this.router.navigate(['/login']);
      return false
    }
    
    return true;
  }  
}
