import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/utils/notification.service';
import { PermissionService } from 'src/app/utils/permission.service';
import { Permissions } from '../dto/permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate, CanLoad {

  constructor(
    private router: Router,
    private permissionService: PermissionService,
    private notification: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> { 
 
    return this.checkPermission(route)
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {


    return this.checkPermission(route)
  }  

  private checkPermission(route: Route | ActivatedRouteSnapshot) {

    const hasPermission = this.permissionService.hasPermissionList(route.data['perms']);

    if(!hasPermission) {
      this.notification.warn('You do not have the permission for this action');
      // this.router.navigate(['/']);
      return hasPermission
    }

    return hasPermission
  }

  
}
