import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BranchIndexedDBService } from 'src/app/indexDB/branch.service';
import { AreasService } from 'src/app/pages/settings/services/areas.service';
import { BranchService } from 'src/app/pages/settings/services/branch.service';

@Component({
  selector: 'app-branch-search-select',
  templateUrl: './branch-search-select.component.html',
  styleUrls: ['./branch-search-select.component.css']
})
export class BranchSearchSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() areaId:number = 0;
  @Input() showDefaultListValue:boolean = true;
  @Input() defaultSelected:any;
  @Input() control: FormControl | string;
  @Input() disabled: boolean = false
  @Input() type: 'client' | 'server' = 'client';
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public branchLabel: string = $localize`:@@branch:Branch`;
  public endpoint: string = '';
  public branchesData: { id: number, name: string }[] = [];
  public storedArrayString = localStorage.getItem('indexDBFetched');
  public storedArray = this.storedArrayString ? JSON.parse(this.storedArrayString) : [];
  private subscription: Subscription;
  
  constructor(
    private branchIDBService: BranchIndexedDBService,
    private areaService: AreasService,
    private branchService: BranchService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['areaId']) {
      this.areaId = changes['areaId'].currentValue;
      if(this.areaId === 0) {
        this.getAllBranches();
        this.endpoint = this.branchService.searchBranchEndpoint;
        return
      }
      this.getBranchesByAreaId(this.areaId);
      this.endpoint = this.areaService.searchBranchByAreaEndpoint(this.areaId);
      return 
    }
  }

  ngOnInit(): void {
    this.getAllBranches();
    this.endpoint = this.branchService.searchBranchEndpoint;
    if (!this.storedArray.includes('branchList')) {
      this.type = 'server';
    }
  }

  emitSelected(value:{id:any, name:string}) {
    this.emitSelectedVal.emit(value);
  }

  getAllBranches(){
    if (this.subscription) {
      // Unsubscribe from the previous subscription
      this.subscription.unsubscribe();
    }
    this.subscription = this.branchIDBService.getStoredData().subscribe({
      next: (branches) => { 
        this.branchesData = branches;
        // Check if the branchesData is empty after fetching
        if (branches.length === 0) {
          // It's still empty, trigger a refetch
          this.getAllBranches();
        }
       },
      error: (error) => { console.error('Error retrieving branches:', error); }
    });
  }

  getBranchesByAreaId(areaId: number){
    this.branchIDBService.getStoredBranchesByAreaId(areaId).subscribe({
      next: (branches) => { this.branchesData = branches; },
      error: (error) => { console.error('Error retrieving branches:', error); }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
