import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  DashboardLevelType,
  TogglePermissionType,
} from 'src/app/shared/models/configurations';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  baseCoreUrl: string = environment.baseUrl + environment.coreUrl;
  baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {}

  public createRole(role: any) {
    return this.http.post(`${this.baseCoreUrl}roles`, role);
  }

  public getRoles(params): Observable<any> {
    return this.http.get(`${this.baseCoreUrl}roles/query?${params}`);
  }

  public getRole(roleId: number): Observable<any> {
    return this.http.get(`${this.baseCoreUrl}roles/${roleId}`);
  }

  public getRolePermissions(roleId: number): Observable<any> {
    return this.http.get(`${this.baseCoreUrl}roles/${roleId}/permissions`);
  }

  public togglePermissions(toggleData: TogglePermissionType) {
    return this.http.post(`${this.baseCoreUrl}roles/setpermission`, toggleData);
  }

  public updateDashboardLevel(payload: DashboardLevelType) {
    return this.http.post(
      `${this.baseCoreUrl}roles/update-dashboard-level`,
      payload
    );
  }

  public initiateApprovalLimit(roleId: number[], approvalLimit: number) {
    return this.http.post(
      `${this.baseCoreUrl}roles/initiate-update-approval-limit`,
      {
        roleId,
        approvalLimit,
      }
    );
  }
  public initiateBackdatingLimit(roleId: number[], limitInDays: number) {
    return this.http.post(
      `${this.baseCoreUrl}roles/initiate-posting-backdate-limit`,
      {
        roleId,
        limitInDays,
      }
    );
  }


  public approveApprovalLimit(roleId: number[]) {
    return this.http.post(
      `${this.baseCoreUrl}roles/approve-update-approval-limit`,
      {
        roleId,
      }
    );
  }

  public approveBackdatingLimit(roleId: number[]) {
    return this.http.post(
      `${this.baseCoreUrl}roles/approve-posting-backdate-limit`,
      {
        roleId,
      }
    );
  }

  public rejectApprovalLimit(roleId: number[]) {
    return this.http.post(
      `${this.baseCoreUrl}roles/decline-update-approval-limit`,
      {
        roleId,
      }
    );
  }
  public rejectBackdatingLimit(roleId: number[]) {
    return this.http.post(
      `${this.baseCoreUrl}roles/decline-posting-backdate-limit`,
      {
        roleId,
      }
    );
  }
}
