import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class GlAccountService {

    baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) { }

    public postGlAccount(data: Object) {
        return this.http.post<any>(this.baseUrl + 'api/v2/internal/accounting/gl-accounts', data);
    }

    public getAllGlAccounts() {
        return this.http.get<any>(this.baseUrl + 'api/v2/internal/accounting/gl-accounts/all');
    }

    public getGlAccount(id: number) {
        return this.http.get<any>(this.baseUrl + 'api/v2/internal/accounting/gl-accounts/' + id);
    }

    public getMappedGlAccounts() {
        return this.http.get<any>(this.baseUrl + 'api/v2/internal/accounting/gl-accounts/map');
    }

    public getGlAccountHeaders() {
        return this.http.get<any>(this.baseUrl + 'api/v2/internal/accounting/gl-accounts/headers');
    }

    public getGlAccounts(req: any): Observable<any> {
      return this.http.get(this.baseUrl + 'api/v2/internal/accounting/gl-accounts', req);
    //   return this.dataService.getList(this.baseUrl + 'api/v2/internal/accounting/gl-accounts', req);
    }

}
