import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UtilityService } from 'src/app/utils/utility.service';
import { WizerValidatorService } from 'src/app/utils/wizer.validators.service';
import {
  ActionButtonType,
  KeyValueType,
  ReportInputTypes,
  reportSummaryType,
} from '../../models/common';

@Component({
  selector: 'app-reports-board',
  templateUrl: './reports-board.component.html',
  styleUrls: ['./reports-board.component.css'],
})
export class ReportsBoardComponent implements OnInit {
  @Input() showDepositDefaultListValue: boolean = true;
  @Input() showDepositClientDefaultListValue: boolean = true;
  @Input() inputsToShow: Array<ReportInputTypes>;
  @Input() savedData;
  @Input() showTableActionFilter: boolean = true;
  @Input() showSummaryData: boolean = true;
  @Input() summaryData: reportSummaryType[];
  @Input() clientData: { id: number; first_name: string; last_name: string }[] =
    [];
  @Input() depositClientData: { id: number; first_name: string; last_name: string }[] =
    [];
  @Input() payload = [];
  @Input() type: 'client' | 'server' = 'client';
  @Input() minDate = new Date('01/01/2016');
  @Input() maxDate = new Date();
  @Input() title: string;
  @Input() activeState: string;
  @Input() initialValue: string = '';
  @Input() states: any[];
  @Input() actionButtons: ActionButtonType[];
  @Input() showActionBar2: boolean = true;
  @Input() disableActionButtons: boolean = true;
  @Input() showSearchInput: boolean = true;
  @Input() showDownloadButton: boolean = true;
  @Input() runText: string = $localize`:@@fetch_records:Fetch Records`;
  @Input() runIconPath: string = 'assets/icons/download.svg';
  @Input() runMenu: KeyValueType[] = [
    { key: 'Download As Excel', value: 'csv' },
  ];
  @Input() showRunButton: boolean = false;
  @Input() showNumOfRowSelected: boolean = false;
  @Input() numOfRowSelected: number = 0;
  @Input() totalAmtOfRowSelected: number = 0;

  @Output() searchEmit: EventEmitter<any> = new EventEmitter();
  @Output() stateEmit: EventEmitter<any> = new EventEmitter();
  @Output() actionEmit: EventEmitter<any> = new EventEmitter();
  @Output() runMainMenu: EventEmitter<null> = new EventEmitter();
  @Output() runMenuChange: EventEmitter<{val:string, formValues:any}> = new EventEmitter();

  @Output() setSelectedFilter: EventEmitter<any> = new EventEmitter();
  @Output() setInputedFilter: EventEmitter<any> = new EventEmitter();
  @Output() runReportEmit: EventEmitter<any> = new EventEmitter();
  @Output() emitChange: EventEmitter<any> = new EventEmitter();

  public reportsForm: FormGroup;
  public reportInputTypes = ReportInputTypes;
  public selectedAreaId: number = 0;
  public selectedBranchId: number = 0;
  public selectedLoanOfficerId: number = 0;
  public defaultAll = {
    ['name']: $localize`:@@all:All`,
    ['id']: 0,
  };
  public startDateRange = {
    min: new Date('01/01/2016'),
    max: new Date(),
  };
  public endDateRange = {
    min: new Date(this.startDateRange.max),
    max: new Date(),
  };

  public reportDays = [
    { key: 'Today', value: 'TODAY' },
    { key: '1 Day Ago', value: '1_DAY_AGO' },
    { key: '2 Days Ago', value: '2_DAYS_AGO' },
    { key: '3 Days Ago', value: '3_DAYS_AGO' },
    { key: '4 Days Ago', value: '4_DAYS_AGO' },
    { key: '5 Days Ago', value: '5_DAYS_AGO' },
    { key: '6 Days Ago', value: '6_DAYS_AGO' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilityService,
    private validatorService: WizerValidatorService
  ) {
    this.reportsForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.initForm();
    this.loadSavedData();
    this.dateListner();
  }

  initForm() {
    this.inputsToShow.forEach((input) => {
      this.reportsForm.addControl(input, new FormControl());
      //this.reportsForm.controls[input].addValidators(Validators.required)
    });
  }

  loadSavedData(): void {
    this.reportsForm.patchValue(this.savedData);
  }

  runReport() {
    if (!this.reportsForm.valid) {
      this.validatorService.CheckMessagesAndNotify(this.reportsForm.controls);
      return;
    }
    const payload = {};
    this.inputsToShow.forEach((input) => {
      if (this.hasDateTypes(input)) {
        payload[input] = this.utils.formatDate_custom(
          this.reportsForm.value[input]
        );
        return;
      }
      payload[input] = this.reportsForm.value[input];
    });

    this.runReportEmit.emit(payload);
  }

  public emitInputedVal(key: string, value: string) {
    this.setInputedFilter.emit({ key, value });
  }

  public emitSelectedVal(
    key: ReportInputTypes,
    val: { id: any; name: string }
  ) {
    if (!val) val = this.defaultAll;
    switch (key) {
      case ReportInputTypes.AREA:
        this.selectedAreaId = val.id;
        if (val.id === 0) {
          this.setSelectedFilter.emit({
            type: ReportInputTypes.BRANCH,
            value: this.selectedBranchId,
          });
        } else {
          //set form value to All
          this.reportsForm.get(ReportInputTypes.BRANCH)?.setValue(0);
          this.reportsForm.get(ReportInputTypes.LOAN_OFFICER)?.setValue(0);

          //set behaviour subject to hold the current values
          this.setSelectedFilter.emit({
            type: ReportInputTypes.BRANCH,
            value: this.defaultAll,
          });
          this.setSelectedFilter.emit({
            type: ReportInputTypes.LOAN_OFFICER,
            value: this.defaultAll,
          });
          this.setSelectedFilter.emit({
            type: ReportInputTypes.GROUP,
            value: this.defaultAll,
          });
        }
        this.setSelectedFilter.emit({
          type: ReportInputTypes.AREA,
          value: val,
        });
        break;
      case ReportInputTypes.BRANCH:
        this.selectedBranchId = val.id;
        if (val.id !== 0) {
          this.reportsForm.get(ReportInputTypes.LOAN_OFFICER)?.setValue(0);
          this.setSelectedFilter.emit({
            type: ReportInputTypes.LOAN_OFFICER,
            value: this.defaultAll,
          });
        }
        this.setSelectedFilter.emit({
          type: ReportInputTypes.BRANCH,
          value: val,
        });
        break;
      case ReportInputTypes.GROUP:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.GROUP,
          value: val,
        });
        break;
      case ReportInputTypes.LOAN_OFFICER:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.LOAN_OFFICER,
          value: val,
        });
        break;
      case ReportInputTypes.LOAN_PRODUCT:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.LOAN_PRODUCT,
          value: val,
        });
        break;
      case ReportInputTypes.DEPOSIT_PRODUCT:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.DEPOSIT_PRODUCT,
          value: val,
        });
        break;
      case ReportInputTypes.LOAN_STATUS:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.LOAN_STATUS,
          value: val,
        });
        break;
      case ReportInputTypes.CLIENT:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.CLIENT,
          value: val,
        });
        break;
      case ReportInputTypes.DEPOSIT_CLIENT:
        this.setSelectedFilter.emit({
          type: ReportInputTypes.DEPOSIT_CLIENT,
          value: val,
        });
    }
    this.emitChange.emit({ key, val });
  }

  byInputType(index: number, value) {
    return value;
  }

  get getAreaCtrl() {
    return this.reportsForm.get(ReportInputTypes.AREA) as FormControl;
  }

  get getBranchCtrl() {
    return this.reportsForm.get(ReportInputTypes.BRANCH) as FormControl;
  }

  get getLoanOfficerCtrl() {
    return this.reportsForm.get(ReportInputTypes.LOAN_OFFICER) as FormControl;
  }

  get getGroupCtrl() {
    return this.reportsForm.get(ReportInputTypes.GROUP) as FormControl;
  }

  get getLimitCtrl() {
    return this.reportsForm.get(ReportInputTypes.LIMIT) as FormControl;
  }

  get getProductCtrl() {
    return this.reportsForm.get(ReportInputTypes.LOAN_PRODUCT) as FormControl;
  }

  get getDepositProductCtrl() {
    return this.reportsForm.get(
      ReportInputTypes.DEPOSIT_PRODUCT
    ) as FormControl;
  }

  get getClientCtrl() {
    return this.reportsForm.get(ReportInputTypes.CLIENT) as FormControl;
  }
  get getDepositClientCtrl() {
    return this.reportsForm.get(ReportInputTypes.DEPOSIT_CLIENT) as FormControl;
  }

  get getStatusCtrl() {
    return this.reportsForm.get(ReportInputTypes.LOAN_STATUS) as FormControl;
  }

  public dateListner() {
    if (this.hasStartAndEndDate(this.inputsToShow)) {
      this.startDateRange.max = new Date(
        this.reportsForm.get(ReportInputTypes.END_DATE).value
      );
      this.endDateRange.min = new Date(
        this.reportsForm.get(ReportInputTypes.START_DATE).value
      );

      this.setSelectedFilter.emit({
        type: ReportInputTypes.START_DATE,
        value: this.endDateRange.min,
      });
      this.setSelectedFilter.emit({
        type: ReportInputTypes.END_DATE,
        value: this.startDateRange.max,
      });
    } else {
      this.startDateRange.max = new Date();
      this.endDateRange.min = new Date('01/01/2016');

      if (this.isReportInputTypePresent(ReportInputTypes.START_DATE))
        this.setSelectedFilter.emit({
          type: ReportInputTypes.START_DATE,
          value: new Date(
            this.reportsForm.get(ReportInputTypes.START_DATE).value
          ),
        });
      if (this.isReportInputTypePresent(ReportInputTypes.END_DATE))
        this.setSelectedFilter.emit({
          type: ReportInputTypes.END_DATE,
          value: new Date(
            this.reportsForm.get(ReportInputTypes.END_DATE).value
          ),
        });
    }
  }

  onRunMenu() {
    this.runMainMenu.emit(this.reportsForm.value);
  }

  onRunMenuChange(val: string) {
    this.runMenuChange.emit({val, formValues: this.reportsForm.value});
  }

  onSearch(value: string) {
    this.searchEmit.emit(value);
  }

  onStateChange(value: any) {
    this.stateEmit.emit(value);
  }

  onActionTrigger(val: ActionButtonType['value']) {
    this.actionEmit.emit(val);
  }

  hasDateTypes(input: ReportInputTypes): boolean {
    return (
      input === ReportInputTypes.START_DATE ||
      input === ReportInputTypes.END_DATE ||
      input === ReportInputTypes.INACTIVE_SINCE
    );
  }

  hasStartAndEndDate(inputsArray: ReportInputTypes[]): boolean {
    return (
      inputsArray.includes(ReportInputTypes.START_DATE) &&
      inputsArray.includes(ReportInputTypes.END_DATE)
    );
  }

  isReportInputTypePresent(inputType: ReportInputTypes): boolean {
    return this.inputsToShow.includes(inputType);
  }
}
