import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTitleCase]'
})
export class TitleCaseDirective {

  constructor(private el: ElementRef) {}

  @HostListener('blur') onBlur() {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const inputValue = inputElement.value.trim();
    inputElement.value = this.titleCase(inputValue);
  }

  private titleCase(input: string): string {
    if (!input) {
      return input;
    }

    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

}
