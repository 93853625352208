<div class="flex items-center bg-primary px-1 py-1.5 rounded-lg gap-x-3.5" *ngIf="navButtons?.length > 0">
    <div *ngFor="let btn of navButtons">
        <button [disabled]="btn.navStatus === 'not-active'" [matTooltip]="btn.navStatus === 'not-active'? 'Coming Soon': ''" *hasPermissions="btn.perms" (click)="trigger(btn)" [ngClass]="
        isActive(btn) ? 'bg-white text-primary/95' : 'bg-primary text-white/95'
      " [class]="
        'flex rounded-lg gap-x-3.5 h-[35px] items-center justify-center p-2 '
      " mat-raised-button data-toggle="tooltip" data-placement="left">
      <img
        [ngClass]="isActive(btn) ? 'filter-primary' : ''"
        [src]="btn.icon"
        alt=""
      />
      <span *ngIf="btn.name">{{ btn.name }}</span>
    </button>
    </div>
</div>