import { Component, OnInit } from '@angular/core';
import { Page, InitDisplayedCol } from '../../models/common';
import { DummyDataService } from '../../services/dummyData.service';

@Component({
  selector: 'app-account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: ['./account-documents.component.css']
})
export class AccountDocumentsComponent implements OnInit {

  public pageProp: Page = this.dummyData.dummyPage;
  public showPagination:boolean = true;
  public columns: InitDisplayedCol[] = [
    {
      name: 'Name',
      key: 'name',
      type: 'text',
      value: 'name',
    },
    {
      name: 'Description',
      key: 'description',
      type: 'text',
      value: 'description',
    }
   
  ]

  public payload = this.dummyData.depositTransactionsDummyTableData
  public documents: any[] = []

  constructor(private dummyData: DummyDataService) { }

  ngOnInit(): void {
  }

  
  public pageSizeToggle (size:number){
  }

  public pageCtrlToggle (ctrl:string){
  }

  onSort(value) {
  }

  onSearch(value) {
  }

}
