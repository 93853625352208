import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupService } from 'src/app/pages/client-management/services/group.service';
import { BranchService } from 'src/app/pages/settings/services/branch.service';

@Component({
  selector: 'app-group-search-select',
  templateUrl: './group-search-select.component.html',
  styleUrls: ['./group-search-select.component.css']
})
export class GroupSearchSelectComponent implements OnInit, OnChanges {

  @Input() loanOfficerId:number = 0;
  @Input() branchId:number = 0;
  @Input() showDefaultListValue:boolean = true;
  @Input() disabled:boolean = false
  @Input() defaultSelected:any;
  @Input() control: FormControl | string;
  @Input() type: 'client' | 'server' = 'server';
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public groupLabel: string = $localize`:@@group:Group`;
  @Input() groupsData: { id: number, name: string }[] = [];
  public endpoint: string = '';

  constructor(
    private groupService: GroupService,
    private branchService : BranchService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['loanOfficerId']) {
      this.loanOfficerId = changes['loanOfficerId'].currentValue;
      if(this.loanOfficerId === 0) {
        this.endpoint = this.groupService.searchGroupEndpoint;
        return
      }
      this.endpoint = this.groupService.searchGroupByLoanOfficersEndpoint(this.loanOfficerId);
      return
    }
    if(changes['branchId']) {
      this.loanOfficerId = changes['branchId'].currentValue;
      if(this.branchId === 0) {
        this.endpoint = this.groupService.searchGroupEndpoint;
        return
      }
      this.endpoint = this.branchService.searchGroupByBranchEndpoint(this.branchId);
      return
    }
  }

  ngOnInit(): void {
    this.endpoint = this.groupService.searchGroupEndpoint
  }

  emitSelected(value:{id:any, name:string}) {
    this.emitSelectedVal.emit(value);
  }

}
