<div
  class="h-[480px] chart-wrapper flex flex-col gap-y-2 bg-chart rounded-[5px] overflow-hidden relative"
  [ngClass]="wrapperClass"
  #wrapper
>
  <app-chart-loader-indicator *ngIf="isLoading"></app-chart-loader-indicator>

  <div>
    <div
      class="flex p-4 items-center z-10 justify-between border-b-2 h-[40px] border-gray-200 pt-5"
    >
      <h3 class="text-chartIconLabel font-semibold">{{ title }}</h3>

      <div
        *ngIf="showValuePercentToggle"
        class="flex items-center gap-[4px] text-sm rounded-lg bg-white px-2 border-[1px] border-gray-300"
      >
        <span>Value</span>
        <mat-slide-toggle
          class="scale-[0.6]"
          (change)="togglePercentOrValue($event)"
        ></mat-slide-toggle>
        <span>Percentage</span>
      </div>

      <div *ngIf="showExpandIcons" class="flex items-center gap-3">
        <p *ngIf="showTotal">Total: {{ total | formatMilliBilli }}</p>
        <mat-icon
          class="text-chartIconLabel scale-[0.6] cursor-context-menu"
          *ngIf="!isFullScreen"
          (click)="openCustomFullScreen()"
          svgIcon="fullscreen"
        ></mat-icon>
        <mat-icon
          class="text-chartIconLabel cursor-context-menu"
          *ngIf="isFullScreen"
          (click)="exitCustomFullScreen()"
          >fullscreen_exit</mat-icon
        >
      </div>
      <p *ngIf="showTotalCount" class="font-semibold">Total Count: {{ total }}</p>
    </div>

    <div [ngClass]="filterClass">
      <ng-content select="[filter]"></ng-content>
    </div>
  </div>
  <div [ngClass]="chartClass">
    <ng-content select="[chart]"></ng-content>
  </div>
  <div class="text-sm overflow-auto" [ngClass]="tableClass">
    <ng-content select="[table]"></ng-content>
  </div>
</div>
