import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  map,
  tap,
  switchMap,
  catchError,
  BehaviorSubject,
} from 'rxjs';
import { jwtDecode } from 'jwt-decode';

import { IdNameType } from 'src/app/shared/models/common';
import { environment } from 'src/environments/environment';

interface User {
  id?: number;
  approvalLimit?: number;
  postingBackDateLimit?: number;
  area: IdNameType;
  branch: IdNameType;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  permissions: string[];
  tenantId: number;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authUrl = environment.baseUrl + environment.authUrl;
  private coreUrl = environment.baseUrl + environment.coreUrl;
  private user = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private router: Router) {}

  private getIPAndCountry(): Observable<any> {
    return this.http.get<any>('https://ipapi.co/json/');
  }

  private loginReq(
    username: string,
    password: string,
    res: null | { country_name: string; ip: string }
  ): Observable<any> {
    return this.http
      .post<any>(this.authUrl + 'login', {
        email: username,
        password: password,
        ipAddress: (res && res.ip) || 'Unknown IP',
        country: (res && res.country_name) || 'Unknown Country',
      })
      .pipe(
        tap((res) => {
          localStorage.setItem('indexDBFetched', JSON.stringify([]));
          localStorage.setItem('isIndexDBFetched', 'false');
          this.storeUserCredentials(res);
        })
      );
  }

  private storeUserCredentials(res) {
    if (res?.data?.user_info?.firstTimeLogin) return;
    // on Login
    if (res.data?.user_info) this.setUserProfile(res.data.user_info);

    // on refresh token and login
    const { permissions, approvalLimit,postingBackDateLimit } = jwtDecode(res.data.access_token) as any;
    if(!this.getuser) return;
    this.setUserProfile({ ...this.getuser, approvalLimit, postingBackDateLimit});
    this.user.next({ ...this.getuser, approvalLimit, postingBackDateLimit});
    this.setUserPerm(permissions);
    this.setToken(res.data.access_token);
    this.setRefreshToken(res.data.refresh_token);
  }

  public login(username: string, password: string): Observable<any> {
    return this.getIPAndCountry().pipe(
      switchMap((res) => this.loginReq(username, password, res)),
      catchError(() => this.loginReq(username, password, null))
    );
  }

  public logout() {
    return this.http
      .post<any>(this.authUrl + 'invalidate-token', {
        token: this.getRefreshToken,
      })
      .pipe(
        tap(() => {
          var storedLanguage = localStorage.getItem('locale');
          localStorage.clear();
          localStorage.setItem('locale', storedLanguage);
        })
      );
  }

  public resetPassword(data: any) {
    return this.http.put<any>(this.coreUrl + 'users/reset-password', data);
  }
  public resetPasswordOnExpiry(data: any) {
    return this.http.put<any>(
      this.coreUrl + 'users/reset-password-at-expiration',
      data
    );
  }

  public forgotPassword(email: string) {
    return this.http.post<any>(
      this.coreUrl + `users/${email}/forgot-password`,
      null
    );
  }

  public verifyToken(token: any): Observable<any> {
    return this.http.post(this.authUrl + 'api/v2/auth/verifyToken', {
      token: token,
    });
  }

  public refreshToken(): Observable<any> {
    return this.http
      .post<any>(`${this.authUrl}refresh_token`, {
        token: this.getRefreshToken,
      })
      .pipe(
        tap((res) => {
          
          this.storeUserCredentials(res)
        })
      );
  }
  public get getuser(): null | User {
    if (this.user.value) return this.user.value;
    const userFromLs = JSON.parse(localStorage.getItem('pf') || null);
    if (userFromLs) {
      this.user.next(userFromLs);
      return userFromLs;
    }
    return null;
  }
  public get getToken(): null | string {
    return JSON.parse(localStorage.getItem('token') || null);
  }
  public get getRefreshToken(): null | string {
    return JSON.parse(localStorage.getItem('rt') || null);
  }

  public setToken(token: string): void {
    localStorage.setItem('token', JSON.stringify(token));
  }
  public setRefreshToken(token: string): void {
    localStorage.setItem('rt', JSON.stringify(token));
  }
  private setUserProfile(pf: any): void {
    localStorage.setItem('pf', JSON.stringify(pf));
  }
  private setUserPerm(perms: string[]): void {
    localStorage.setItem('userPerm', JSON.stringify(perms));
  }
}
