import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoanOfficerIndexedDBService } from 'src/app/indexDB/loanOfficer.service';
import { UserService } from 'src/app/pages/staff-management/services/user.service';

@Component({
  selector: 'app-loan-officer-search-select',
  templateUrl: './loan-officer-search-select.component.html',
  styleUrls: ['./loan-officer-search-select.component.css']
})
export class LoanOfficerSearchSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() areaId:number = 0;
  @Input() branchId:number = 0;
  @Input() showDefaultListValue:boolean = true;
  @Input() defaultSelected:any;
  @Input() disabled: boolean = false
  @Input() control: FormControl | string;
  @Input() type: 'client' | 'server' = 'client';
  @Output() emitSelectedVal: EventEmitter<any> = new EventEmitter();

  public loanOfficerLabel: string = $localize`:@@account_officer:Account Officer`;
  loanOfficersData: { id: number, name: string }[] = [];
  public endpoint: string = '';
  private previousAreaId:number = 0;
  public storedArrayString = localStorage.getItem('indexDBFetched');
  public storedArray = this.storedArrayString ? JSON.parse(this.storedArrayString) : [];
  private subscription: Subscription;

  constructor(
    private loanOfficerIDBService: LoanOfficerIndexedDBService,
    private userService: UserService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['branchId'] || changes['areaId']) {
      this.branchId = changes['branchId']?.currentValue;
      this.areaId = changes['areaId']?.currentValue;
      const param: any = {
        ...(this.areaId && {areaId: this.areaId}),   
        ...(this.branchId && {branchId: this.branchId})
      }
      if(param.hasOwnProperty('areaId')) this.previousAreaId = param['areaId'];
      const queryParam = new URLSearchParams(param).toString()
      this.endpoint = this.userService.searchLoanOfficerEndpointByParam(queryParam);
      
      if(this.type === 'server') return;   
      if(this.areaId !== undefined && this.areaId !== 0) return this.getLoanOfficersByAreaId(this.areaId);
      if(this.branchId !== undefined && this.branchId !== 0) return this.getLoanOfficersByBranchId(this.branchId);
      if(this.branchId === 0 && this.previousAreaId !== 0) return this.getLoanOfficersByAreaId(this.previousAreaId);

      return this.getAllLoanOfficers();

    }

    if(changes['type']) {
      this.type = changes['type'].currentValue;
      this.fetchLoanOfficers(this.type);
    }
    
  }

  ngOnInit(): void {
    if (!this.storedArray.includes('loanOfficer')) {
      this.type = 'server';
    }
    this.fetchLoanOfficers(this.type);
  }

  fetchLoanOfficers(type: 'client' | 'server') {
    if(type === 'client') return this.getAllLoanOfficers();
    this.endpoint = this.userService.searchApprovedLoanOfficerEndpoint;
  }

  emitSelected(value:{id:any, name:string}) {
    this.emitSelectedVal.emit(value);
  }

  getAllLoanOfficers(){
    if (this.subscription) {
      // Unsubscribe from the previous subscription
      this.subscription.unsubscribe();
    }
    this.subscription = this.loanOfficerIDBService.getStoredData().subscribe({
      next: (loanOfficers) => { 
        this.loanOfficersData = loanOfficers;
        // Check if the loanOfficersData is empty after fetching
        if (loanOfficers.length === 0) {
          // It's still empty, trigger a refetch
          this.getAllLoanOfficers();
        }
       },
      error: (error) => { console.error('Error retrieving account Officers:', error); }
    });
  }

  getLoanOfficersByBranchId(branchId: number){
    this.loanOfficerIDBService.getStoredLoanOfficersByBranchId(branchId).subscribe({
      next: (loanOfficers) => { this.loanOfficersData = loanOfficers; },
      error: (error) => { console.error('Error retrieving account Officers:', error); }
    });
  }
  
  getLoanOfficersByAreaId(areaId: number){
    this.loanOfficerIDBService.getStoredLoanOfficersByAreaId(areaId).subscribe({
      next: (loanOfficers) => { this.loanOfficersData = loanOfficers; },
      error: (error) => { console.error('Error retrieving account Officers:', error); }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
