<!-- min-h-[9em] -->
<div class="flex items-center h-full flex-wrap p-4 gap-[3%] gap-y-4 rounded-md" [ngClass]="classNames ? classNames : 'bg-summary/100'">
    <ng-container *ngIf="alignContent === 'horizontal'">
        <p class="flex items-start flex-wrap break-all w-[230px] gap-x-5 flex-row" *ngFor="let item of items">
            <span class="font-semibold break-all">{{ item.key.toString() | titlecase }}:</span>
            <ng-container *ngIf="!item.type">
                <span *ngIf="item.value && item.value !== '' && isString(item.value)">{{
          item.value.toString()  | titlecase
        }}</span>
                <span *ngIf="item.value && item.value !== '' && !isString(item.value)">{{ item.value }}</span
        >
        <span *ngIf="!item.value || item.value === ''">-</span>
            </ng-container>
            <ng-container *ngIf="item.type">
                <span *ngIf="item.value && item.value !== '' && item.type === 'currency'">
          {{ item.value | wz_money_format }}
        </span>
            </ng-container>
        </p>
    </ng-container>

    <ng-container *ngIf="alignContent === 'vertical'">
        <p class="flex font-semibold items-start w-[130px] flex-wrap gap-x-5 flex-col" *ngFor="let item of items">
            <span>
        <ng-container *ngIf="item.type === 'currency'">
          {{ item.value | wz_money_format }}
        </ng-container>
        <ng-container *ngIf="item.type !== 'currency'">
          <span [matTooltip]="item.value" matTooltipClass=" break-all whitespace-normal	">
            {{ item.value.toString()  | titlecase | truncate }}
          </span>
    </ng-container>
    </span>
    <ng-container *ngIf="!item.type">
        <span class="text-xs font-light break-all" *ngIf="item.key && item.key !== '' && isString(item.key)">{{ item.key | titlecase }}</span
        >
        <span
          class="text-xs font-light break-all"
          *ngIf="item.key && item.key !== '' && !isString(item.key)"
          >{{ item.key }}</span
        >
        <span
          class="text-xs font-light break-all"
          *ngIf="!item.key || item.key === ''"
          >-</span
        >
      </ng-container>
      <ng-container *ngIf="item.type">
        <span class="text-xs">
          {{ item.key }}
        </span>
    </ng-container>
    </p>
    </ng-container>
</div>