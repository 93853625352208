import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { ActionButtonType, KeyValueType } from '../../models/common';

@Component({
  selector: 'app-table-action-filter',
  templateUrl: './table-action-filter.component.html',
  styleUrls: ['./table-action-filter.component.css'],
})
export class TableActionFilterComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() activeState: string;
  @Input() initialValue: string = '';
  @Input() states: any[];
  @Input() actionButtons: ActionButtonType[];
  @Input() hideActionButtons: boolean = false;

  @Input() showActionBar2: boolean = true;
  @Input() disableActionButtons: boolean = true;
  @Input() showSearchInput: boolean = true;
  @Input() showDownloadButton: boolean = true;
  @Input() runText: string = 'Fetch Records';
  @Input() runIconPath: string = 'assets/icons/download.svg';
  @Input() runMenu: KeyValueType[] = [
    // {
    //   key: 'Download As PDF',
    //   value: 'pdf'
    // },
    {
      key: 'Download As Excel',
      value: 'csv',
    },
  ];
  @Input() showRunButton: boolean = false;
  @Input() showNumOfRowSelected: boolean = false;
  @Input() numOfRowSelected: number = 0;
  @Input() totalAmtOfRowSelected: number = 0;

  @Output() searchEmit: EventEmitter<any> = new EventEmitter();
  @Output() stateEmit: EventEmitter<any> = new EventEmitter();
  @Output() actionEmit: EventEmitter<any> = new EventEmitter();
  @Output() dateEmit: EventEmitter<any> = new EventEmitter();
  @Output() runMainMenu: EventEmitter<null> = new EventEmitter();
  @Output() runMenuChange: EventEmitter<string> = new EventEmitter();

  public activeStateName!: string;

  minDate: Date;
  maxDate: Date;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['activeState']){

      this.activeStateName = this.states?.find(
        (state) => state.value.toLowerCase() === this.activeState.toLowerCase()
      )?.name;
    }
  }

  ngOnInit(): void {    
    this.activeStateName = this.states?.find(
      (state) => state.value.toLowerCase() === this.activeState.toLowerCase()
    )?.name;
  }

  onRunMenu() {
    this.runMainMenu.emit();
  }

  onRunMenuChange(val: string) {
    this.runMenuChange.emit(val);
  }

  onSearch(value: string) {
    this.searchEmit.emit(value);
  }

  onStateChange(value: any) {
    this.activeState = value;
    this.activeStateName = this.states.find(
      (state) => state.value.toLowerCase() === this.activeState.toLowerCase()
    )?.name;
    this.stateEmit.emit(value);
  }

  onActionTrigger(val: ActionButtonType['value']) {
    this.actionEmit.emit(val);
  }

  
}
