import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})
export class UserActivityComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
}
