import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-loading-overlay',
  templateUrl: './main-loading-overlay.component.html',
  styleUrls: ['./main-loading-overlay.component.css']
})
export class MainLoadingOverlayComponent {
  @Input() showLoader: boolean = false;
  constructor() { }

 
}
