<!-- <div [ngStyle]="setMyStyles()"></div> -->
<!-- <div class="login-glass"></div> -->
<div class="flex w-[100%] h-[100vh] justify-center bg-no-repeat bg-cover" [ngStyle]="setMyStyles()">
    <section id="content" class="mt-[30%] sm:mt-[20%] md:mt-[5%] p-2 animated fadeInUp w-[100%] md:w-[80%] lg:w-[450px]">
        <div class="mt-10 forms-container shadow-sm" id="forms-container">
            <a class="block login-brand" href="#"><img class="m-auto" src="assets/images/system-images/logo.png" /></a>
            <section class="panel panel-default bg-white m-t-lg form-login-container" id="form-login-container">
                <header class="panel-heading text-center hidden">
                    <strong i18n="Sign In|Sign In@@sign_in">Sign in</strong>
                </header>
                <form action="#" autocomplete="off" class="panel-body wrapper-lg" id="form-login">
                    <br />
                    <div class="form-group">
                        <input type="text" placeholder="Email" name="_name" [(ngModel)]="username" class="form-control input-md" data-smk-msg=" " required i18n-placeholder="@@email" />
                    </div>
                    <div class="form-group">
                        <nz-input-group class="form-control" [nzSuffix]="suffixTemplate">
                            <input [type]="passwordVisible ? 'text' : 'password'" class="text-xs" nz-input (keyup.enter)="login()" name="_sword" placeholder="Password" [(ngModel)]="password" required i18n-placeholder="@@password" />
                        </nz-input-group>
                        <ng-template #suffixTemplate>
                            <span class="pointer" nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'" (touchstart)="passwordVisible = true" (mousedown)="passwordVisible = true" (touchend)="passwordVisible = false" (mouseup)="passwordVisible = false"></span>
                        </ng-template>
                    </div>
                    <div class="form-group">
                        <mat-form-field style="width: 100%;" class="w-full" appearance="outline">
                            <mat-label i18n="Label language|Label for language@@login_language">Select Language</mat-label>
                            <mat-select (selectionChange)="changeLocale($event.value)" required name="language" [(ngModel)]="language">
                                <mat-option *ngFor="let language of languages" [value]="language.value">
                                    {{ language.key }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button i18n="Sign In|Sign In@@sign_in" type="button" class="bg-success/90 hover:bg-success text-white w-[100%] py-4" (click)="login()">
            Sign in
          </button>
                    <br />
                    <span href="" class="pull-right m-t-xs forgot-password-text">
            <small
              i18n="Forgot password|Label for forgot password@@forgot_password"
              class="tiny-text-font-size"
              style="cursor: pointer"
              (click)="navigateToForgotPassword()"
              >Forgot password?</small
            ></span
          >
        </form>
      </section>

      <section
        class="panel panel-default bg-white m-t-lg form-forgot-password-container"
        id="form-forgot-password-container"
      >
        <header class="panel-heading text-center">
          <strong i18n="Reset Password|Label for reset password@@reset_password"
            >Reset Password</strong
          >
        </header>
        <form
          action="#"
          [formGroup]="forgotPasswordForm"
          (ngSubmit)="forgotPassword()"
          class="panel-body wrapper-lg"
          id="form-forgot-password"
        >
          <p
            class="re-enter-text"
            i18n="
              Kindly enter your email|Label for Kindly enter your
              email@@kindly_enter_your_email"
          >
            Kindly enter your email
          </p>
          <div class="form-group">
            <input
              type="email"
              placeholder="Email address"
              i18n-placeholder="
                Email Address|A placeholder for email address@@email_address"
              name="email"
              formControlName="email"
              class="form-control input-md"
              required
            />
          </div>
          <div class="re-enter-div">
            <button
              i18n="Submit|Submit Buton@@submit"
              type="submit"
              class="bg-success/90 hover:bg-success text-white p-4 re-enter-btn"
              style="display: flex; align-items: center"
            >
              Submit
            </button>
            <span href="#" class="pull-right m-t-xs forgot-password-text"
              ><small
                style="cursor: pointer"
                class="tiny-text-font-size"
                (click)="showForm('form-login-container')"
                i18n="Back to login|Label for back to login@@back_to_login"
                >Back to login</small
              ></span
            >
          </div>
        </form>
      </section>

      <section
        class="panel panel-default bg-white m-t-lg form-change-password-container"
        id="form-change-password-container"
      >
        <header class="panel-heading text-center">
          <strong class="error" i18n="Password Change Required|Password Change Required@@password_change_required">Password Change Required</strong>
        </header>
        <p *ngIf="username" class="text-center my-1 mx-auto">
          <strong>({{ username }})</strong>
        </p>
        <form
          [formGroup]="changePasswordForm"
          (ngSubmit)="changePassword()"
          action="#"
          class="panel-body wrapper-lg"
          id="form-change-password"
        >
          <!-- Current Password -->
          <div class="form-group">
            <label i18n="Current Password|Label for current password@@current_password" >Current Password</label>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group
                  class="form-control"
                  [nzSuffix]="suffixTemplate"
                >
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    class="text-xs"
                    nz-input
                    placeholder="Password"
                    formControlName="oldPassword"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <span
                    class="pointer"
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (touchstart)="passwordVisible = true"
                    (mousedown)="passwordVisible = true"
                    (touchend)="passwordVisible = false"
                    (mouseup)="passwordVisible = false"
                  ></span>
                    </ng-template>
                    </nz-form-control>
                    </nz-form-item>
        </div>
        <!-- New Password -->
        <div class="form-group">
            <label i18n="New Password|Label for new password@@new_password">New Password</label>
            <nz-form-item>
                <nz-form-control>
                    <nz-input-group class="form-control" [nzSuffix]="suffixTemplate">
                        <input [type]="passwordVisible ? 'text' : 'password'" class="text-xs" nz-input i18n-placeholder="Password|A placeholder for password@@password" placeholder="Password" formControlName="newPassword" />
                    </nz-input-group>
                    <ng-template #suffixTemplate>
                        <span class="pointer" nz-icon [nzType]="passwordVisible ? 'eye' : 'eye-invisible'" (touchstart)="passwordVisible = true" (mousedown)="passwordVisible = true" (touchend)="passwordVisible = false" (mouseup)="passwordVisible = false"></span>
                    </ng-template>
                </nz-form-control>
                <small i18n="Password must contain at least one uppercase, lowercase and number (minimum of 8 characters)|Password must contain at least one uppercase, lowercase and number (minimum of 8 characters)@@pa_mu_co_at_le_on_up_lo_an_nu_mi_of_8_ch">Password must contain at least one uppercase, lowercase and
                number (minimum of 8 characters)</small
              >
            </nz-form-item>
          </div>
          <!-- COnfirm New Password -->
          <div class="form-group">
            <label i18n="Confirm New Password|Label for confirm new password@@confirm_new_password" >Confirm New Password</label>
            <nz-form-item>
              <nz-form-control>
                <nz-input-group
                  class="form-control"
                  [nzSuffix]="suffixTemplate"
                >
                  <input
                    [type]="passwordVisible ? 'text' : 'password'"
                    class="text-xs"
                    nz-input
                    name="_sword"
                    placeholder="Password"
                    formControlName="confirmNewPassword"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                  <span
                    class="pointer"
                    nz-icon
                    [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                    (touchstart)="passwordVisible = true"
                    (mousedown)="passwordVisible = true"
                    (touchend)="passwordVisible = false"
                    (mouseup)="passwordVisible = false"
                  ></span>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="re-enter-div">
            <button
              class="bg-success/90 hover:bg-success text-white p-4 re-enter-btn"
              style="display: flex; align-items: center"
              type="submit"
              i18n="Submit|Submit Buton@@submit"
            >
              Submit
            </button>
            <span href="#" class="pull-right m-t-xs change-password-text"
              ><small
                style="cursor: pointer"
                class="tiny-text-font-size"
                (click)="showForm('form-login-container')"
                i18n="Back to login|Label for back to login@@back_to_login"
                >Back to login</small
              ></span
            >
          </div>
        </form>
      </section>

      <footer id="footer">
        <div class="text-center padder">
          <small class="tiny-text-font-size"
            ><a
              style="color: #8e8c8c"
              target="_blank"
              href="http://myaquila.com"
              >©{{ year }} Aquila</a
            ></small
          >
        </div>
      </footer>
    </div>
  </section>
</div>