import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import type { EChartsOption } from 'echarts';
import { columnData } from '../datasource';

import * as echarts from 'echarts';
import { UtilityService } from 'src/app/utils/utility.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css'],
})
export class ColumnChartComponent implements OnChanges, OnDestroy {
  @ViewChild('echarts') echartsElement: ElementRef;
  @ViewChild('echarts2') echarts2Element: ElementRef;
  @Input() width: string = '300px';
  @Input() barLabel: string = 'Amount';
  @Input() palette?: string[] = ['#4d5d76'];
  @Input() hasCurrencySymbol: boolean = true;
  @Input() chartData?: { name: string; value: number }[] = [];
  @Input() defaultChartData?: { name: string; value: number }[] = [];
  @Input() itemsPerPage: number = 7; // Number of items per page
  @Input() sortingOptions: {
    key: 'name' | 'value';
    sortingDirection: 'asc' | 'desc' | 'default';
  } = {
    key: 'name',
    sortingDirection: 'asc',
  };
  currentPage: number = 1;
  totalPages: number;
  xAxisLabelMaxLenth: number = 12;
  formatZeros: string = '';
  isWeb = false;
  isTablet = false;
  isHandset = false;
  public scale = {
    unit: '',
    value: 1,
  };

  options2: EChartsOption = {};

  @Input() options: EChartsOption = {
    color: ['#4d5d76'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
      },
      textStyle: {
        fontSize: 10,
      },
      formatter: (params) => {
        return `${params[0].name}<br />
          ${params[0].marker} ${params[0].seriesName} : ${
          this.hasCurrencySymbol
            ? this.utilityService.currencyFormat(
                params[0].data * this.scale?.value
              )
            : params[0].data
        } <br /> <br />
        `;
      },
    },
    grid: {
      left: '3%', // Set the left margin
      right: '4%', // Set the right margin
      top: '10%', // Set the top margin
      containLabel: true,
      width: '90%', // Set the width of the chart within the grid
      height: '90%', // Set the height of the chart within the grid
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true,
          interval: 1,
        },
        axisLine: {
          lineStyle: {
            color: '#4d5d76',
          },
        },
        axisLabel: {
          interval: 1,
          color: '#4d5d76',
          fontSize: 10,
          formatter: (value: string) => {
            if (value.length > this.xAxisLabelMaxLenth) {
              return value.slice(0, this.xAxisLabelMaxLenth - 3) + '...';
            }
            return value;
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          fontSize: 10,
          formatter: (value: number) => {
            if (!value) return '-';
            return value < 1
              ? value.toString()
              : this.utilityService.pipeNumber(value);
          },
        },
      },
    ],
    series: [
      {
        name: 'Amount',
        type: 'bar',
        barWidth: '20px',
        data: [10, 52, 200, 334, 390, 330, 220],
      },
    ],
  };

  constructor(
    public utilityService: UtilityService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.options2 = JSON.parse(JSON.stringify(this.options));
    const customBreakpoints = {
      web: '(min-width: 1024px)',
      tablet: '(min-width: 501px) and (max-width: 1023px)',
      handset: '(max-width: 500px)',
    };

    this.breakpointObserver
      .observe([
        customBreakpoints.web,
        customBreakpoints.tablet,
        customBreakpoints.handset,
      ])
      .subscribe((result) => {
        this.isWeb = result.breakpoints[customBreakpoints.web];
        this.isTablet = result.breakpoints[customBreakpoints.tablet];
        this.isHandset = result.breakpoints[customBreakpoints.handset];

        if (this.isWeb) {
          this.itemsPerPage = 7;
        } else if (this.isTablet) {
          this.itemsPerPage = 5;
        } else if (this.isHandset) {
          this.itemsPerPage = 7;
        }

        this.sortChartData(
          this.sortingOptions.key,
          this.sortingOptions.sortingDirection
        );
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sortingOptions']) {
      const sortingOptions = changes['sortingOptions'].currentValue;
      if(!sortingOptions) return;
      this.sortChartData(sortingOptions.key, sortingOptions.sortingDirection);
    }

    if (changes['chartData']) {
      this.chartData = changes['chartData'].currentValue;
      this.formatChartData();
      this.setUpChart2();
      this.sortChartData(
        this.sortingOptions.key,
        this.sortingOptions.sortingDirection
      );
    }
  }

  setUpChart2() {
    this.options2.series[0].data = this.defaultChartData
      // .slice(startIndex, endIndex)
      .map((item: any) => item.value);

    this.options2.xAxis[0].data = this.defaultChartData
      // .slice(startIndex, endIndex)
      .map((item: any) => item.name);

    this.options2.series[0].name = this.barLabel;
  }

  formatChartData() {
    if (!this.chartData) return;
    this.scale = this.utilityService.getScale(this.chartData, 'value');
    if (this.hasCurrencySymbol) {
      this.chartData = this.chartData.map((item: any) => {
        item['value'] = item['value'] / this.scale.value;
        return item;
      });
    }
    this.defaultChartData = this.chartData;
  }
  // ngOnInit(): void {
  //   //this.updateChartData(this.currentPage);
  //   // this.sortChartData(this.sortingOptions.key, this.sortingOptions.sortingDirection);
  // }

  updateChartData(currentPage: number): void {
    this.currentPage = currentPage;
    const startIndex = (currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Update series data and x-axis labels
    this.options.series[0].data = this.chartData
      .slice(startIndex, endIndex)
      .map((item: any) => item.value);

    this.options.xAxis[0].data = this.chartData
      .slice(startIndex, endIndex)
      .map((item: any) => item.name);

    this.options.series[0].name = this.barLabel;

    // const maxLabel =Math.ceil( Math.max(
    //   ...this.chartData?.map((item) => item.value)
    // ) / this.scale.value) * this.scale.value;

    // const maxVal = Math.max(
    //   ...this.chartData?.map((item) => item.value)
    // )

    // const maxLabel = Math.ceil( maxVal/ this.utilityService.getValueScale(maxVal, 10).value) * this.utilityService.getValueScale(maxVal, 10).value;
    //   // const maxLabel = Math.floor(maxVal);

    // this.options.yAxis = {
    //  ...this.options.yAxis,
    //   min: 0,
    //   max: maxLabel < 8 ? 8 : maxLabel,
    // };

    // Update chart colors
    this.options.color = this.palette;

    // Calculate the total number of pages
    this.totalPages = Math.ceil(this.chartData.length / this.itemsPerPage);
    this.updateChartOptions();
  }

  updateChartOptions(): void {
    setTimeout(() => {
      if (this.echartsElement && this.echartsElement.nativeElement) {
        const echartsInstance = echarts.getInstanceByDom(
          this.echartsElement.nativeElement
        );

        const echarts2Instance: any = echarts.getInstanceByDom(
          this.echarts2Element.nativeElement
        );

        echarts2Instance.setOption(this.options2);


        const yAxisOpt =
          echarts2Instance._model._componentsMap.get('yAxis')[0].axis;

        this.options.yAxis = {
          ...this.options.yAxis,
          min: 0,
          max: yAxisOpt.scale._extent[1] < 8 ? 8 : yAxisOpt.scale._extent[1],
        };

        echartsInstance.setOption(this.options);
      }
    }, 50);
  }

  sortChartData(key: string, direction: 'asc' | 'desc' | 'default'): void {
    if (direction === 'default') {
      // Sort the data based on the original order when it was received
      this.chartData = this.defaultChartData;
    } else {
      this.chartData = this.chartData.slice().sort((a: any, b: any) => {
        const aValue = typeof a[key] === 'string' ? a[key].trim() : a[key];
        const bValue = typeof b[key] === 'string' ? b[key].trim() : b[key];

        if (direction === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    }

    // Update the chart data
    this.updateChartData(this.currentPage);
  }

  ngOnDestroy(): void {
    this.breakpointObserver.ngOnDestroy();
  }
}
