import {
  DatePipe,
  DecimalPipe,
  formatCurrency,
  formatDate,
  getCurrencySymbol,
} from '@angular/common';
import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { URL } from 'url';
import { LoginUser } from '../auth/models/login-user';
import { SearchableSelectTypes } from '../shared/models/common';
import { environment } from 'src/environments/environment';

declare let $: any;

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  initialTicketChange = {};
  initialTickets = [];

  initialNotifications = {};
  initialNotificationLength: any = 0;
  weekdays: any;
  initialKycStatus: string = 'pending';
  initialKycDataItem = {
    id: 1,
    approvalState: '',
    date: '',
    bvnImage: '',
    selfie: '',
    clientIdentification: {
      number: '',
      description: '',
      url: '',
      name: '',
      idType: '',
    },
    utilityBill: '',
    bvnPhoneNumber: '',
    bvnEmail: '',
    bvnFirstName: '',
    bvnMiddleName: '',
    bvnLastName: '',
  };

  private newKycStatus = new BehaviorSubject<string>(this.initialKycStatus);
  private newKycDataItem = new BehaviorSubject<Object>(this.initialKycDataItem);

  private newNotificationLength = new BehaviorSubject<Object>(
    this.initialNotificationLength
  );
  private newNotifications = new BehaviorSubject<Object>(
    this.initialNotifications
  );

  currentKycStatus = this.newKycStatus.asObservable();
  currentKycDataItem = this.newKycDataItem.asObservable();
  currentNotificationLength: any = this.newNotificationLength.asObservable();
  currentNotifications: any = this.newNotifications.asObservable();

  private renderer: Renderer2;
  private elementRef: ElementRef;

  public months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor(private datePipe: DatePipe, private numberPipe: DecimalPipe) {
    this.weekdays = [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY',
    ];
  }

  nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  setKycStatus(data) {
    this.newKycStatus.next(data);
  }

  setKycDataItem(data) {
    this.newKycDataItem.next(data);
  }

  showLoading(): void {
    $('#loading-overlay').show();
  }

  private newTicketList = new BehaviorSubject<Object>(this.initialTickets);
  private newTicketChange = new BehaviorSubject<Object>(
    this.initialTicketChange
  );

  getScale(chartData: any, prop: any) {
    const average = Math.round(
      chartData.reduce((acc, item) => acc + item[prop], 0) / chartData.length
    );
    return this.getValueScale(average, 1000);
  }

  getValueScale(val: number, startAt: 10 | 100 | 1000 | 1000000 | 1000000000) {
    let value = 1;
    let unit = '';
    if (val >= 10 && startAt <= 10) {
      unit = '0';
      value = 10;
    }
    if (val >= 100 && startAt <= 100) {
      unit = '’00';
      value = 100;
    }
    if (val >= 1000 && startAt <= 1000) {
      unit = '’000';
      value = 1000;
    }
    if (val >= 1000000 && startAt <= 1000000) {
      unit = 'mn';
      value = 1000000;
    }
    if (val >= 1000000000 && startAt <= 1000000000) {
      unit = 'bn';

      value = 1000000000;
    }

    return { value, unit };
  }

  currentTicketList = this.newTicketList.asObservable();
  currentTicketChange = this.newTicketChange.asObservable();

  setTicketData(data) {
    this.newTicketList.next(data);
  }

  setCurrentTicketChange(data) {
    this.newTicketChange.next(data);
  }

  setNotificationData(data) {
    this.newNotifications.next(data);
  }
  setCurrentNotificationLength(data) {
    this.newNotificationLength.next(data);
  }

  hideLoading(): void {
    $('#loading-overlay').fadeOut(500);
  }

  prepareString(str) {
    return str
      .replace(/_/g, ' ')
      .split(' ')
      .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ');
  }

  //formats values in millions and billions, then return it with or without currency symbol
  formatTotalNumber(
    value: any,
    digitsInfo: string = '1.2-2',
    hasCurrencySymbol: boolean = true,
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol-narrow'
  ): string {
    if (!value || !Number(value) || Number(value) <= 0) return '-';

    const locale =
      localStorage.getItem('locale') || environment.countryInfo.locale;
    const currencyCode = environment.countryInfo.code || 'NGN';
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

    if (Number(value) >= 1e9) {
      // Format as billions
      return hasCurrencySymbol
        ? `${currencySymbol}${(Number(value) / 1e9).toFixed(1)}b`
        : `${(Number(value) / 1e9).toFixed(1)}b`;
    } else if (Number(value) >= 1e6) {
      // Format as millions
      return hasCurrencySymbol
        ? `${currencySymbol}${(Number(value) / 1e6).toFixed(1)}m`
        : `${(Number(value) / 1e6).toFixed(1)}m`;
    } else {
      // Default format
      if (hasCurrencySymbol) {
        return formatCurrency(
          Number(value),
          locale,
          currencySymbol,
          currencyCode,
          digitsInfo
        );
      } else {
        const formattedValue = value
          .toFixed(0)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return `${formattedValue}`;
      }
    }
  }

  //returns currency symbol only
  getCurrencySymbol(
    code: string,
    format: 'wide' | 'narrow' = 'narrow'
  ): string {
    return getCurrencySymbol(code, format);
  }

  //returns currency with symbol
  currencyFormat(
    value: number | string,
    display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol-narrow',
    digitsInfo: string = '1.2-2'
  ): string | null {
    if (!value || !Number(value)) return '-';
    const locale =
      localStorage.getItem('locale') || environment.countryInfo.locale;
    const currencyCode = environment.countryInfo.code || 'NGN';
    const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

    return formatCurrency(
      Number(value),
      locale,
      currencySymbol,
      currencyCode,
      digitsInfo
    );
  }

  /** new Date() format to 17-06-2016 **/
  formatDate_custom(date: Date): string {
    return formatDate(date, 'dd-MM-yyyy', 'en-UK');
  }
  formatDate_custom3(date: Date | string): string {
    return formatDate(date, 'd MMM. yyyy', 'en-UK');
  }

  formatDate_custom_yearFirst(date: Date | string): string {
    if (date == undefined) date = new Date();
    return formatDate(date, 'yyyy-MM-dd', 'en-UK');
  }

  /** 2016-06-17 to 17-06-2016 **/
  formatDate_reverse(date): string {
    date = date ? date.split('-') : false;
    return date ? date[2] + '-' + date[1] + '-' + date[0] : '';
  }

  /** 2016-06-17 14:35:32 to 17-06-2016 **/
  formatDate_custom2(date): string {
    date = date ? date.split('T')[0].split('-') : false;
    return date ? date[2] + '-' + date[1] + '-' + date[0] : '';
  }

  pipeDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  pipeNumber(value: string | number) {
    return this.numberPipe.transform(value, '1.0-0');
  }

  formatAppCurrency(value: string): Number {
    if (!value) return 0;
    return Number(value.toString().slice(1).replace(/,/g, ''));
  }

  /* tabs */
  gotoTab(parentId, tabId): void {
    //$('#'+parentId+' a[href="/#'+tabId+'"]').tab('show');
    var tabPanel = $('#' + parentId);
    var panes = tabPanel.find('.tab-content > div');
    var headers = tabPanel.find('header ul > li');
    headers.removeClass('active');
    headers.find('#' + tabId).addClass('active');
    panes.removeClass('active');
    panes
      .parent()
      .find('#' + tabId)
      .addClass('active');
    tabPanel
      .find('#' + tabId)
      .parent()
      .addClass('active')
      .siblings()
      .removeClass('active');
  }

  saveDataOnDevice(data: any, filename: string) {
    if (!data) {
      return;
    }

    if (!filename) filename = 'console.json';

    if (typeof data === 'object') {
      data = JSON.stringify(data, undefined, 4);
    }

    var blob = new Blob([data], { type: 'text/json' }),
      e = document.createEvent('MouseEvents'),
      a = document.createElement('a');

    a.download = filename;
    a.href = window.URL.createObjectURL(blob);
    a.dataset['downloadurl'] = ['text/json', a.download, a.href].join(':');
    e.initMouseEvent(
      'click',
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  }

  getNameById(obj: IdName[], id: number): String {
    return obj != null ? obj.filter((d) => d.id == id)[0].name : null;
  }

  getObjById(obj: IdName[], id: number): object {
    return obj != null ? obj.filter((d) => d.id == id)[0] : null;
  }

  // input: 65,000. output: 65000
  prepareMoney = function (amt) {
    if (amt === 'undefined') {
      return 0;
    }
    amt = amt + ',';
    return amt ? (amt === ',' ? 0 : amt.replace(/,/g, '')) : amt;
  };

  sanitizeCurrency(currencyValue) {
    return Number(String(currencyValue).replace(/[^0-9\.]/g, ''));
  }

  sanitizePercentage(percentageValue) {
    return Number(String(percentageValue).replace(/%/g, ''));
  }

  titleCase(input: string): string {
    return input
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  joinWithUnderscore(inputString: string): string {
    if (!inputString) return '';

    return inputString.replace(/ /g, '_');
  }

  underScoreToSpace(values: any) {
    if (typeof values !== 'string' && !Array.isArray(values)) return values;

    if (typeof values === 'string') return values.replace(/_/g, ' ');

    if (Array.isArray(values))
      return values.map((value) => {
        if (typeof value === 'string') {
          return value.replace(/_/g, ' ');
        }
        return value;
      });
  }

  editForm(formId: string): void {
    $('#' + formId + ' .editable').removeAttr('disabled');
  }

  disableForm(formId: string): void {
    $('#' + formId + ' .editable').attr('disabled', 'disabled');
  }

  /** Date Utilities **/

  yearMinus(date: Date, numberOfYears: number): Date {
    var ddate = new Date(date);
    ddate.setFullYear(ddate.getFullYear() - numberOfYears);
    return ddate;
  }

  subtractWeeks(date: Date, numberOfWeeks: number): Date {
    var ddate = new Date(date);
    ddate.setDate(ddate.getDate() - 7 * numberOfWeeks);
    return ddate;
  }

  addWeeks(date: Date, numberOfWeeks: number): Date {
    var ddate = new Date(date);
    ddate.setDate(ddate.getDate() + 7 * numberOfWeeks);
    return ddate;
  }

  addDays(date: Date, numberOfDays: number): Date {
    var ddate = new Date(date);
    ddate.setDate(ddate.getDate() + numberOfDays);
    return ddate;
  }

  subtractDays(date: Date, numberOfDays: number): Date {
    var ddate = new Date(date);
    ddate.setDate(ddate.getDate() - numberOfDays);
    return ddate;
  }

  objToQueryParams(params: any) {
    // check if its an object (not array or function)
    if (params && params === Object(params)) {
      return '?' + new URLSearchParams(params).toString();
    }
    return null;
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  addBusinessDaysWithUnit(
    date: Date,
    numberOfDays: number,
    unit: string,
    isCalendar: boolean = false
  ): Date {
    if (unit.toUpperCase() === 'MONTH') {
      let newDate = this.addMonth(date, numberOfDays);
      // if saturday
      if (newDate.getDay() === this.daysOfWeek.SATURDAY && !isCalendar) {
        return this.addDate(newDate, 2);
      }
      // if sunday
      else if (newDate.getDay() === this.daysOfWeek.SUNDAY && !isCalendar) {
        return this.addDate(newDate, 1);
      } else {
        return newDate;
      }
    }

    return this.addBusinessDays(
      date,
      this.convertToDays(numberOfDays, unit, isCalendar),
      isCalendar
    );
  }

  addDate(date: Date, days: number): Date {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  addMonth(date: Date, months: number): Date {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  daysOfWeek = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
  };

  addBusinessDays(date: Date, numberOfDays: number, isCalendar: boolean): Date {
    while (numberOfDays > 0) {
      //if friday
      if (date.getDay() === this.daysOfWeek.FRIDAY && !isCalendar) {
        date = this.addDate(date, 3);
        numberOfDays--;
      } else {
        date = this.addDate(date, 1);
        if (
          (date.getDay() === this.daysOfWeek.SUNDAY ||
            date.getDay() === this.daysOfWeek.SATURDAY) &&
          !isCalendar
        ) {
        } else {
          numberOfDays--;
        }
      }
    }
    return date;
  }

  convertToDays(
    duration: number,
    unit: string,
    isCalendar: boolean = true
  ): number {
    let days = 0;
    let repaymantType: any = {};
    let calendarType = {
      week: 7,
      month: 30,
      year: 365,
    };
    let workType = {
      week: 5,
      month: 20,
      year: 260,
    };

    if (isCalendar) {
      repaymantType = calendarType;
    } else {
      repaymantType = workType;
    }

    switch (unit) {
      case 'DAY':
        days = duration;
        break;
      case 'WEEK':
        days = duration * repaymantType.week;
        break;
      case 'MONTH':
        days = duration * repaymantType.month;
        break;
      case 'YEAR':
        days = duration * repaymantType.year;
        break;
    }
    return days;
  }

  formatChartDate(date: any) {
    // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
    // @ts-ignore
    // const [{ value: month }, , { value: year }] = dateTimeFormat.formatToParts(new Date(date));
    return this.datePipe.transform(new Date(date), 'MMM y');
    // return `${month} ${year}`;
  }

  formatDailyDisbursementAndRepaymentChartDate(date: any) {
    // const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' });
    // @ts-ignore
    // const [{ value: month }, , { value: day }] = dateTimeFormat.formatToParts(new Date(date));
    // return `${day} ${month}`;

    return this.datePipe.transform(new Date(date), 'dd MMM');
  }

  /* Lock screen */
  lockScreen(): void {
    $('#modal-lock-screen').show();
  }

  unlockScreen(): void {
    $('#modal-lock-screen').fadeOut(500);
  }

  /* Export factory */

  exportAsPDF(divId: string) {
    $('#' + divId).printMe({
      path: [
        '../../assets/libs/print-me/bootstrap.min.css',
        '../../assets/libs/print-me/example2.css',
      ],
    });
  }

  exportTableToExcel(tableID, filename = '') {
    var downloadLink;
    var dataType = 'application/vnd.ms-excel';
    var tableSelect = document.getElementById(tableID);
    var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + '.xls' : 'excel_data.xls';

    // Create download link element
    downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    const nav = window.navigator as any;

    if (nav.msSaveOrOpenBlob) {
      var blob = new Blob(['\ufeff', tableHTML], {
        type: dataType,
      });
      nav.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  }

  getLoggedInUser(): LoginUser {
    return JSON.parse(localStorage['user']);
  }

  mapRecordsToSearchableSelect(records: any[]): SearchableSelectTypes[] {
    return records.map((record) => ({
      label: record?.name,
      value: record?.id,
    }));
  }

  processFileResponse(
    fileResponseData: any,
    fileName: string,
    render: 'base64' | 'blob',
    fileType?: 'excel' | 'pdf'
  ): void {
    if (render === 'base64') {
      const binaryString = window.atob(fileResponseData);
      const bytes = new Uint8Array(binaryString.length);
      const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
      const blob =
        fileType === 'pdf'
          ? new Blob([binaryToBlob], { type: 'application/pdf' })
          : new Blob([binaryToBlob], { type: 'application/vnd.ms-excel' });
      this.downloadFile(blob, fileName);
    } else {
      const blob =
        fileType === 'pdf'
          ? new Blob([fileResponseData], { type: 'application/pdf' })
          : new Blob([fileResponseData], { type: 'application/vnd.ms-excel' });
      this.downloadFile(blob, fileName);
    }
  }

  private downloadFile(blob: any, fileName: string): void {
    // IE Browser
    const nav = window.navigator as any;
    if (nav.msSaveOrOpenBlob) {
      nav.msSaveOrOpenBlob(blob, fileName);
      return;
    }

    // Other Browsers
    const url = (window.URL || window.webkitURL).createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  // 2MB by default
  limitFileSize(fileSize: number, requiredFileSize: number = 2): boolean {
    let fileSizeValid = false;
    const maxSize = requiredFileSize * 1024 * 1024; 

    if (fileSize > maxSize) fileSizeValid = false;
    else fileSizeValid = true;

    return fileSizeValid;
  }
}

class IdName {
  id: number;
  name: String;
  extra: object;
}
