import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { KeyValuePermType } from '../../models/common';
import { PermissionService } from 'src/app/utils/permission.service';
import { Permissions } from '../../dto/permissions';

@Component({
  selector: 'app-action-bar3',
  templateUrl: './action-bar3.component.html',
  styleUrls: ['./action-bar3.component.css'],
})
export class ActionBar3Component implements OnChanges {
  @Input() wrapInMenu = false;
  @Input() actionButtons: KeyValuePermType[] = [
    {
      key: 'Custom 1',
      value: 'custom1',
      selected: true,
      perms: [Permissions.NONE],
    },
    {
      key: 'Custom 2',
      value: 'custom2',
      selected: false,
      perms: [Permissions.NONE],
    },
    {
      key: 'Custom 3',
      value: 'custom3',
      selected: false,
      perms: [Permissions.NONE],
    },
  ];
  @Input() showActionButtons: boolean = true;
  @Input() disableActionButtons: boolean = false;
  @Input() selectedAction: string = '';
  @Input() defaultClass = 'h-[40px] px-8 rounded-lg mx-1';
  @Input() activeClass = 'bg-primary text-white font-semibold';
  @Input() inactiveClass = 'border border-primary/20 text-primary';
  @Output() actionEmit: EventEmitter<string> = new EventEmitter();

  constructor(private permissionsService: PermissionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedAction']) {
      const isSelected = this.actionButtons.find(
        (btn) => btn.value === this.selectedAction
      );
      if (isSelected?.selected) return;
      this.actionButtons.map((btn) => {
        btn.selected = btn.value === this.selectedAction;
        return btn;
      });
    }
  }

  public hasPermission(perms: string[]) {
    return this.permissionsService.hasPermissionList(perms);
  }

  public get selectedBtn(){
    return this.actionButtons.find(btn=>btn.value === this.selectedAction);
  }

  public evalClass(val: string) {
    if (
      val === 'reject' ||
      val === 'disable' ||
      val === 'decline' ||
      val === 'cancel'
    ) {
      return 'bg-error';
    }
    return 'bg-success';
  }

  public actionToggled(val: string) {
    this.actionButtons.map((button) => {
      button.selected = val === button.value;
      if(button.selected) this.selectedAction = button.value;
      return button;
    });
    this.actionEmit.emit(val);
  }
}
