import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartFilterControlsInterface, ReportInputTypes } from 'src/app/shared/models/common';
import moment from 'moment';

@Component({
  selector: 'app-chart-filter',
  templateUrl: './chart-filter.component.html',
  styleUrls: ['./chart-filter.component.css']
})
export class ChartFilterComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formControls: ChartFilterControlsInterface[] = []
  @Input() showSortControl: boolean = true;
  @Input() legends: {label: string, total: number}[] = [];

  public sortLabel = 'Sort';

  @Output() filter: EventEmitter<any> = new EventEmitter()
  public sortOptions = [
    { label: 'Default', value: 'default'},
    { label: 'Value: Asc.', value: 'value-asc'},
    { label: 'Value: Desc.', value: 'value-desc'},
    { label: 'Name: Asc.', value: 'name-asc'},
    { label: 'Name: Desc.', value: 'name-desc'},
  ]
  public PAROptions = [
    { label: 'Total PAR', value: 0},
    { label: 'PAR 1', value: '1'},
    { label: 'PAR 7', value: '7'},
    { label: 'PAR 30', value: '30'},
    { label: 'PAR 60', value: '60'},
    { label: 'PAR 90', value: '90'},
    { label: 'PAR 180', value: '180'},
    { label: 'PAR 180+', value: '180+'}
  ]

  public clientStatus = [
    { label: 'Approved Clients', value: 'approved'},
    { label: 'Active Client', value: 'running'},
    
  ]
  public startDateRange = {
    min: new Date('01/01/2016'),
    max: new Date(),
  };
  public endDateRange = {
    min: new Date(this.startDateRange.max),
    max: new Date(),
  };
  public areaId:number = 0;
  public branchId: number = 0;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initializeFilter()
    this.initFilterListener()
  }

  get startDateCtrl(){
    return this.getControl(ReportInputTypes.START_DATE)
  }
  get endDateCtrl(){
    return this.getControl(ReportInputTypes.END_DATE)
  }

  initializeFilter() {
    this.form = this.fb.group(this.setFormGroupControl());
  }

  setFormGroupControl() {
    let groupControl = {}
    this.formControls.forEach(control => {
      if(control.name === ReportInputTypes.CLIENT_STATUS){
        groupControl[control.name] = new FormControl('approved')
      }else if( ReportInputTypes.START_DATE === control.name){
        groupControl[control.name] = new FormControl(`${moment().subtract(6, 'days').format('YYYY-MM-DD')}`)
      
      }else if( ReportInputTypes.END_DATE === control.name){
        groupControl[control.name] = new FormControl(new Date())
      }
      else{
        groupControl[control.name] = new FormControl(0)
      }
    })
    return groupControl
  }

  initFilterListener() {
    this.formControls.forEach(control => {
      this.form.get(control.name)?.valueChanges.subscribe({
        next: (value) => this.filter.emit({value, ...control})
      })
    })
  }

  // emitSelectedVal(ev: any) {
  //   this.filter.emit(ev)
  // }

  getControl(controlName: string) {
    return this.form.get(controlName) as FormControl
  }

  // public dateListner() {
  //   this.startDateRange.max = new Date(this.entryClosureForm.get("endDate").value);
  //   this.endDateRange.min = new Date(this.entryClosureForm.get("startDate").value)
  // }

  onSort(sortOption: any) {
    this.sortLabel = sortOption.label
    this.filter.emit({value: sortOption.value, name: ReportInputTypes.SORT, type: 'SORT' })
  }

}
