import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appSentenceCase]'
})
export class SentenceCaseDirective {

  constructor(private el: ElementRef) {}

  @HostListener('blur') onBlur() {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    inputElement.value = this.sentenceCase(inputElement.value);
  }

  private sentenceCase(input: string): string {
    // Split the input into sentences based on periods.
    const sentences = input.split('.');

    // Process each sentence and transform the first letter to uppercase.
    const transformedSentences = sentences.map(sentence => {
      // Trim leading and trailing whitespace
      sentence = sentence.trim();
      // Check if the sentence is not empty
      if (sentence) {
        // Transform the first letter to uppercase and concatenate the rest of the sentence.
        return sentence.charAt(0).toUpperCase() + sentence.slice(1);
      } else {
        // If the sentence is empty, return it as is.
        return sentence;
      }
    });

    // Join the transformed sentences with periods and spaces.
    return transformedSentences.join('. ');
  }

}
